import React, {
	useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form } from 'react-bootstrap';
import InfoBar from '../components/Navigation/InfoBar';
import CourseListTable from '../components/Tables/CourseListTable';
import { getTrainingsForCompany } from '../constants/routes';
import { useDationUser } from '../hooks/dation-user';
import useApi from '../hooks/use-api';
import moment from '../moment';
import { compareTableSortDates } from '../utils/sort';
import EnrollEmployeeModal from '../components/Modal/EnrollEmployeesModal';
import {
	findExistingReservation,
	getCalculatedFreeCapacity, isCode95CcvCode,
	transformSlotForFutureSubRow,
	transformTrainingDataForCompany,
} from '../utils/training';
import ReservationModal from '../components/Modal/ReservationModal/ReservationModal';
import { CODE_95_ENABLED, useViewPreferences } from '../hooks/view-preferences';
import DateFilters, { isDateRangeValid } from '../components/Tables/Filters/DateFilter';
import TextFilter, { textFilter } from '../components/Tables/Filters/TextFilter';
import useUserPreferences from '../hooks/use-user-preferences';
import { COURSE_LIST_TABLE } from '../constants/tables';

const TABLE_EXTERNAL_FILTERS_NAME = `${COURSE_LIST_TABLE}ExternalFilters`;
const MIN_DATE = moment().subtract(1, 'day');

function CourseListPage() {
	const { t } = useTranslation();
	const dationUser = useDationUser();
	const {
		getFilters, setFilters,
	} = useUserPreferences();
	const sessionFilters = getFilters(TABLE_EXTERNAL_FILTERS_NAME);
	const { isPreferenceOptionEnabled } = useViewPreferences();

	const [body, setBody] = useState([]);
	const [showEnrollModal, setShowEnrollModal] = useState(false);
	const [showReservationModal, setShowReservationModal] = useState(false);
	const [selectedTraining, setSelectedTraining] = useState(null);
	const [showCode95Trainings, setShowCode95Trainings] = useState(sessionFilters?.showCode95Trainings || false);

	const defaultStartDate = moment().set({ hour: 0, minute: 0, second: 0 });
	const defaultEndDate = moment().add(1, 'years').set({ hour: 0, minute: 0, second: 0 });

	const initialDateFiltersState = {
		startDate: sessionFilters?.dateFilters?.startDate && moment.isMoment(moment(sessionFilters?.dateFilters?.startDate)) ? moment(sessionFilters.dateFilters.startDate).set({ hour: 0, minute: 0, second: 0 }) : defaultStartDate,
		endDate: sessionFilters?.dateFilters?.endDate && moment.isMoment(moment(sessionFilters?.dateFilters?.endDate)) ? moment(sessionFilters.dateFilters.endDate).set({ hour: 0, minute: 0, second: 0 }) : defaultEndDate,
	};

	const [dateFilters, setDateFilters] = useState(initialDateFiltersState);

	const code95Enabled = isPreferenceOptionEnabled(CODE_95_ENABLED);

	const [{ data, isLoading }, fetchTrainings] = useApi.get(
		getTrainingsForCompany(dationUser.companyId),
		{},
		{ lazy: true },
	);

	const buttonCell = useCallback(cellInfo => {
		const { row: { original } } = cellInfo;
		return !dationUser.isDationInstanceUser() && !original.isSubRow ? (
			<div className="d-flex flex-row-reverse">
				<Button
					className="ms-1"
					variant="outline-primary"
					type="button"
					size="sm"
					onClick={e => {
						e.stopPropagation();
						setSelectedTraining(original);
						setShowReservationModal(true);
					}}
				>
					{t('trainings.reserve')}
				</Button>
				{!original.composite && (
					<Button
						variant="outline-primary"
						type="button"
						size="sm"
						onClick={e => {
							e.stopPropagation();
							setSelectedTraining(original);
							setShowEnrollModal(true);
						}}
					>
						{t('trainings.enroll')}
					</Button>
				)}
			</div>
		) : null;
	}, [dationUser]);

	const startDateCell = useCallback(cellInfo => {
		const { row } = cellInfo;
		return (
			<div className={`${!row.original.isSubRow && row.isExpanded ? 'text-primary' : 'text-primary-color'}`}>
				{row.original.startDate}
			</div>
		);
	}, []);

	const expanderCell = useCallback(({ row }) => {
		const { slots } = row.original || [];
		const chevronStyle = row.isExpanded ? 'glyphicons-chevron-down text-primary' : 'glyphicons-chevron-up text-secondary';
		return slots ? (
			<span
				{...row.getToggleRowExpandedProps({ title: null })}
			>
				<span className={`glyphicons ${chevronStyle}`} />
			</span>
		) : null;
	}, []);

	const headers = React.useMemo(() => [
		{
			id: 'expander',
			Header: () => null,
			Cell: expanderCell,
			disableFilters: true,
		},
		{
			Header: t('trainings.date'),
			accessor: 'startDate',
			sortType: compareTableSortDates,
			Cell: startDateCell,
			disableFilters: true,
		},
		...(code95Enabled ? [{
			Header: t('trainings.ccvCode'),
			accessor: 'ccvCode',
			Filter: TextFilter,
			filter: textFilter,
		}] : []),
		{
			Header: t('trainings.training'),
			accessor: 'name',
			Filter: TextFilter,
			filter: textFilter,
		},
		{
			Header: t('trainings.location'),
			accessor: 'city',
			Filter: TextFilter,
			filter: textFilter,
		},
		{
			Header: t('trainings.capacity'),
			accessor: 'freeCapacity',
			disableFilters: true,
			Cell: ({ row: { original: { freeCapacity, trainingReservations } } }) => {
				const existingReservation = findExistingReservation(trainingReservations, dationUser.getCurrentCompanyId()) || {};
				return getCalculatedFreeCapacity(freeCapacity, existingReservation);
			},
		},
		{
			accessor: 'id',
			Cell: buttonCell,
			disableFilters: true,
		},
	], [t]);

	useEffect(() => {
		if(data) {
			const filteredTrainings = data.filter(training => {
				if(showCode95Trainings) {
					return training.ccvCode?.length && isCode95CcvCode(training.ccvCode);
				}
				return true;
			});
			setBody(transformTrainingDataForCompany(filteredTrainings));
		}
	}, [data]);

	const refreshTrainings = () => {
		fetchTrainings({
			startDate: dateFilters.startDate.format(),
			endDate: dateFilters.endDate.format(),
		});
	};

	useEffect(() => {
		if(isDateRangeValid(
			dateFilters.startDate,
			dateFilters.endDate,
			{ minDate: MIN_DATE },
		)) {
			setFilters(TABLE_EXTERNAL_FILTERS_NAME, {
				dateFilters: {
					startDate: dateFilters.startDate.format(),
					endDate: dateFilters.endDate.format(),
				},
				showCode95Trainings,
			});
			refreshTrainings();
		}
	}, [dateFilters, showCode95Trainings]);

	const courseListTableRef = useRef();

	const resetFilters = () => {
		courseListTableRef.current.clearFilters(); // defined by child
		setDateFilters({
			startDate: defaultStartDate,
			endDate: defaultEndDate,
		});
		setShowCode95Trainings(false);
	};

	return (
		<>
			<InfoBar />
			{ data === null
				? <div className="loading-spinner" />
				: (
					<div className="row pb-5">
						<div className="col-sm-12 col-xl-10 mx-auto mt-2">
							<EnrollEmployeeModal
								show={showEnrollModal}
								setShow={setShowEnrollModal}
								training={selectedTraining}
								handleSubmit={refreshTrainings}
							/>
							<ReservationModal
								show={showReservationModal}
								setShow={setShowReservationModal}
								training={selectedTraining}
								setTraining={setSelectedTraining}
								handleSubmit={refreshTrainings}
							/>
							<div className="d-flex mb-3">
								<Card className="col-5">
									<Card.Body>
										<DateFilters
											minDate={MIN_DATE}
											filters={dateFilters}
											handleChange={(dateKey, date) => setDateFilters({ ...dateFilters, [dateKey]: date })}
										/>
										{code95Enabled ? (
											<Form.Check className="mt-2" type="checkbox">
												<Form.Check.Input
													type="checkbox"
													checked={showCode95Trainings || false}
													onChange={({ target }) => setShowCode95Trainings(target.checked)}
												/>
												<Form.Check.Label onClick={() => setShowCode95Trainings(!showCode95Trainings)}>
													{t('trainings.show_code_95_trainings')}
												</Form.Check.Label>
											</Form.Check>
										) : null}
									</Card.Body>
								</Card>
								<div className="d-flex align-items-end ms-2">
									<button
										type="button"
										className="btn btn-outline-secondary"
										onClick={resetFilters}
									>
										{t('table.clearFilters')}
									</button>
								</div>
							</div>
							{isLoading ? <div className="loading-spinner" /> : (
								<CourseListTable
									ref={courseListTableRef}
									data={body}
									columns={headers}
									orderDesc={false}
									transformSubRow={transformSlotForFutureSubRow}
									allowExport={false}
								/>
							)}
						</div>
					</div>
				)}
		</>
	);
}

export default CourseListPage;
