import React from 'react';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function TabsHeader({ tabKey, tabs, options = {} }) {
	const { t } = useTranslation();
	const variant = options?.variant || 'tabs';

	const getLinkClassName = (tab, key) => {
		if(tab === key) {
			return variant === 'tabs' ? 'active-tab' : 'text-primary';
		}

		return 'text-secondary';
	};
	return (
		<div className={options?.className}>
			<Nav variant="tabs" className="border-0 px-1">
				{tabs.map(tab => (
					<Nav.Item key={tab}>
						<Nav.Link
							className={getLinkClassName(tab, tabKey)}
							eventKey={tab}
						>
							{options.i18nPrefix ? t(`${options.i18nPrefix}.${tab}`) : tab}
						</Nav.Link>
					</Nav.Item>
				))}
			</Nav>
		</div>
	);
}

export default TabsHeader;
