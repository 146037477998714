/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import DationInstancesListPage from '../../pages/DationInstance/DationInstancesListPage';
import NotFoundPage from '../../pages/NotFoundPage';
import DationInstanceDetailPage from '../../pages/DationInstance/DationInstanceDetailPage';
import SignUpPage from '../../pages/SignUpPage';
import StatisticsPage from '../../pages/StatisticsPage';
import EditUserPage from '../../pages/EditUserPage';
import MaintenancePage from '../../pages/MaintenancePage';

function AdminRouter({ handleSignOut }) {
	return (
		<Switch>
			<Route exact path={ROUTES.LOGIN} element={<Navigate to={ROUTES.DATION_INSTANCES} />} />
			<Route exact path={ROUTES.LANDING} element={<Navigate to={ROUTES.DATION_INSTANCES} />} />

			<Route exact path={ROUTES.DATION_INSTANCES} element={<DationInstancesListPage />} />
			<Route exact path={ROUTES.PORTAL_STATISTICS} element={<StatisticsPage />} />
			<Route
				exact
				path={`${ROUTES.DATION_INSTANCES}/:dationInstanceId`}
				element={<DationInstanceDetailPage />}
			/>

			<Route
				exact
				path={ROUTES.resetPasswordPage()}
				Component={() => {
					handleSignOut();
					return <SignUpPage />;
				}}
			/>
			<Route exact path="/notFound" element={<NotFoundPage />} />
			<Route exact path={ROUTES.EDIT_USER} element={<EditUserPage />} />
			<Route exact path={ROUTES.MAINTENANCE} element={<MaintenancePage />} />
		</Switch>
	);
}

export default AdminRouter;
