/* eslint-disable import/no-extraneous-dependencies */
import React, {
	createContext, useState, useContext,
} from 'react';
import { initializeApp } from 'firebase/app';
import {
	getAuth,
	signInWithCustomToken,
	signInWithEmailLink,
	isSignInWithEmailLink,
	sendPasswordResetEmail,
	signOut,
	getIdToken,
	linkWithCredential,
	updatePassword,
	onAuthStateChanged,
} from 'firebase/auth';

const firebaseContext = createContext();
const config = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

function useProvideFirebase() {
	const [authUser, setAuthUser] = useState(null);
	const [userSignUp, setUserSignUp] = useState(false);
	const [userPassword, setUserPassword] = useState(null);

	const firebaseApp = initializeApp(config);
	const auth = getAuth(firebaseApp);

	const getUser = () => auth.currentUser;
	const signIn = customToken => signInWithCustomToken(auth, customToken);

	const doPasswordReset = email => sendPasswordResetEmail(auth, email);
	const doPasswordUpdate = password => updatePassword(auth.currentUser, password);

	const isValidSignInWithEmailLink = link => isSignInWithEmailLink(auth, link);
	const signInWithLink = (email, link) => signInWithEmailLink(auth, email, link);
	const linkUserWithCredential = credential => linkWithCredential(auth.currentUser, credential);

	const doSignOut = () => {
		sessionStorage.removeItem('idToken');
		setAuthUser(null);
		return signOut(auth);
	};

	const initializeIdToken = () => getIdToken(auth.currentUser).then(
		token => {
			sessionStorage.setItem('idToken', token);
			return token;
		},
	);

	return {
		auth,
		setAuthUser,
		authUser,
		getUser,
		signIn,
		doPasswordReset,
		doPasswordUpdate,
		doSignOut,
		initializeIdToken,
		isValidSignInWithEmailLink,
		signInWithLink,
		linkUserWithCredential,
		onAuthStateChanged,
		userSignUp,
		setUserSignUp,
		userPassword,
		setUserPassword,
	};
}

export function ProvideFirebase({ children }) {
	const firebase = useProvideFirebase();
	return <firebaseContext.Provider value={firebase}>{children}</firebaseContext.Provider>;
}

export const useFirebase = () => useContext(firebaseContext);
