import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ConfirmModal from './ConfirmModal';
import useApi from '../../hooks/use-api';
import { useToast } from '../../hooks/use-toast';
import { putDocument } from '../../constants/routes';

function DeleteDocumentModal({
	show,
	setShow,
	title,
	message,
	successMessage,
	handleSubmit,
	documentId,
}) {
	const toast = useToast();
	const { t } = useTranslation();
	const [errorMessage, setErrorMessage] = useState(null);
	const [, deleteDocument] = useApi.delete(putDocument(documentId), {}, { lazy: true });

	const handleDeleteDocument = () => {
		deleteDocument()
			.then(() => {
				toast.addToast(successMessage);
				setShow(false);
				setErrorMessage(null);
				handleSubmit();
			}).catch(() => {
				setErrorMessage(t('documents.delete_error'));
			});
	};

	return (
		<ConfirmModal
			show={show}
			setShow={showValue => {
				setErrorMessage(null);
				setShow(showValue);
			}}
			title={title}
			message={message}
			handleSubmit={handleDeleteDocument}
			errorMessage={errorMessage}
		/>
	);
}

DeleteDocumentModal.propTypes = {
	documentId: PropTypes.number,
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	successMessage: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};

export default DeleteDocumentModal;
