import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from '../components/Modal/ConfirmModal';
import useApi from '../hooks/use-api';
import { parseErrorMessage } from '../utils/parseErrors';
import { useToast } from '../hooks/use-toast';

function DeleteUserModal({
	deleteEndpoint,
	show,
	setShow,
	title,
	message,
	successMessage,
	handleSubmit,
}) {
	const toast = useToast();
	const [errorMessage, setErrorMessage] = useState(null);
	const [, deleteUser] = useApi.delete(deleteEndpoint, {}, { lazy: true });

	const handleDeleteUser = () => {
		deleteUser()
			.then(() => {
				toast.addToast(successMessage);
				setShow(false);
				setErrorMessage(null);
				handleSubmit();
			}).catch(e => {
				setErrorMessage(parseErrorMessage(e));
			});
	};

	return (
		<ConfirmModal
			show={show}
			setShow={showValue => {
				setErrorMessage(null);
				setShow(showValue);
			}}
			title={title}
			message={message}
			handleSubmit={handleDeleteUser}
			errorMessage={errorMessage}
		/>
	);
}

DeleteUserModal.propTypes = {
	deleteEndpoint: PropTypes.string,
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	successMessage: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};

export default DeleteUserModal;
