import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ConfirmModal from './ConfirmModal';
import useApi from '../../hooks/use-api';
import { useToast } from '../../hooks/use-toast';
import { updateEstablishment } from '../../constants/routes';

function DeleteEstablishmentModal({
	show,
	setShow,
	title,
	message,
	successMessage,
	handleSubmit,
	establishmentId,
}) {
	const toast = useToast();
	const { t } = useTranslation();
	const [errorMessage, setErrorMessage] = useState(null);
	const [, deleteEstablishment] = useApi.delete(updateEstablishment(establishmentId), {}, { lazy: true });

	const handleDeleteEstablishment = () => {
		setErrorMessage(null);
		deleteEstablishment()
			.then(() => {
				toast.addToast(successMessage);
				setShow(false);
				setErrorMessage(null);
				handleSubmit();
			}).catch(() => {
				setErrorMessage(t('establishments.delete_error'));
			});
	};

	return (
		<ConfirmModal
			show={show}
			setShow={showValue => {
				setErrorMessage(null);
				setShow(showValue);
			}}
			title={title}
			message={message}
			handleSubmit={handleDeleteEstablishment}
			errorMessage={errorMessage}
		/>
	);
}

DeleteEstablishmentModal.propTypes = {
	establishmentId: PropTypes.number,
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	successMessage: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};

export default DeleteEstablishmentModal;
