import { utils, writeFile } from 'xlsx';

// Create XLSX workbook and sheet, download file
// eslint-disable-next-line import/prefer-default-export
export const getXLSXFile = (sheets, fileName) => {
	const wb = utils.book_new();

	sheets.forEach(sheet => {
		const { name, rows } = sheet;
		const ws = utils.json_to_sheet(rows);
		utils.book_append_sheet(wb, ws, name);
	});

	writeFile(wb, fileName);
};
