/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import StudentRisk from './StudentRisk';
import moment from '../../moment';
import { MAXIMUM_COMBINED_HOURS_PER_CYCLE } from '../../utils/code95';

function Code95RiskCard({ currentCycle = null, latestCycle = null, handleClick }) {
	const { t } = useTranslation();

	const glyphIconClassname = currentCycle?.practice
		? 'glyphicons glyphicons-square-empty-check text-success'
		: 'glyphicons glyphicons-square-empty-remove text-danger';

	const emptyCycleMessage = useMemo(() => {
		if(latestCycle && moment(latestCycle.startDate).isAfter(moment())) {
			return t('code95.future_code95', { start: moment(latestCycle.startDate).format('L') });
		}
		if(latestCycle && moment(latestCycle.endDate).isBefore(moment())) {
			return t('code95.code95_expired');
		}

		return t('code95.add_code95_cycle_required');
	}, [latestCycle]);

	return (
		<div className="card shadow rounded-xl d-inline-block" style={{ width: 350 }}>
			{currentCycle === null
				? (
					<div className="card-body m-0 p-1">
						<div className="text-center">
							<div className="d-inline-block glyphicons glyphicons-circle-plus opacity-3" style={{ cursor: 'pointer' }} onClick={handleClick} />
							<p className="text-muted">{emptyCycleMessage}</p>
						</div>
					</div>
				)
				: (
					<div className="card-body">
						<h6 className="text-primary-color">{t('code95.code95Progress')}</h6>
						<div className="d-flex justify-content-between align-items-center">
							<h3 className="text-primary-color">{`${currentCycle.points}/${MAXIMUM_COMBINED_HOURS_PER_CYCLE} ${t('code95.hour')}`}</h3>
							<div className="d-flex justify-content-end align-items-center">
								<span>{t('employees.practical')}</span>
								<div className="pt-1 px-2">
									<span className={glyphIconClassname} />
								</div>
							</div>
						</div>
						<span
							className="text-muted"
						>{`${t('code95.dueDate')}: ${currentCycle.endDate.format('L')}`}
						</span>
						<StudentRisk amount={currentCycle.points} endDate={currentCycle.endDate} />
					</div>
				)}
		</div>
	);
}

Code95RiskCard.propTypes = {
	currentCycle: PropTypes.shape({
		points: PropTypes.number.isRequired,
		endDate: PropTypes.instanceOf(moment).isRequired,
	}),
	latestCycle: PropTypes.shape({
		startDate: PropTypes.string.isRequired,
		endDate: PropTypes.string.isRequired,
	}),
	handleClick: PropTypes.func.isRequired,
};

export default Code95RiskCard;
