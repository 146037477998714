import CompanyLocale from './pages/Company/locale.json';
import FormLocale from './components/Form/locale.json';
import TableLocale from './components/Tables/locale.json';
import EmployeeLocale from './pages/Employees/locale.json';
import GeneralLocale from './pages/locale.json';
import ModalLocale from './components/Modal/locale.json';
import DationInstanceLocale from './pages/DationInstance/locale.json';

const locale = {
	nl: {
		translation: {
			name: 'Portaal',
			portal: {
				loginText: 'Inloggen portaal',
				login: 'Inloggen',
				logout: 'Uitloggen',
				loading: 'Aan het laden...',
				add: 'Toevoegen',
				email_placeholder: 'E-mailadres',
				password_placeholder: 'Wachtwoord',
				error: 'Een interne fout is opgetreden. Probeer het later nog eens. Neem contact op met de helpdesk indien dit probleem zich voor blijft doen.',
				set_password: 'Nieuw wachtwoord instellen',
				set_password_placeholder: 'Nieuw wachtwoord',
				set_password_button_text: 'Wachtwoord opslaan',
				link_expired: 'De login link is verlopen, vraag een nieuwe aan',
				terms_and_conditions: 'algemene voorwaarden',
				agreed: 'Akkoord met de',
				password_minimum_characters: 'Minimaal 8 tekens',
				password_minimum_digits: 'Minimaal 1 cijfer',
				password_minimum_lowercase_characters: 'Minimaal 1 kleine letter',
				password_minimum_uppercase_characters: 'Minimaal 1 hoofdletter',
				email_not_valid: 'Voer een geldig e-mailadres in',
				signup_error_text: 'De link om je wachtwoord in te stellen is verlopen, vraag een nieuwe aan bij je rijopleider',
				name: 'Dation Certificate Portal',
				remember_password: 'Wachtwoord vergeten',
				reset_password: 'Wachtwoord opnieuw instellen',
				reset_password_email: 'Voer je e-mailadres in om je wachtwoord opnieuw te kunnen instellen',
				sent_email: 'Verzend e-mail',
				email_send: 'E-mail verzonden!',
				reset_email_confirmation_text: 'Stel je wachtwoord opnieuw in met de link in je e-mail',
				chat_with_support: 'Chat met support',
			},
			navigation: {
				settings: {
					settings: 'Instellingen',
				},
				companyUser: {
					employees: 'Medewerkers',
					planning: 'Planning',
					offer: 'Cursusagenda',
					dashboard: 'Dashboard',
					certificates: 'Certificaten',
				},
				dationUser: {
					companies: 'Bedrijven',
					users: 'Gebruikers',
				},
				adminUser: {
					dation_instances: 'Opleiders',
					statistics: 'Statistieken',
				},
			},
			...CompanyLocale,
			...FormLocale,
			...TableLocale,
			...EmployeeLocale,
			...GeneralLocale,
			...ModalLocale,
			...DationInstanceLocale,
		},
	},
};

export default locale;
