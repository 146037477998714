/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useApi } from '../hooks/use-api';
import { resetPasswordRequestPage } from '../constants/routes';
import PortalCheck from '../assets/images/bvo_portal_check.png';
import Logo from '../assets/images/logo.png';

function ForgetPasswordPage() {
	const { t } = useTranslation();
	const [email, setEmail] = useState('');
	const [isSent, setIsSent] = useState(false);
	const [validated, setValidated] = useState(false);

	const [state, sendEmail] = useApi.post(
		resetPasswordRequestPage(),
		{},
		{ lazy: true },
	);

	const onSubmit = event => {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;

		if(form.checkValidity() !== false) {
			sendEmail({ emailAddress: email });
			setIsSent(true);
		}
		setValidated(true);
	};
	return isSent ? (
		<div>
			<h2 className="text-primary-color">Je aanvraag is gelukt!</h2>
			<img src={PortalCheck} style={{ height: 120 }} className="d-block mx-auto my-5" alt={t('portal.name')} />
			<p className="text-muted">{t('portal.reset_email_confirmation_text')} </p>
		</div>
	) : (
		<Form noValidate validated={validated} onSubmit={onSubmit}>
			<img src={Logo} className="w-75 mb-4 mx-auto d-block" alt={t('portal.name')} />

			<h5>{t('portal.reset_password')}</h5>

			<div className="mb-3">
				<input
					type="email"
					className="form-control"
					value={email}
					required
					onChange={e => setEmail(e.target.value)}
					placeholder={t('portal.email_placeholder')}
					autoComplete="username"
				/>
				<Form.Control.Feedback type="invalid">
					{t('portal.email_not_valid')}
				</Form.Control.Feedback>
				<div className="mt-2">
					<small className="text-muted">{t('portal.reset_password_email')}</small>
				</div>
			</div>
			<div className="d-grid">
				<button type="submit" className="btn btn-primary">{t('portal.sent_email')}</button>
			</div>
		</Form>
	);
}

export default ForgetPasswordPage;
