import React from 'react';
import Message from './Message';

function SuccessMessage(props) {
	return <Message variant="success" {...props} />;
}

SuccessMessage.propTypes = Message.propTypes;

export default SuccessMessage;
