import React from 'react';
import PropTypes from 'prop-types';

function SmallFormLabel({ label }) {
	return <label className="text-secondary mb-n2 font-size-small" htmlFor={label}>{label}</label>;
}

SmallFormLabel.propTypes = {
	label: PropTypes.string.isRequired,
};

export default SmallFormLabel;
