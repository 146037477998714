import React from 'react';
import {
	useSortBy, useTable, useGlobalFilter, usePagination,
} from 'react-table';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import TableHeader from './Default/TableHeader';
import TableGlobalFilter from './Default/TableGlobalFilter';
import * as ROUTES from '../../constants/routes';
import DefaultTableBody from './Default/DefaultTableBody';

function DationInstanceTable({ columns, data }) {
	const navigate = useNavigate();

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state: { globalFilter },
		setGlobalFilter,
		rows,
	} = useTable({
		columns,
		data,
		initialState: {
			sortBy: [
				{
					id: 'name',
					desc: false,
				},
			],
		},
	}, useGlobalFilter, useSortBy, usePagination);
	return (
		<div className="col-12 col-xl-10 mx-auto pt-n5">
			<div className="table-responsive overflow-hidden">
				<TableGlobalFilter setGlobalFilter={setGlobalFilter} globalFilter={globalFilter} />
				<table {...getTableProps()} className="table table-large table-hover">
					<TableHeader headerGroups={headerGroups} />
					<DefaultTableBody
						getTableBodyProps={getTableBodyProps}
						data={rows}
						prepareRow={prepareRow}
						handleClick={({ id }) => navigate(ROUTES.dationInstancePage(id))}
					/>
				</table>
			</div>
		</div>
	);
}

DationInstanceTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
};

export default DationInstanceTable;
