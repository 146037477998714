import React from 'react';
import PropTypes from 'prop-types';

// Compact form group with vertical label/value. Will have to be adapted to support editing, when required
function FormGroupPlainText({
	label,
	value,
	className,
}) {
	return (
		<div className={`form-group ${className ?? ''}`}>
			<label className="text-secondary mb-n2 font-size-small" htmlFor={label}>{label}</label>
			<span className="form-control-plaintext text-primary-color p-0 item" id={label}>{value}</span>
		</div>
	);
}

FormGroupPlainText.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	className: PropTypes.string,
};
export default FormGroupPlainText;
