/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import LoginPage from '../../pages/LoginPage';
import SignUpPage from '../../pages/SignUpPage';
import ForgetPasswordPage from '../../pages/ForgetPasswordPage';
import MaintenancePage from '../../pages/MaintenancePage';

function AnonUserRouter() {
	return (
		<Switch>
			<Route exact path={ROUTES.LOGIN} element={(<LoginPage />)} />
			<Route exact path={ROUTES.resetPasswordRequestPage()} element={<ForgetPasswordPage />} />
			<Route exact path={ROUTES.resetPasswordPage()} element={<SignUpPage />} />
			<Route exact path={ROUTES.MAINTENANCE} element={<MaintenancePage />} />
			<Route path="/*" element={(<LoginPage />)} />
		</Switch>
	);
}

export default AnonUserRouter;
