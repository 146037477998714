import React from 'react';
import { useTranslation } from 'react-i18next';
import useApi from '../../hooks/use-api';
import { getEstablishmentsForCompany } from '../../constants/routes';
import { useDationUser } from '../../hooks/dation-user';

function EstablishmentSelect({
	establishmentId, setEstablishmentId,
}) {
	const { t } = useTranslation();
	const { getCurrentCompanyId } = useDationUser();
	const [{ data: establishmentData }] = useApi.get(getEstablishmentsForCompany(getCurrentCompanyId()));

	return establishmentData?.length > 0 ? (
		<dl className="mt-3">
			<dt className="mb-1 text-primary-color">{t('employees.establishment')}</dt>
			<select
				className="form-select"
				value={establishmentId}
				onChange={e => setEstablishmentId(parseInt(e.target.value, 10))}
			>
				<option value={0}>{t('modalFields.allEstablishments')}</option>
				{establishmentData.map(establishment => (
					<option key={establishment.id} value={establishment.id}>{establishment.name}</option>
				))}
			</select>
		</dl>
	) : null;
}

export default EstablishmentSelect;
