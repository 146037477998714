import React from 'react';
import { useSortBy, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import TableHeader from './Default/TableHeader';
import DefaultTableBody from './Default/DefaultTableBody';

function EducationsTable({ columns, data }) {
	const { t } = useTranslation();

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
	} = useTable({
		columns,
		data,
		initialState: {
			sortBy: [
				{
					id: 'date',
					desc: true,
				},
			],
		},
	}, useSortBy);

	return (
		<>
			<div className="d-flex justify-content-between">
				<h5>
					<span className="glyphicons glyphicons-history me-2 ms-3" />
					{t('educations.planning')}
				</h5>
			</div>
			<div className="table-responsive overflow-hidden">
				<table {...getTableProps()} className="table table-large">
					<TableHeader headerGroups={headerGroups} />
					<DefaultTableBody
						getTableBodyProps={getTableBodyProps}
						data={rows}
						prepareRow={prepareRow}
					/>
				</table>
			</div>
		</>
	);
}

export default EducationsTable;
