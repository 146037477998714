/* eslint-disable import/prefer-default-export */
import moment from '../moment';

export const sortByDate = (a, b, { order = 'desc' } = {}) => (
	order === 'desc'
		? moment(b).valueOf() - moment(a).valueOf()
		: moment(a).valueOf() - moment(b).valueOf()
);

export const getWeekdayFormat = date => moment(date).format('dd');

export const getDateFormat = date => moment(date).format('L');

export const getWeekdayDateFormat = date => moment(date).format('dd L');

export const getWeekdayDateTimeFormat = date => moment(date).format('dd L LT');

export const getTimeDateFormat = date => moment(date).format('LT');

export const getStartOfDayFullDateFormat = date => moment(date).startOf('day').format();

export const DEFAULT_RISKY_EXPIRATION_PERIOD_IN_DAYS = 180;
export const isExpirationDateRisky = expirationDate => {
	if(expirationDate === null) {
		return false;
	}

	const date = moment(expirationDate);
	const duration = moment.duration(moment().diff(date));

	return duration.as('days') > -DEFAULT_RISKY_EXPIRATION_PERIOD_IN_DAYS;
};
