/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useApi from '../hooks/use-api';
import { getEstablishmentsForCompany } from '../constants/routes';
import InfoBar from '../components/Navigation/InfoBar';
import { useDationUser } from '../hooks/dation-user';
import EstablishmentsTable from '../components/Tables/EstablishmentsTable';
import EstablishmentModal from '../components/Modal/EstablishmentModal';
import * as ROUTES from '../constants/routes';
import DeleteEstablishmentModal from '../components/Modal/DeleteEstablishmentModal';

function EstablishmentListPage() {
	const dationUser = useDationUser();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [establishments, setEstablishments] = useState([]);
	const [selectedEstablishment, setSelectedEstablishment] = useState([]);
	const [showEditModal, setEditModal] = useState(false);
	const [showDeleteModal, setDeleteModal] = useState(false);

	const [{ data, isLoading }, fetchCompanyEstablishments] = useApi.get(getEstablishmentsForCompany(dationUser.companyId), { cachePolicy: 'no-cache' });

	const deleteCell = useCallback(cellInfo => {
		const { row: { original } } = cellInfo;
		return (
			<div className="text-end">
				<span
					className="text-center glyphicons glyphicons-bin clickable b-left d-inline-block p-0 chip-glyphicon cursor-pointer"
					id={`remove-user-${original.id}`}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						setSelectedEstablishment(original);
						setDeleteModal(true);
					}}
				/>
			</div>
		);
	}, []);

	const columns = React.useMemo(() => [
		{
			Header: t('establishments.establishment'),
			accessor: 'name',
		},
		{
			accessor: 'delete',
			Cell: deleteCell,
		},
	], [t, establishments]);

	useEffect(() => {
		if(data) {
			setEstablishments(data);
		}
	}, [data]);
	return (
		<>
			<InfoBar
				backTitle={t('establishments.establishments')}
				goBack={() => navigate(ROUTES.SETTINGS)}
			/>
			{isLoading
				? <div className="loading-spinner" />
				: (
					<>
						<div className="d-flex flex-row-reverse mx-5">
							<Button
								variant="outline-secondary"
								className="rounded-button p-1"
								type="button"
								onClick={() => {
									setSelectedEstablishment(null);
									setEditModal(true);
								}}
							>
								<span className="glyphicons glyphicons-plus" />
							</Button>
						</div>
						<>
							<EstablishmentModal
								show={showEditModal}
								setShow={() => {
									setEditModal(false);
								}}
								selectedEstablishment={selectedEstablishment}
								handleSubmit={fetchCompanyEstablishments}
							/>
							<DeleteEstablishmentModal
								show={showDeleteModal}
								setShow={show => {
									setSelectedEstablishment({});
									setDeleteModal(show);
								}}
								establishmentId={selectedEstablishment?.id}
								title={t('establishments.delete')}
								message={t('establishments.confirm_delete', { name: selectedEstablishment?.name })}
								successMessage={t('establishments.deleted', { name: selectedEstablishment?.name })}
								handleSubmit={fetchCompanyEstablishments}
							/>
							<EstablishmentsTable
								className="col-12 col-xl-10 mx-auto pt-n5"
								columns={columns}
								data={establishments || []}
								handleClick={original => {
									setSelectedEstablishment(original);
									setEditModal(true);
								}}
							/>
						</>
					</>
				)}
		</>
	);
}

export default EstablishmentListPage;
