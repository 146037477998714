import React, {
	createContext, useContext, useEffect, useState,
} from 'react';
import useUserPreferences from '../use-user-preferences';

const tabsContext = createContext();

// eslint-disable-next-line import/prefer-default-export
export function useTabs(tabs, tabKey, defaultKey) {
	const { setTabKey, getTabKey } = useUserPreferences();
	const [key, setKey] = useState(null);

	useEffect(() => {
		if(tabs.length) {
			const userPreferenceKey = getTabKey(tabKey);
			const memoryKey = tabs.find(tab => tab === userPreferenceKey) ? userPreferenceKey : null;

			setKey(defaultKey || memoryKey || tabs[0]);
		}
	}, [tabs]);

	const handleKeyChange = k => {
		setTabKey(tabKey, k);
		setKey(k);
	};

	const isTabActive = tab => tab === key;

	return {
		key,
		tabs,
		isTabActive,
		setTabKey,
		handleKeyChange,
	};
}

export function ProvideTabs({ initialState, children }) {
	const { tabs, tabKey, defaultKey } = initialState;
	const tabsState = useTabs(tabs, tabKey, defaultKey);
	return <tabsContext.Provider value={tabsState}>{children}</tabsContext.Provider>;
}

export const useTabsHook = () => useContext(tabsContext);
