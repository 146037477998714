import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentModal from '../../components/Modal/DocumentModal';
import DocumentsTable from '../../components/Tables/DocumentsTable';
import { useApi } from '../../hooks/use-api';
import * as ROUTES from '../../constants/routes';
import DeleteDocumentModal from '../../components/Modal/DeleteDocumentModal';
import RightHeaderButton from '../../components/Tables/Default/RightHeaderButton';
import { getWeekdayDateFormat } from '../../utils/date';
import { useDationUser } from '../../hooks/dation-user';

function Documents({ employeeId }) {
	const { t } = useTranslation();
	const { isDationInstanceUser } = useDationUser();

	const [showEditModal, setEditModal] = useState(false);
	const [showDeleteModal, setDeleteModal] = useState(false);
	const [selectedDocument, setSelectedDocument] = useState(null);

	const [{
		data: documents,
		isLoading: loadingDocuments,
	}, fetchEmployeeDocuments] = useApi.get(ROUTES.getDocumentsForStudent(employeeId));

	const handleDelete = e => {
		e.preventDefault();
		e.stopPropagation();
		setEditModal(false);
		setDeleteModal(true);
	};

	const documentsTableHeaders = React.useMemo(() => [
		{
			Header: t('documents.name'),
			accessor: 'fileName',
		},
		{
			Header: t('documents.date'),
			accessor: 'creationDate',
			Cell: ({ row }) => {
				const { original } = row;
				return getWeekdayDateFormat(original?.creationDate);
			},
		},
	], [t]);

	const headerRight = () => (isDationInstanceUser() ? null : (
		<RightHeaderButton
			title={t('documents.add')}
			icon="glyphicons-file-plus"
			onClick={() => {
				setSelectedDocument(null);
				setEditModal(true);
			}}
		/>
	));

	return (
		<>
			<DeleteDocumentModal
				show={showDeleteModal}
				setShow={show => {
					setSelectedDocument(null);
					setDeleteModal(show);
				}}
				documentId={selectedDocument?.id}
				title={t('documents.delete')}
				message={t('documents.confirm_delete', { name: selectedDocument?.name })}
				successMessage={t('documents.deleted', { name: selectedDocument?.name })}
				handleSubmit={fetchEmployeeDocuments}
			/>
			<DocumentModal
				show={showEditModal}
				setShow={() => setEditModal(false)}
				selectedEntity={selectedDocument}
				handleSubmit={fetchEmployeeDocuments}
				studentId={employeeId}
				handleDelete={handleDelete}
			/>
			{!loadingDocuments && (
				<DocumentsTable
					columns={documentsTableHeaders}
					data={documents || []}
					renderHeaderRight={headerRight}
					handleClick={original => {
						setSelectedDocument(original);
						setEditModal(true);
					}}
				/>
			)}
		</>
	);
}

export default Documents;
