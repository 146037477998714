import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from './Tooltip';

function PlanningTooltip({ isReservation = false }) {
	const { t } = useTranslation();

	const getTranslationKey = () => {
		const prefix = isReservation ? 'reservation_' : 'enrollment_';
		return `${prefix}disabled_info`;
	};

	const popoverContent = () => (
		<>
			<strong>{t('tooltip.attention')}</strong>
			&nbsp;
			{t(`unsubscribe.${getTranslationKey()}`)}
			&nbsp;
			{t('unsubscribe.contact')}
		</>
	);

	return (
		<Tooltip renderPopoverContent={popoverContent}>
			<span
				style={{ width: 20, transform: 'translateY(1px)' }}
				className="text-center text-secondary glyphicons glyphicons-circle-info clickable b-left d-inline-block p-0 chip-glyphicon"
				id="disenrollment-disabled-info"
			/>
		</Tooltip>
	);
}

export default PlanningTooltip;
