import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMergeState from '../use-merge-state';
import { parseErrorMessage } from '../../utils/parseErrors';
import useApi from '../use-api';

const usePostPutModalState = entityProps => {
	const { t } = useTranslation();
	const [entity, setEntity] = useMergeState(entityProps?.initialEntity);

	const [isValidated, setIsValidated] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isLoading, setLoading] = useState(false);

	const [, postEntity] = useApi.post(entityProps?.postEntityRoute(), {}, { lazy: true });
	const [, updateEntity] = useApi.put(entityProps?.putEntityRoute(entity?.id), {}, { lazy: true });

	const handleClose = () => {
		setEntity(entityProps?.initialEntity);
		setErrorMessage(null);
		setIsValidated(false);
		setIsDisabled(false);
	};

	const submitEntity = extraBodyData => new Promise((resolve, reject) => {
		setErrorMessage(null);
		setLoading(true);
		let submit;
		let successMessage;

		const params = { name: entity.name };
		if(entity.id) {
			submit = updateEntity;
			successMessage = t(`${entityProps?.name}.edited`, params);
		} else {
			submit = postEntity;
			successMessage = t(`${entityProps?.name}.created`, params);
		}

		setIsDisabled(true);
		submit({ ...entity, ...extraBodyData })
			.then(() => {
				resolve(successMessage);
			})
			.catch(e => {
				setErrorMessage(parseErrorMessage(e));
				setIsDisabled(false);
				setIsValidated(true);
				reject();
			})
			.finally(() => setLoading(false));
	});

	return {
		isValidated,
		setIsValidated,
		isDisabled,
		setIsDisabled,
		isLoading,
		setLoading,
		errorMessage,
		setErrorMessage,
		setEntity,
		entity,
		handleClose,
		submitEntity,
	};
};

export default usePostPutModalState;
