import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Tab, Button } from 'react-bootstrap';
import InfoBar from '../components/Navigation/InfoBar';
import CourseListTable from '../components/Tables/CourseListTable';
import { useDationUser } from '../hooks/dation-user';
import { compareTableSortDates } from '../utils/sort';
import useApi from '../hooks/use-api';
import { getPlannedTrainingsForCompany } from '../constants/routes';
import PlannedTrainingModal from '../components/Modal/PlannedTrainingModal/PlannedTrainingModal';
import {
	findExistingReservation,
	getCalculatedFreeCapacity,
	getCalculatedReservationAmounts,
	transformSlotForFutureSubRow,
	transformSlotForPastSubRow,
	transformTrainingDataForCompany,
} from '../utils/training';
import EnrollEmployeeModal from '../components/Modal/EnrollEmployeesModal';
import moment from '../moment';
import { getStartOfDayFullDateFormat } from '../utils/date';
import ReservationModal from '../components/Modal/ReservationModal/ReservationModal';
import TabsHeader from '../components/Tabs/TabsHeader';
import { useTabs } from '../hooks/use-tabs';
import { CODE_95_ENABLED, useViewPreferences } from '../hooks/view-preferences';

const PLANNING_OVERVIEW_TAB = 'planning_overview_tab';

// These must match with the tab translation key
const FUTURE_PLANNING_TAB = 'future';
const PAST_PLANNING_TAB = 'past';

const tabs = [FUTURE_PLANNING_TAB, PAST_PLANNING_TAB];

function PlanningPage() {
	const { t } = useTranslation();
	const dationUser = useDationUser();
	const { key, handleKeyChange } = useTabs(tabs, PLANNING_OVERVIEW_TAB);
	const { isPreferenceOptionEnabled } = useViewPreferences();

	const [pastBody, setPastBody] = useState([]);
	const [futureBody, setFutureBody] = useState([]);
	const [training, setTraining] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [showEnrollModal, setShowEnrollModal] = useState(false);
	const [showReservationModal, setShowReservationModal] = useState(false);
	const [selectedTraining, setSelectedTraining] = useState(null);

	const code95Enabled = isPreferenceOptionEnabled(CODE_95_ENABLED);

	const [{ data: pastData }, fetchPastTrainings] = useApi.get(
		getPlannedTrainingsForCompany(dationUser.companyId),
		{ startDateBefore: getStartOfDayFullDateFormat(moment()) },
	);
	const [{ data: futureData }, fetchFutureTrainings] = useApi.get(
		getPlannedTrainingsForCompany(dationUser.companyId),
		{ startDateAfter: getStartOfDayFullDateFormat(moment()) },
	);

	const expanderCell = useCallback(({ row }) => {
		const { slots } = row.original || [];
		const chevronStyle = row.isExpanded ? 'glyphicons-chevron-down text-primary' : 'glyphicons-chevron-up text-secondary';
		return slots ? (
			<span
				{...row.getToggleRowExpandedProps({ title: null })}
			>
				<span className={`glyphicons ${chevronStyle}`} />
			</span>
		) : null;
	}, []);

	const startDateCell = useCallback(cellInfo => {
		const { row } = cellInfo;
		return (
			<div className={`${!row.original.isSubRow && row.isExpanded ? 'text-primary' : 'text-primary-color'}`}>
				{row.original.startDate}
			</div>
		);
	}, []);

	const reservationCell = useCallback(({ row: { original: { trainingReservations } } }) => {
		const existingReservation = findExistingReservation(trainingReservations, dationUser.getCurrentCompanyId()) || {};
		const { amountPlanned, amountReserved } = getCalculatedReservationAmounts(
			existingReservation,
			dationUser.getCurrentCompanyId(),
		);
		return amountReserved ? (
			<div>{amountPlanned} / {amountReserved}</div>
		) : null;
	}, []);

	const headers = React.useMemo(() => [
		{
			id: 'expander',
			Header: () => null,
			Cell: expanderCell,
			disableFilters: true,
		},
		{
			Header: t('trainings.date'),
			accessor: 'startDate',
			sortType: compareTableSortDates,
			Cell: startDateCell,
			disableFilters: true,
		},
		...(code95Enabled ? [{
			Header: t('trainings.ccvCode'),
			accessor: 'ccvCode',
			disableFilters: true,
		}] : []),
		{
			Header: t('trainings.training'),
			accessor: 'name',
			disableFilters: true,
		},
		{
			Header: t('trainings.location'),
			accessor: 'city',
			disableFilters: true,
		},
		{
			Header: t('trainings.reservations'),
			accessor: 'trainingReservations',
			Cell: reservationCell,
			disableFilters: true,
		},
	], [t]);

	const actionCell = useCallback(cellInfo => {
		const { row: { original } } = cellInfo;
		const trainingReservation = findExistingReservation(original.trainingReservations, dationUser.getCurrentCompanyId());
		const disableButtons = trainingReservation ? (
			original.freeCapacity === 0 && trainingReservation.amountPlanned === trainingReservation.amountReserved
		) : original.freeCapacity === 0;
		return !dationUser.isDationInstanceUser() && !original.isSubRow ? (
			<div className="d-flex flex-row-reverse">
				<Button
					className="ms-1"
					variant="outline-primary"
					type="button"
					size="sm"
					disabled={disableButtons}
					onClick={e => {
						e.stopPropagation();
						setSelectedTraining(original);
						setShowReservationModal(true);
					}}
				>
					{t('trainings.reserve')}
				</Button>
				{!original.composite && (
					<Button
						variant="outline-primary"
						type="button"
						size="sm"
						disabled={disableButtons}
						onClick={e => {
							e.stopPropagation();
							setSelectedTraining(original);
							setShowEnrollModal(true);
						}}
					>
						{t('trainings.enroll')}
					</Button>
				)}
			</div>
		) : null;
	}, [dationUser]);

	const futureHeaders = [...headers,
		{
			Header: t('trainings.capacity'),
			accessor: 'freeCapacity',
			Cell: ({ row: { original: { freeCapacity, trainingReservations } } }) => {
				const existingReservation = findExistingReservation(trainingReservations, dationUser.getCurrentCompanyId()) || {};
				return getCalculatedFreeCapacity(freeCapacity, existingReservation);
			},
			disableFilters: true,
		},
		{
			accessor: 'id',
			Cell: actionCell,
			disableFilters: true,
		}];

	const handleDisenrollment = () => {
		if(!_.isEmpty(training)) {
			const updatedTraining = futureData.find(newTraining => newTraining.id === training.id);
			setTraining(updatedTraining || {
				...training,
				enrollments: [],
			});
		}
	};

	useEffect(() => {
		if(pastData) {
			setPastBody(transformTrainingDataForCompany(pastData));
		}
	}, [pastData]);

	useEffect(() => {
		if(futureData) {
			setFutureBody(transformTrainingDataForCompany(futureData));
			handleDisenrollment();
		}
	}, [futureData]);

	const handleSubmit = () => {
		fetchFutureTrainings();
		fetchPastTrainings();
	};

	const showDisenroll = key === FUTURE_PLANNING_TAB && !dationUser.companyToViewName;

	return (
		<>
			<InfoBar />
			<EnrollEmployeeModal
				show={showEnrollModal}
				setShow={setShowEnrollModal}
				training={selectedTraining}
				handleSubmit={handleSubmit}
			/>
			<PlannedTrainingModal
				show={showModal}
				setShow={setShowModal}
				training={training}
				showDisenroll={showDisenroll}
				handleSubmit={fetchFutureTrainings}
			/>
			<ReservationModal
				show={showReservationModal}
				setShow={setShowReservationModal}
				training={selectedTraining}
				setTraining={setSelectedTraining}
				handleSubmit={handleSubmit}
			/>
			<div className="row pb-5">
				<div className="col-sm-12 col-xl-10 mx-auto">
					<Tab.Container id="planning-tabs" className="col-12 col-xl-10 mx-auto my-5" activeKey={key} onSelect={k => handleKeyChange(k)}>
						<TabsHeader
							options={{ className: 'mb-3', i18nPrefix: 'tabs' }}
							tabKey={key}
							tabs={tabs}
						/>
						<Tab.Content>
							<Tab.Pane eventKey={FUTURE_PLANNING_TAB}>
								{ futureData === null
									? <div className="loading-spinner" />
									: (
										<CourseListTable
											orderDesc={false}
											data={futureBody}
											columns={futureHeaders}
											transformSubRow={transformSlotForFutureSubRow}
											handleClick={original => {
												setTraining(original);
												setShowModal(true);
											}}
										/>
									)}
							</Tab.Pane>
							<Tab.Pane eventKey={PAST_PLANNING_TAB}>
								{ pastData === null
									? <div className="loading-spinner" />
									: (
										<CourseListTable
											data={pastBody}
											columns={headers}
											transformSubRow={transformSlotForPastSubRow}
											handleClick={original => {
												setTraining(original);
												setShowModal(true);
											}}
										/>
									)}
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>
				</div>
			</div>
		</>
	);
}

export default PlanningPage;
