import React, { useEffect, useState } from 'react';
import {
	Button, Col, Form, FormControl, FormLabel, Modal, ModalBody, ModalFooter, ModalTitle, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useMergeState from '../../hooks/use-merge-state';
import * as ROUTES from '../../constants/routes';
import useApi from '../../hooks/use-api';
import { useToast } from '../../hooks/use-toast';
import { parseErrorMessage } from '../../utils/parseErrors';
import { useDationUser } from '../../hooks/dation-user';
import ErrorMessage from '../common/messages/ErrorMessage';

const EstablishmentModal = React.memo(({
	show, setShow, handleSubmit, selectedEstablishment,
}) => {
	const { t } = useTranslation();
	const toast = useToast();
	const dationUser = useDationUser();

	const initialEstablishment = {
		id: null,
		name: null,
	};

	const [isValidated, setIsValidated] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [establishment, setEstablishment] = useMergeState(initialEstablishment);

	const [, postEstablishment] = useApi.post(ROUTES.postEstablishment());
	const [, updateEstablishment] = useApi.patch(ROUTES.updateEstablishment(establishment?.id));

	useEffect(() => {
		setEstablishment(selectedEstablishment !== null ? selectedEstablishment : initialEstablishment);
	}, [selectedEstablishment, show]);

	const close = () => {
		setShow(false);
		setEstablishment(initialEstablishment);
		setErrorMessage(null);
		setIsValidated(false);
		setIsDisabled(false);
	};

	const handleResponse = successMessage => {
		close();
		handleSubmit();
		toast.addToast(successMessage);
		setIsDisabled(false);
	};

	const submit = event => {
		event.stopPropagation();
		event.preventDefault();
		let submitEstablishment;
		let successMessage;
		let establishmentData = establishment;
		const params = { name: establishment.name };
		if(establishment.id) {
			submitEstablishment = updateEstablishment;
			successMessage = t('establishments.edited', params);
		} else {
			submitEstablishment = postEstablishment;
			establishmentData = { ...establishment, company: { id: dationUser.companyId } };
			successMessage = t('establishments.created', params);
		}

		setIsDisabled(true);
		submitEstablishment(establishmentData)
			.then(() => handleResponse(successMessage))
			.catch(e => {
				setErrorMessage(parseErrorMessage(e));
				setIsDisabled(false);
				setIsValidated(true);
			});
	};

	const handleChange = ({ target }) => setEstablishment({ name: target.value });

	return (
		<Modal show={show} onHide={close} size="lg">
			<Form noValidate validated={isValidated} onSubmit={submit}>
				<Modal.Header closeButton={!isDisabled}>
					<ModalTitle>{establishment.id ? t('establishments.edit') : t('establishments.add')}</ModalTitle>
				</Modal.Header>
				<ModalBody>
					<ErrorMessage message={errorMessage} />
					<Row className="mb-12">
						<Col xs={12} md={12}>
							<FormLabel>{t('establishments.name')}</FormLabel>
							<FormControl id="establishment-name" type="text" value={establishment.name ?? ''} onChange={handleChange} />
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button variant="outline-secondary" disabled={isDisabled} onClick={close}>{t('modalActions.cancel')}</Button>
					<Button variant="primary" disabled={isDisabled} type="submit">{t('modalActions.edit')}</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);
});

export default EstablishmentModal;
