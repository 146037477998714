import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getDisplayName } from '../../../utils/person';

function StudentAccordionContent({
	student, onDisenroll, showDisenroll, disabled, isHidden,
}) {
	const [showConfirmation, setShowConfirmation] = useState(false);
	const { t } = useTranslation();
	return (
		<Card.Body
			className={`card my-3 py-2 text-primary-color flex-row flex-row justify-content-between ${isHidden && 'd-none'}`}
			key={student.id}
		>
			{showConfirmation ? (
				<>
					<div>{t('unsubscribe.confirmation', { name: getDisplayName(student) })}</div>
					<button
						type="button"
						className="btn btn-primary btn-sm"
						onClick={onDisenroll}
						disabled={disabled}
					>
						{t('modalActions.confirm')}
					</button>
					<button
						type="button"
						className="btn btn-secondary btn-sm"
						onClick={() => setShowConfirmation(false)}
					>
						{t('modalActions.cancel')}
					</button>
				</>
			) : (
				<>
					<div>{getDisplayName(student)}</div>
					{showDisenroll && (
						<button
							type="button"
							className="btn btn-primary btn-sm"
							onClick={() => setShowConfirmation(true)}
							disabled={disabled}
						>
							{t('unsubscribe.button')}
						</button>
					)}
				</>
			)}
		</Card.Body>
	);
}

export default StudentAccordionContent;
