/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CompanyStudentTable from '../Tables/CompanyStudentTable';
import { compareTableDates } from '../../utils/sort';
import EstablishmentFilter from '../common/EstablishmentFilter';

const ESTABLISHMENT_FILTER_KEY = 'establishmentId';

function StudentsOverview({
	students,
	extraColumnHeaders = [],
	establishmentData,
}) {
	const { t } = useTranslation();
	const [body, setBody] = useState([]);

	const hiddenHeaders = [
		{
			Header: ESTABLISHMENT_FILTER_KEY,
			accessor: ESTABLISHMENT_FILTER_KEY,
		},
	];

	const defaultHeaders = [
		{
			Header: t('employees.lastName'),
			accessor: 'lastNameAndInsertion',
			sortType: (a, b) => a.original.lastNameAndInsertion.trim().localeCompare(b.original.lastNameAndInsertion.trim()),
			disableFilters: true,
		},
		{
			Header: t('employees.firstName'),
			accessor: 'firstName',
			disableFilters: true,
		},
		{
			Header: t('employees.initials'),
			accessor: 'initials',
			disableFilters: true,
		},
		{
			Header: t('employees.dateOfBirth'),
			accessor: 'dateOfBirth',
			sortType: (a, b) => compareTableDates('dateOfBirthSort', a, b),
			disableFilters: true,
		},
	];

	const headers = React.useMemo(() => [
		...defaultHeaders,
		...extraColumnHeaders,
		...hiddenHeaders,
	], [defaultHeaders, extraColumnHeaders, hiddenHeaders]);

	const renderCustomFilters = React.useCallback(
		({ filters, setFilter }) => (
			<EstablishmentFilter
				filters={filters}
				setFilter={setFilter}
				establishments={establishmentData}
			/>
		),
		[establishmentData],
	);

	useEffect(() => {
		if(students) {
			const newBody = students.map(student => ({
				...student,
				[ESTABLISHMENT_FILTER_KEY]: student.establishment?.id, // needed for establishment filter
			}));

			setBody(newBody);
		}
	}, [students]);

	return (
		<CompanyStudentTable
			columns={headers}
			data={body}
			renderCustomFilters={renderCustomFilters}
			sortByProps={{ id: 'lastNameAndInsertion', desc: false }}
		/>
	);
}

StudentsOverview.propTypes = {
	students: PropTypes.array.isRequired,
	extraColumnHeaders: PropTypes.array,
	establishmentData: PropTypes.array,
};

export default StudentsOverview;
