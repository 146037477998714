/* eslint-disable react-hooks/exhaustive-deps, react/no-unstable-nested-components */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { compareTableDates } from '../../../utils/sort';
import Code95Filter, { code95Filter } from '../../Tables/Filters/Code95Filter';
import BooleanFilter, { booleanFilter } from '../../Tables/Filters/BooleanFilter';
import IsBeforeFilter, { isBeforeFilter } from '../../Tables/Filters/IsBeforeFilter';
import StudentsOverview from '../StudentsOverview';

// These must match with the tab translation key
export const CODE_95_STUDENTS_TAB = 'code95Students';

function Code95StudentsOverviewTab({ students, establishmentData }) {
	const { t } = useTranslation();

	const sortTableCode95ExpirationDate = (a, b) => compareTableDates('code95ExpirationDateSort', a, b);
	const sortTableCode95Progress = (a, b) => parseInt(a.original?.code95Hours, 10) - parseInt(b.original?.code95Hours, 10);
	const sortPractical = (a, b) => (a.original?.practical ? 1 : 0) - (b.original?.practical ? 1 : 0);

	const extraColumnHeaders = React.useMemo(() => [
		{
			Header: t('code95.code95'),
			accessor: 'code95Progress',
			sortType: sortTableCode95Progress,
			Filter: Code95Filter,
			filter: code95Filter,
		},
		{
			Header: t('employees.practical'),
			accessor: 'practical',
			Cell: ({ value }) => (value
				? <div className="mt-1"><span className="glyphicons glyphicons-square-empty-check text-success" /></div>
				: <div className="mt-1"><span className="glyphicons glyphicons-square-empty-remove text-danger" /></div>),
			sortType: sortPractical,
			Filter: BooleanFilter,
			filter: booleanFilter,
		},
		{
			Header: t('code95.code95ExpirationDate'),
			accessor: 'code95ExpirationDate',
			sortType: sortTableCode95ExpirationDate,
			Filter: props => <IsBeforeFilter {...props} label={t('code95.code95IsBefore')} />,
			filter: isBeforeFilter,
		},
	], []);

	return (
		<StudentsOverview
			students={students}
			extraColumnHeaders={extraColumnHeaders}
			establishmentData={establishmentData}
		/>
	);
}

export default Code95StudentsOverviewTab;
