/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from '../../moment';
import InfoBar from '../../components/Navigation/InfoBar';
import Code95StudentsOverviewTab, { CODE_95_STUDENTS_TAB } from '../../components/Employees/Tabs/Code95StudentsOverviewTab';
import ArchivedStudentsOverviewTab, { ARCHIVED_STUDENTS_TAB } from '../../components/Employees/Tabs/ArchivedStudentsOverviewTab';
import StudentsChecklistOverviewTab, { STUDENTS_CHECKLIST_TAB } from '../../components/Employees/Tabs/StudentsChecklistOverviewTab';
import { ProvideTabs, useTabsHook } from '../../hooks/use-tabs';
import TabsHeader from '../../components/Tabs/TabsHeader';
import OtherStudentsOverview, { OTHERS_STUDENTS_TAB } from '../../components/Employees/Tabs/NonCode95StudentsOverviewTab';
import { getXLSXFile } from '../../utils/xlsx';
import StudentsActions from '../../components/Employees/StudentsActions';
import useApi from '../../hooks/use-api';
import {
	getCompanyStudents,
	getEstablishmentsForCompany,
	importCompanyStudents,
	importStudentsTemplate,
} from '../../constants/routes';
import StudentModal from '../../components/Modal/StudentModal';
import { formatStudent } from '../../utils/student';
import { getCurrentCode95Cycle, NO_CYCLE_CYCLE_REQUIRED } from '../../utils/code95';
import Code95Points from '../../components/StudentRisk/Code95Points';
import { useDationUser } from '../../hooks/dation-user';
import UploadFileModal from '../../components/Modal/UploadFileModal';
import { getDateFormat } from '../../utils/date';
import StudentsApplicationModal from '../../components/Modal/StudentsApplicationModal';
import { CODE_95_ENABLED, EDUCATION_INFORMATION_ENABLED, useViewPreferences } from '../../hooks/view-preferences';
import * as ROUTES from '../../constants/routes';

const STUDENTS_OVERVIEW_TAB = 'students_overview_tab';

function EmployeeListPageContainer() {
	const { isPreferenceOptionEnabled } = useViewPreferences();

	return (
		<ProvideTabs
			initialState={{
				tabs: [
					...(isPreferenceOptionEnabled(CODE_95_ENABLED) ? [CODE_95_STUDENTS_TAB] : []),
					OTHERS_STUDENTS_TAB,
					...(isPreferenceOptionEnabled(EDUCATION_INFORMATION_ENABLED) ? [STUDENTS_CHECKLIST_TAB] : []),
					ARCHIVED_STUDENTS_TAB,
				],
				tabKey: STUDENTS_OVERVIEW_TAB,
			}}
		>
			<EmployeeListPageChild />
		</ProvideTabs>
	);
}

function EmployeeListPageChild() {
	const { t } = useTranslation();
	const dationUser = useDationUser();
	const { isPreferenceOptionEnabled } = useViewPreferences();
	const navigate = useNavigate();

	const [showAddStudentModal, setShowAddStudentModal] = useState(false);
	const [showAddStudentsApplicationModal, setShowStudentsApplicationModal] = useState(false);
	const [showUploadFileModal, setShowUploadFileModal] = useState(false);
	const [activeCode95students, setActiveCode95Students] = useState([]);
	const [activeNonCode95students, setActiveNonCode95Students] = useState([]);
	const [archivedStudents, setArchivedStudents] = useState([]);
	const { tabs, key, handleKeyChange } = useTabsHook();
	const [shouldDownload, setShouldDownLoad] = useState(false);

	const [, uploadStudents] = useApi.post(importCompanyStudents(dationUser.companyId), {},	{ lazy: true });

	const [{ data: establishmentData }] = useApi.get(getEstablishmentsForCompany(dationUser.getCurrentCompanyId()));

	const [{ data: importFile = null }, fetchImportStudentsTemplate] = useApi.get(importStudentsTemplate(), {},	{ lazy: true });

	// Code 95 Active Students
	const [{ data: studentsData, isLoading: loadingStudents }, fetchCompanyStudents] = useApi.get(
		getCompanyStudents(dationUser.companyId),
	);

	const getCode95Data = student => {
		const currentCycle = getCurrentCode95Cycle(student.code95Cycles);

		if(currentCycle === null) {
			return {
				code95Progress: t('dashboard.no_current_cycle'),
				// Set students that require a new cycle to -1
				// These values are used like this in the Code95Filter
				code95Hours: NO_CYCLE_CYCLE_REQUIRED,
			};
		}

		return {
			practical: currentCycle.practiceHours > 0,
			code95ExpirationDate: moment(currentCycle.endDate).format('L'),
			code95ExpirationDateSort: currentCycle.endDate,
			code95Progress: <Code95Points cycle={currentCycle} />,
			code95Hours: currentCycle.practiceHours + currentCycle.theoryHours,
		};
	};

	useEffect(() => {
		if(studentsData?.length) {
			const tempActiveCode95Students = [];
			const tempActiveNonCode95Students = [];
			const tempArchivedStudents = [];

			studentsData.forEach(student => {
				const formattedStudent = formatStudent(student);

				if(student.archived) {
					tempArchivedStudents.push(formattedStudent);
				} else if(student.code95Student) {
					tempActiveCode95Students.push({
						...formattedStudent,
						...getCode95Data(student),
					});
				} else {
					tempActiveNonCode95Students.push(formattedStudent);
				}
			});

			setActiveCode95Students(tempActiveCode95Students);
			setActiveNonCode95Students(tempActiveNonCode95Students);
			setArchivedStudents(tempArchivedStudents);
		}
	}, [studentsData]);

	const exportAllStudents = () => {
		const allStudents = [
			...activeCode95students,
			...activeNonCode95students,
			...archivedStudents,
		];
		return [
			{
				name: t('navigation.companyUser.employees'),
				rows: allStudents.map(student => {
					const currentCode95Cycle = getCurrentCode95Cycle(student.code95Cycles);
					return ({
						[t('employees.initials')]: student.initials,
						[t('employees.firstName')]: student.firstName,
						[t('employees.insertion')]: student.insertion,
						[t('employees.lastName')]: student.lastName,
						[t('employees.officialFirstName')]: student.officialFirstName,
						[t('employees.dateOfBirth')]: student.dateOfBirth,
						[t('employees.placeOfBirth')]: student.placeOfBirth,
						[t('employees.cbrNumber')]: student.cbrId,
						[t('employees.employeeNumber')]: student.employeeNumber,
						[t('employees.establishment')]: student.establishment?.name,
						[t('employees.phone')]: student.phoneNumber,
						[t('employees.email')]: student.emailAddress,
						[t('employees.drivingLicenseExpirationDate')]: student.drivingLicenseExpiryDate ? getDateFormat(student.drivingLicenseExpiryDate) : null,
						[t('employees.driverPassExpirationDate')]: student.driverPassExpiryDate ? getDateFormat(student.driverPassExpiryDate) : null,
						[t('code95.followCode95')]: t(`external.${student.code95Student ? 'yes' : 'no'}`),
						[t('employees.startDateCycle')]: currentCode95Cycle?.startDate ? getDateFormat(currentCode95Cycle?.startDate) : null,
						[t('employees.endDateCycle')]: currentCode95Cycle?.endDate ? getDateFormat(currentCode95Cycle?.endDate) : null,
						[t('employees.theoryHoursFollowed')]: currentCode95Cycle?.theoryHours,
						[t('employees.practiceHoursFollowed')]: currentCode95Cycle?.practiceHours,
						[t('employees.hoursCompleted')]: currentCode95Cycle ? (parseInt(currentCode95Cycle?.theoryHours, 10) + parseInt(currentCode95Cycle?.practiceHours, 10)) : null,
						[t('employees.practical')]: t(`code95.${student.practical ? 'achieved' : 'not_achieved'}`),
						[t('employees.hasEducation')]: t(`external.${student.education ? 'yes' : 'no'}`),
						[t('import.active_archived')]: t(`employees.tabs.${student.archived ? 'archived' : 'active'}`),
						[t('employees.comments')]: student.comments,
						[t('employees.id')]: student.id,
					});
				}),
			},
		];
	};

	useEffect(() => {
		if(shouldDownload && importFile?.content) {
			const a = document.createElement('a');
			a.href = `data:application/xlsx;base64,${importFile.content}`;
			a.download = `${t('import.filename')} ${moment().format('L')}.xlsx`;
			document.body.appendChild(a);
			a.click();
			setShouldDownLoad(false);
		}
	}, [importFile]);

	const dropdownOptions = useMemo(() => [
		{
			id: 1,
			text: t('employees.exportAll'),
			icon: 'export',
			last: false,
			handleClick: () => getXLSXFile(exportAllStudents(), `${t('navigation.companyUser.employees')} ${dationUser.getCompanyName()} ${moment().format('L')}.xlsx`),
		},
		{
			id: 2,
			text: t('import.generateTemplate'),
			icon: 'cloud-download',
			last: false,
			handleClick: () => {
				setShouldDownLoad(true);
				fetchImportStudentsTemplate();
			},
		},
		{
			id: 3,
			text: t('import.uploadFile'),
			icon: 'cloud-upload',
			last: true,
			handleClick: () => setShowUploadFileModal(true),
		},
	], [exportAllStudents, importStudentsTemplate]);

	return (
		<>
			<InfoBar />
			{loadingStudents ? <div className="loading-spinner" /> : (
				<div className="row pb-5">
					<div className="col-sm-12 col-xl-10 mx-auto">
						<Tab.Container id="planning-tabs" activeKey={key} onSelect={k => handleKeyChange(k)}>
							<div style={{ minHeight: '90px' }} className="d-flex justify-content-between align-items-center">
								<TabsHeader
									options={{ i18nPrefix: 'employees.tabs' }}
									tabKey={key}
									tabs={tabs}
								/>
								<StudentsActions
									onAddStudent={() => setShowAddStudentModal(true)}
									onAddStudentsApplication={dationUser.isCompanyContactPersonUser() ? () => setShowStudentsApplicationModal(true) : null}
									dropdownOptions={dropdownOptions}
								/>
							</div>
							<Tab.Content>
								{isPreferenceOptionEnabled(CODE_95_ENABLED) && (
									<Tab.Pane eventKey={CODE_95_STUDENTS_TAB}>
										<Code95StudentsOverviewTab
											students={activeCode95students}
											establishmentData={establishmentData}
										/>
									</Tab.Pane>
								)}
								<Tab.Pane eventKey={OTHERS_STUDENTS_TAB}>
									<OtherStudentsOverview
										students={activeNonCode95students}
										establishmentData={establishmentData}
									/>
								</Tab.Pane>
								<Tab.Pane eventKey={STUDENTS_CHECKLIST_TAB}>
									<StudentsChecklistOverviewTab visible={key === STUDENTS_CHECKLIST_TAB} />
								</Tab.Pane>
								<Tab.Pane eventKey={ARCHIVED_STUDENTS_TAB}>
									<ArchivedStudentsOverviewTab
										students={archivedStudents}
										establishmentData={establishmentData}
									/>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</div>
				</div>
			)}
			<StudentModal
				show={showAddStudentModal}
				setShow={setShowAddStudentModal}
				handleSubmit={studentId => navigate(ROUTES.employeeDetailPage(studentId))}
				establishmentData={establishmentData}
			/>
			{dationUser.isCompanyContactPersonUser() ? (
				<StudentsApplicationModal
					show={showAddStudentsApplicationModal}
					setShow={setShowStudentsApplicationModal}
					handleSubmit={() => fetchCompanyStudents()}
					establishmentData={establishmentData}
				/>
			) : null}
			<UploadFileModal
				show={showUploadFileModal}
				setShow={setShowUploadFileModal}
				successMessage={t('import.successMessage')}
				handleSubmit={employees => uploadStudents({ fileData: employees }).then(() => fetchCompanyStudents())}
			/>
		</>
	);
}

function EmployeeListPage() {
	return <EmployeeListPageContainer />;
}

export default EmployeeListPage;
