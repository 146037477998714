import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChipInput from '../../ChipInput/ChipInput';
import { useDationUser } from '../../../hooks/dation-user';

function CompanySelect({
	selected,
	setSelected,
}) {
	const { t } = useTranslation();
	const { getCurrentCompany } = useDationUser();

	const [options, setOptions] = useState([]);
	const [companiesAvailableForSharing, setCompaniesAvailableForSharing] = useState(null);

	useEffect(() => {
		setCompaniesAvailableForSharing(getCurrentCompany()?.sharedCompanies);
	}, []);

	useEffect(() => {
		if(companiesAvailableForSharing) {
			setOptions(companiesAvailableForSharing.map(company => ({ label: company.name, value: company.id })));
		}
	}, [companiesAvailableForSharing]);

	return selected.length || options.length ? (
		<dl className="mt-3">
			<dt className="mb-1 text-primary-color">{t('reservations.selectCompaniesToShare')}</dt>
			<ChipInput
				options={options}
				setSelected={setSelected}
				selected={selected}
				title={t('reservations.selectedCompanies')}
			/>
		</dl>
	) : null;
}

export default CompanySelect;
