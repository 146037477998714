import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from '../../moment';
import { compareTableDates } from '../../utils/sort';
import DashboardTable from './DashboardTable';
import { getDateFormat, isExpirationDateRisky } from '../../utils/date';

function DrivingLicense({ students }) {
	const { t } = useTranslation();

	const headers = React.useMemo(() => [
		{
			Header: t('dashboard.name'),
			accessor: 'displayName',
		},
		{
			Header: t('dashboard.expiry_date'),
			accessor: 'drivingLicenseExpiryDate',
			Cell: ({ value }) => getDateFormat(moment(value)),
			sortType: (a, b) => compareTableDates('drivingLicenseExpiryDate', a, b),
		},
	], [t]);

	const filteredStudents = React.useMemo(() => {
		if(students === null) {
			return [];
		}

		return students.filter(({ drivingLicenseExpiryDate }) => isExpirationDateRisky(drivingLicenseExpiryDate));
	}, [students]);

	return (
		<DashboardTable
			cardHeader={<div className="d-flex"><span className="glyphicons glyphicons-id" style={{ fontSize: '2.5rem' }} /><span className="m-2"> {t('dashboard.driving_license_expires')}</span></div>}
			sortBy={[
				{
					id: 'drivingLicenseExpiryDate',
					desc: false,
				},
			]}
			columns={headers}
			data={filteredStudents}
		/>
	);
}

export default DrivingLicense;
