import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import PropTypes from 'prop-types';

function ChipInputList({ options = [], onOptionSelected }) {
	return (
		<ListGroup className="typeahead-selector chip-result-list">
			{options.map(option => <ListGroupItem className="list-item" onMouseDown={() => onOptionSelected(option)} key={option.value}>{option.label}</ListGroupItem>)}
		</ListGroup>
	);
}

ChipInputList.propTypes = {
	options: PropTypes.array,
	onOptionSelected: PropTypes.func.isRequired,
};

export default ChipInputList;
