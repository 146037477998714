import React, { useState } from 'react';
import {
	Modal,
	ModalTitle,
	ModalBody,
	ModalFooter,
	Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useToast } from '../../hooks/use-toast';
import { useApi } from '../../hooks/use-api';
import ErrorMessage from '../common/messages/ErrorMessage';

const InviteUserModal = React.memo(({
	show, setShow, handleSubmit, successMessage, title, endpoint, message, size = 'lg',
}) => {
	const { t } = useTranslation();
	const toast = useToast();

	const [errorMessage, setErrorMessage] = useState(null);
	const [isDisabled, setDisabled] = useState(false);
	const [, sendInvite] = useApi.post(endpoint, {}, { lazy: true });

	const inviteUser = async () => {
		setDisabled(true);
		sendInvite()
			.then(() => {
				setShow(false);
				handleSubmit();
				toast.addToast(successMessage);
				setDisabled(false);
			})
			.catch(error => {
				setErrorMessage(error);
				setDisabled(false);
			});
	};

	return (
		<Modal
			show={show}
			onHide={() => {
				setShow(false);
				setErrorMessage(null);
			}}
			size={size}
		>
			<Modal.Header closeButton={!isDisabled}>
				<ModalTitle>{title}</ModalTitle>
			</Modal.Header>
			<ModalBody>
				<ErrorMessage message={errorMessage} />
				<p>{message}</p>
			</ModalBody>
			<ModalFooter>
				<Button
					variant="outline-secondary"
					disabled={isDisabled}
					onClick={() => {
						setShow(false);
						setErrorMessage(null);
					}}
				>
					{t('add_employee.cancel')}
				</Button>
				<Button variant="primary" disabled={isDisabled} onClick={inviteUser}>{t('users.submit')}</Button>
			</ModalFooter>
		</Modal>
	);
});

InviteUserModal.propTypes = {
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func,
	endpoint: PropTypes.string,
	successMessage: PropTypes.string,
	title: PropTypes.string,
	message: PropTypes.string,
	size: PropTypes.string,
};
export default InviteUserModal;
