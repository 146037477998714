/* eslint-disable import/prefer-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useCallback, useEffect } from 'react';
import { isEqual } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../utils/api';
import useMergeState from '../use-merge-state';
import * as ROUTES from '../../constants/routes';
import { MAINTENANCE_ERROR_CODE } from '../../constants/routes';

const cache = {};

const useDeepCompareMemoize = value => {
	const valueRef = useRef();

	if(!isEqual(value, valueRef.current)) {
		valueRef.current = value;
	}
	return valueRef.current;
};

/**
 * Function used for get requests. Takes a fixed url, variables and options.
 * Used as follows: const [{data, isLoading}, callableFunction] = useApi.get('route');
 * If the option lazy is false, directly calls url. Otherwise it waits until 'callableFunction' is called manually.
 * When parameters change, use 'callableFunction({...newVariables})'
 *
 * Tries caching when url/parameters are the same. This can be prevented by the option {cachePolicy: 'no-cache'}
 *
 * Not usable for dynamically loaded base urls, ie '/company/{id}?params=a', where the id can change in the lifetime of this function
 */
export const useQuery = (url, propsVariables = {}, options = {}) => {
	const {
		lazy = false, cachePolicy = 'cache-first', accept = 'application/json', requestConfig = {},
	} = options;
	const navigate = useNavigate();
	const location = useLocation();
	const wasCalled = useRef(false);
	const propsVariablesMemoized = useDeepCompareMemoize(propsVariables);

	const isSleeping = lazy && !wasCalled.current;
	const isCacheAvailable = cache[url] && isEqual(cache[url].apiVariables, propsVariables);
	const canUseCache = isCacheAvailable && cachePolicy !== 'no-cache' && !wasCalled.current;

	const [state, mergeState] = useMergeState({
		data: canUseCache ? cache[url].data : null,
		error: null,
		isLoading: !lazy && !canUseCache,
		variables: {},
	});

	const makeRequest = useCallback(
		(newVariables, newUrl) => {
			const variables = { ...state.variables, ...(newVariables || {}) };
			const apiVariables = { ...propsVariablesMemoized, ...variables };
			const skipLoading = canUseCache && cachePolicy === 'cache-first';

			if(!skipLoading) {
				mergeState({ isLoading: true, variables });
			} else if(newVariables) {
				mergeState({ variables });
			}
			const updatedUrl = newUrl || url;
			api.get(updatedUrl, apiVariables, requestConfig, accept).then(
				response => {
					const { isAxiosError, data } = response;
					if(isAxiosError) {
						mergeState({ error: response.response.data, data: null, isLoading: false });
					}

					cache[url] = { data, apiVariables };
					mergeState({ data, error: null, isLoading: false });
				},
				error => {
					const { response: { status, data } } = error;
					if(status === MAINTENANCE_ERROR_CODE) {
						if(location.pathname !== ROUTES.MAINTENANCE) {
							navigate(ROUTES.MAINTENANCE, { state: data });
						}
					} else {
						mergeState({ error: { ...error }, data: null, isLoading: false });
					}
				},
			);

			wasCalled.current = true;
		},
		[propsVariablesMemoized],
	);

	useEffect(() => {
		if(isSleeping) {
			return;
		}
		if(canUseCache && cachePolicy === 'cache-only') {
			return;
		}

		makeRequest();
	}, [makeRequest]);

	const setLocalData = useCallback(
		getUpdatedData => mergeState(({ data }) => {
			const updatedData = getUpdatedData(data);
			cache[url] = { ...(cache[url] || {}), data: updatedData };
			return { data: updatedData };
		}),
		[mergeState, url],
	);

	return [
		{
			...state,
			variables: { ...propsVariablesMemoized, ...state.variables },
			setLocalData,
		},
		makeRequest,
	];
};
