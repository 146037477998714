/* eslint-disable import/prefer-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../utils/api';
import useMergeState from '../use-merge-state';
import * as ROUTES from '../../constants/routes';
import { MAINTENANCE_ERROR_CODE } from '../../constants/routes';

const isWorkingAlias = {
	post: 'isCreating',
	put: 'isUpdating',
	patch: 'isUpdating',
	delete: 'isDeleting',
};

export const useMutation = (method, url, contentType = 'application/json') => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	const [state, mergeState] = useMergeState({
		data: null,
		error: null,
		warning: null,
		isWorking: false,
	});

	const parseError = response => {
		const { data, data: { violations, detail } } = response;
		if(Array.isArray(violations)) {
			return violations.map(violation => violation.message);
		}

		if(detail) {
			return detail;
		}

		if(Array.isArray(data)) {
			return data;
		}

		return t('portal.error');
	};

	const makeRequest = useCallback(
		(variables = {}, newUrl = null) => new Promise((resolve, reject) => {
			mergeState({ isWorking: true });

			api[method](newUrl || url, variables, {}, undefined, contentType).then(
				responseObject => {
					const { response, isAxiosError } = responseObject;
					if(isAxiosError) {
						reject(parseError(response));
					} else {
						const { data } = responseObject;
						resolve(data);
						mergeState({
							data, error: null, warning: null, isWorking: false,
						});
					}
				},
			).catch(error => {
				const { response, response: { status, data } } = error;
				if(status === MAINTENANCE_ERROR_CODE) {
					if(location.pathname !== ROUTES.MAINTENANCE) {
						navigate(ROUTES.MAINTENANCE, { state: data });
					}
				} else {
					reject(parseError(response));
					mergeState({ error, data: null, isWorking: false });
				}
			});
		}),
		[method, url, mergeState],
	);

	return [
		{
			...state,
			[isWorkingAlias[method]]: state.isWorking,
		},
		makeRequest,
	];
};
