import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

function DefaultTableFilter({
	id,
	children,
	active = false,
}) {
	return (
		<OverlayTrigger
			trigger="click"
			overlay={(
				<Popover id={id} style={{ minWidth: 200 }}>
					<Popover.Body>
						{children}
					</Popover.Body>
				</Popover>
			)}
			placement="top"
			rootClose
		>
			<span className={`glyphicons glyphicons-filter cursor-pointer ${active ? 'text-danger' : 'text-black-50'}`} />
		</OverlayTrigger>
	);
}

export default DefaultTableFilter;
