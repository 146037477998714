export default function useUserPreferences() {
	const defaultPageIndex = 0;
	const defaultPageSize = 50;

	const setPageIndex = (name, pageIndex) => {
		sessionStorage.setItem(`PAGE_INDEX_${name}`, pageIndex);
	};

	const getPageIndex = name => {
		const index = sessionStorage.getItem(`PAGE_INDEX_${name}`) || defaultPageIndex;
		return parseInt(index, 10);
	};

	const setPageSize = (name, pageSize) => {
		localStorage.setItem(`PAGE_SIZE_${name}`, pageSize);
	};

	const getPageSize = name => {
		const size = localStorage.getItem(`PAGE_SIZE_${name}`) || defaultPageSize;
		return parseInt(size, 10);
	};

	const setTabKey = (name, key) => sessionStorage.setItem(`TAB_KEY_${name}`, key);

	const getTabKey = name => sessionStorage.getItem(`TAB_KEY_${name}`);

	const setFilters = (name, filters) => sessionStorage.setItem(`FILTERS_${name}`, JSON.stringify(filters));
	const getFilters = name => JSON.parse(sessionStorage.getItem(`FILTERS_${name}`) || null);

	return {
		setPageIndex,
		setPageSize,
		setTabKey,
		getPageIndex,
		getPageSize,
		getTabKey,
		getFilters,
		setFilters,
	};
}
