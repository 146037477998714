import React, { useEffect, useState } from 'react';
import {
	Button, Modal, ModalBody, ModalFooter, ModalTitle,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getWeekdayDateTimeFormat } from '../../../utils/date';
import Slots from '../PlannedTrainingModal/Slots';
import DateInput from '../../Form/DateInput';
import moment from '../../../moment';
import useApi from '../../../hooks/use-api';
import * as ROUTES from '../../../constants/routes';
import { useToast } from '../../../hooks/use-toast';
import { CERTIFICATES_ENABLED, CODE_95_ENABLED, useViewPreferences } from '../../../hooks/view-preferences';
import ErrorMessage from '../../common/messages/ErrorMessage';
import { parseErrorMessage } from '../../../utils/parseErrors';

export default function ({
	training,
	enrollment,
	student,
	excludeCertificate,
	getExcludeCertificateCheckbox,
	certificateExpirationDateManuallyChanged,
	customCertificateExpirationDate,
	setCustomCertificateExpirationDate,
	setCertificateExpirationDateManuallyChanged,
	cancel,
	handleSubmit,
}) {
	const { t } = useTranslation();
	const toast = useToast();
	const { isPreferenceOptionEnabled } = useViewPreferences();

	const [enrolledSlots, setEnrolledSlots] = useState([]);
	const [isDisabled, setDisabled] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const [, updateEnrollment] = useApi.patch(ROUTES.updateEnrollment(enrollment.id));

	useEffect(() => {
		if(training.slots && student) {
			const { slots } = training;
			const filteredSlots = slots.filter(slot => slot.students.find(stud => stud.id === student.id));
			setEnrolledSlots(filteredSlots);
		}
	}, [training, student]);

	const handleUpdateCertificateExclusionAndExpirationDate = () => {
		setDisabled(true);

		updateEnrollment({
			excludeFromRiskCalculation: excludeCertificate,
			certificateDateManuallyUpdated: certificateExpirationDateManuallyChanged,
			certificateExpirationDate: moment.isMoment(customCertificateExpirationDate) ? customCertificateExpirationDate.format() : null,
		}).then(() => {
			setDisabled(false);
			setErrorMessage(null);
			cancel();
			handleSubmit();
			toast.addToast(t(training.external ? 'external.edit_submit' : 'training.edit_submit'));
		}).catch(e => {
			setErrorMessage(parseErrorMessage(e));
			setDisabled(false);
		});
	};

	const handleUpdateCustomCertificateDate = date => {
		setCustomCertificateExpirationDate(date);
		setCertificateExpirationDateManuallyChanged(true);
	};

	return (
		<>
			<Modal.Header closeButton>
				<ModalTitle>{t('trainings.course_details')}</ModalTitle>
			</Modal.Header>
			<ModalBody>
				<ErrorMessage message={errorMessage} />
				<dl className="row">
					<dt className="col-5 text-primary-color">{t('trainings.course')}</dt>
					<dd className="col-7" style={{ minHeight: 24 }}>{training.name}</dd>

					{isPreferenceOptionEnabled(CODE_95_ENABLED) && (
						<>
							<dt className="col-5 text-primary-color">{t('trainings.ccvCode')}</dt>
							<dd className="col-7" style={{ minHeight: 24 }}>{training.ccvCode}</dd>
						</>
					)}

					<dt className="col-5 text-primary-color">{t('trainings.location')}</dt>
					<dd className="col-7" style={{ minHeight: 24 }}>{training.city}</dd>

					<dt className="col-5 text-primary-color">{t('trainings.date')}</dt>
					<dd className="col-7" style={{ minHeight: 24 }}>{getWeekdayDateTimeFormat(training.start)}</dd>

					{enrolledSlots.length > 0 && <Slots titleClassnames="col-5" descriptionClassnames="col-7" slots={enrolledSlots} />}

					{isPreferenceOptionEnabled(CERTIFICATES_ENABLED) && (
						<>
							<dt className="col-5 text-primary-color">{t('trainings.certificate')}</dt>
							<dd className="col-7" style={{ minHeight: 24 }}>
								{enrollment.certificateExpirationDate
									? t('external.yes')
									: t('external.no')}
							</dd>

							<dt className="col-5 text-primary-color">{t('trainings.certificate_expiry_date')}</dt>
							<dd className="col-7" style={{ minHeight: 24 }}>
								{/* eslint-disable-next-line */}
								{!student.archived && training.composite ? (
									<DateInput onChange={newDateMoment => handleUpdateCustomCertificateDate(newDateMoment)} value={customCertificateExpirationDate} />
								) :	(enrollment.certificateExpirationDate
									? moment(enrollment.certificateExpirationDate).format('L')
									: '-')}
							</dd>
						</>
					)}

					{isPreferenceOptionEnabled(CODE_95_ENABLED) && (
						<>
							<dt className="col-5 text-primary-color">{t('trainings.type')}</dt>
							<dd className="col-7" style={{ minHeight: 24 }}>{training.theory ? t('external.theory') : t('external.practical')}</dd>

							<dt className="col-5 text-primary-color">{t('trainings.code95Hours')}</dt>
							<dd className="col-7" style={{ minHeight: 24 }}>{enrollment.code95Hours}</dd>
						</>
					)}
				</dl>
			</ModalBody>
			<ModalFooter>
				{(!student.archived && isPreferenceOptionEnabled(CERTIFICATES_ENABLED)) && getExcludeCertificateCheckbox()}
				<Button
					variant="outline-secondary"
					disabled={isDisabled}
					onClick={() => {
						setErrorMessage(null);
						cancel();
					}}
				>
					{t('trainings.close')}
				</Button>
				<Button
					variant="primary"
					disabled={isDisabled}
					type="button"
					onClick={handleUpdateCertificateExclusionAndExpirationDate}
				>
					{t('modalActions.edit')}
				</Button>
			</ModalFooter>
		</>
	);
}
