import React from 'react';
import { useToast } from '../../hooks/use-toast';
import CustomToast from '../../hooks/use-toast/CustomToast';

function ToastHandler() {
	const toast = useToast();
	return (
		<div className="toast-container">
			{toast.toasts.map(({ message, variant }) => <CustomToast key={message} variant={variant} message={message} />)}
		</div>
	);
}

export default ToastHandler;
