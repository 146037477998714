import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DefaultTableFilter from './DefaultTableFilter';

function TextFilter({
	column: {
		id, filterValue, setFilter,
	},
}) {
	const { t } = useTranslation();
	return (
		<DefaultTableFilter id={id} active={filterValue}>
			<div className="form-group">
				<Form.Control
					type="text"
					size="sm"
					placeholder={`${t('table.search')}...`}
					value={filterValue || ''}
					onChange={e => setFilter(e.target.value || undefined)}
				/>
			</div>
		</DefaultTableFilter>
	);
}

export const textFilter = (rows, columnIds, filterValue) => rows.filter(({ original }) => (original[columnIds[0]] || '').toLowerCase().includes((filterValue || '').toLowerCase()));

export default TextFilter;
