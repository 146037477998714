import React from 'react';
import PropTypes from 'prop-types';
import StudentRisk from './StudentRisk';
import moment from '../../moment';
import { calculateCode95CyclePoints, MAXIMUM_COMBINED_HOURS_PER_CYCLE } from '../../utils/code95';

function Code95Points({ cycle }) {
	const points = calculateCode95CyclePoints(cycle);

	return (
		<div className="row">
			<div className="col-2">{`${points}/${MAXIMUM_COMBINED_HOURS_PER_CYCLE}`}</div>
			<div className="col-10 mt-1">
				<StudentRisk amount={points} endDate={moment(cycle.endDate)} />
			</div>
		</div>
	);
}

Code95Points.propTypes = {
	cycle: PropTypes.shape({
		practiceHours: PropTypes.number.isRequired,
		theoryHours: PropTypes.number.isRequired,
		endDate: PropTypes.string.isRequired,
	}).isRequired,
};

export default Code95Points;
