import React from 'react';
import DefaultTableFilter from './DefaultTableFilter';

export const OPTION_ALL = 'all';
function SelectFilter({
	id, filterValue, setFilter, options,
}) {
	return (
		<DefaultTableFilter id={id} active={filterValue && filterValue !== OPTION_ALL}>
			<div className="form-group">
				<select
					className="form-select"
					value={filterValue || OPTION_ALL}
					onChange={e => {
						setFilter(e.target.value);
					}}
				>
					{options.map(({ value, key, name }) => <option value={value} key={key}>{name}</option>)}
				</select>
			</div>
		</DefaultTableFilter>
	);
}

export default SelectFilter;
