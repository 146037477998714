import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InfoBar from '../../components/Navigation/InfoBar';
import useApi from '../../hooks/use-api';
import DationInstanceTable from '../../components/Tables/DationInstanceTable';
import AddDationInstanceModal from '../../components/Modal/AddDationInstanceModal';
import { dationInstanceEndpoint } from '../../constants/routes';

function DationInstancesListPage() {
	const { t } = useTranslation();

	const [show, setShow] = useState(false);
	const [body, setBody] = useState([]);

	// Getting extended fields for dationInstanceEndpoint()
	const [{ data }, fetchDationInstances] = useApi.get(`${dationInstanceEndpoint()}`, { expand: ['portal_admin'] });

	const headers = React.useMemo(() => [
		{
			Header: t('dation_instance.name'),
			accessor: 'name',
		},
	], [t]);

	useEffect(() => {
		if(data) {
			const newBody = data.map(entry => ({
				id: entry.id,
				name: entry.name,
			}));

			setBody(newBody);
		}
	}, [data]);

	return (
		<>
			<InfoBar />
			<div className="d-flex flex-row-reverse">
				<Button
					id="add-dation-instance"
					variant="outline-secondary"
					className="rounded-button p-1"
					type="button"
					onClick={() => setShow(true)}
				>
					<span className="glyphicons glyphicons-plus" style={{ position: 'relative', top: 3 }} />
				</Button>
			</div>
			<DationInstanceTable columns={headers} data={body} />
			<AddDationInstanceModal setShow={setShow} show={show} handleSubmit={fetchDationInstances} />
		</>
	);
}

export default DationInstancesListPage;
