/* eslint-disable no-unused-vars, max-len */
import React, { useEffect, useState } from 'react';
import {
	Button, Modal, ModalBody, ModalFooter, ModalTitle,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDationUser } from '../../hooks/dation-user';
import { dationInstanceEndpoint, getCompanyStudents, sendStudentsApplicationEndpoint } from '../../constants/routes';
import { CATEGORY_OPTIONS, getApplicationOptions } from '../../constants/employees';
import useApi from '../../hooks/use-api';
import { useToast } from '../../hooks/use-toast';
import { sendGoogleAnalyticsEvent } from '../../utils/googleAnalytics';
import ErrorMessage from '../common/messages/ErrorMessage';
import ChipInput from '../ChipInput/ChipInput';
import { getDisplayName } from '../../utils/person';
import EstablishmentSelect from '../common/EstablishmentSelect';
import MultiSelect, { formatForOptions } from '../common/MultiSelect';
import Tooltip, { infoIcon } from '../common/Tooltip';
import InfoMessage from '../common/messages/InfoMessage';
import { CODE_95_ENABLED, useViewPreferences } from '../../hooks/view-preferences';

function StudentsApplicationModal({
	show, setShow, handleSubmit,
}) {
	const { t } = useTranslation();
	const { getCurrentCompany, getCurrentCompanyId } = useDationUser();
	const toast = useToast();
	const { isPreferenceOptionEnabled } = useViewPreferences();

	const [isValidated, setValidated] = useState(false);
	const [errorMessages, setErrorMessages] = useState([]);
	const [isDisabled, setDisabled] = useState(false);
	const [establishmentId, setEstablishmentId] = useState(0);

	const [{ data: students }, fetchStudents] = useApi.get(getCompanyStudents(getCurrentCompanyId()), { archived: false }, { lazy: true });
	const [{ data: dationInstanceData }] = useApi.get(`${dationInstanceEndpoint()}`, {}, {});
	const [, sendStudentsApplication] = useApi.post(sendStudentsApplicationEndpoint());

	const [dationInstances, setDationInstances] = useState([]);
	const [studentOptions, setStudentOptions] = useState([]);

	const [selectedDationInstance, setSelectedDationInstance] = useState(0);
	const [selectedStudents, setSelectedStudents] = useState([]);
	const [selectedApplicationNames, setSelectedApplicationNames] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [message, setMessage] = useState(null);
	const code95Enabled = isPreferenceOptionEnabled(CODE_95_ENABLED);

	useEffect(() => {
		if(show) {
			fetchStudents();
		}
	}, [show]);

	useEffect(() => {
		if(dationInstanceData?.length) {
			const primaryDationInstance = getCurrentCompany()?.primaryDationInstance;
			const dationInstancesList = getCurrentCompany()?.private
				? [dationInstanceData.find(dationInstance => dationInstance.id === primaryDationInstance?.id)]
				: dationInstanceData;
			setDationInstances(dationInstancesList);
			setSelectedDationInstance(primaryDationInstance.id);
		}
	}, [dationInstanceData]);

	useEffect(() => {
		if(students) {
			const filteredStudents = establishmentId
				? students.filter(({ establishment }) => establishment?.id === establishmentId)
				: students;
			setStudentOptions(filteredStudents.map(student => ({
				value: student.id,
				label: getDisplayName(student),
			})));
		}
	}, [students, establishmentId]);

	const clearData = () => {
		setSelectedStudents([]);
		setSelectedApplicationNames([]);
		setSelectedCategories([]);
		setErrorMessages([]);
		setMessage('');
		setValidated(false);
		setDisabled(false);
	};

	const cancel = () => {
		clearData();
		setShow(false);
	};

	const submit = e => {
		e.preventDefault();
		e.stopPropagation();
		setValidated(false);
		setDisabled(true);

		if(!selectedDationInstance || !selectedApplicationNames.length) {
			setValidated(true);
			setDisabled(false);
			return;
		}
		sendStudentsApplication({
			dationInstance: { id: selectedDationInstance },
			applicationNames: selectedApplicationNames.map(({ label }) => label),
			categories: selectedCategories.map(({ label }) => label),
			students: selectedStudents.map(({ value }) => ({ id: value })),
			message,
			company: { id: getCurrentCompanyId() },
		})
			.then(() => {
				sendGoogleAnalyticsEvent({
					hitType: 'event',
					eventCategory: 'Employee',
					eventAction: 'Driving School Application',
					eventLabel: `${selectedStudents.length} Driving school applications for employees`,
				});
				setShow(false);
				setDisabled(false);
				handleSubmit();
				clearData();
				toast.addToast(t('employees.employeesApplicationSent'));
			})
			.catch(err => {
				setErrorMessages(err);
				setMessage('');
				setValidated(false);
				setDisabled(false);
			});
	};

	const selectHelper = helperKey => (
		<>
			<strong>{t('tooltip.attention')}</strong>
			&nbsp;
			{t(`employees.${helperKey}Helper`)}
		</>
	);

	const categoriesHelperContent = () => selectHelper('categories');
	const applicationNamesHelperContent = () => selectHelper('applicationNames');

	const disabledActionsOnError = errorMessages.length > 0;

	const requiredField = isValidated ? (
		<div className="text-primary">
			<small>{t('modalFields.required')}</small>
		</div>
	) : null;

	return (
		<Modal show={show} onHide={() => cancel()} size="lg">
			<Modal.Header closeButton>
				<ModalTitle>{t('employees.employeesApplication')}</ModalTitle>
			</Modal.Header>
			<ModalBody>
				{errorMessages.length > 0 && errorMessages.map(errorMessage => (
					<ErrorMessage message={errorMessage} />
				))}
				<div className="form-group row">
					<div className="col-12">
						<dt className="mb-1 text-primary-color">{t('educations.educator')}</dt>
						<select
							className="form-select"
							value={selectedDationInstance}
							onChange={e => setSelectedDationInstance(parseInt(e.target.value, 10))}
						>
							{dationInstances?.length > 0 ? dationInstances.map(dationInstance => (
								<option key={dationInstance?.id} value={dationInstance?.id}>{dationInstance?.name}</option>
							)) : null}
						</select>
						{!selectedDationInstance ? requiredField : null}
					</div>
					<div className="col-12 mt-3">
						<dt className="mb-1 text-primary-color">{t('modalFields.applicationNames')}
							<Tooltip renderPopoverContent={applicationNamesHelperContent} hideDelay={100}>
								{infoIcon()}
							</Tooltip>
						</dt>
						<MultiSelect
							value={selectedApplicationNames}
							options={formatForOptions(getApplicationOptions(code95Enabled))}
							onChange={value => setSelectedApplicationNames(value)}
							maxItemsShown={3}
						/>
						{!selectedApplicationNames.length ? requiredField : null}
					</div>
					<div className="col-12 mt-3">
						<dt className="mb-1 text-primary-color">{t('modalFields.categories')}
							<Tooltip renderPopoverContent={categoriesHelperContent} hideDelay={100}>
								{infoIcon()}
							</Tooltip>
						</dt>
						<MultiSelect
							value={selectedCategories}
							options={formatForOptions(CATEGORY_OPTIONS)}
							onChange={value => setSelectedCategories(value)}
							maxItemsShown={10}
						/>
					</div>
					<div className="col-12">
						<EstablishmentSelect
							establishmentId={establishmentId}
							setEstablishmentId={setEstablishmentId}
						/>
					</div>
					<div className="col-12">
						<dt className="mb-1 text-primary-color">{t('trainings.select_employees', { optional: null })}</dt>
						<ChipInput
							options={studentOptions}
							setSelected={selectedOptions => setSelectedStudents(selectedOptions)}
							selected={selectedStudents}
							title={t('trainings.selected_employees')}
							disabled={disabledActionsOnError || isDisabled}
							disabledChipIcon={disabledActionsOnError || isDisabled}
						/>
					</div>
					<div className="col-12 mt-3">
						<dt className="mb-1 text-primary-color">{t('modalFields.message')}</dt>
						<textarea
							id="message"
							rows="3"
							className="form-control"
							value={message ?? ''}
							onChange={({ target }) => setMessage(target.value)}
							disabled={disabledActionsOnError || isDisabled}
						/>
					</div>
					<div className="col-12 mt-3">
						<InfoMessage message={t('employees.employeesApplicationHelper')} />
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				{errorMessages.length > 0 ? (
					<Button variant="outline-secondary" onClick={() => cancel()}>{t('modalActions.close')}</Button>
				) : (
					<>
						<Button
							variant="outline-secondary"
							disabled={isDisabled}
							onClick={() => cancel()}
						>{t('modalActions.cancel')}
						</Button>
						<Button
							variant="primary"
							disabled={isDisabled}
							onClick={submit}
						>{t('employees.apply')}
						</Button>
					</>
				)}
			</ModalFooter>
		</Modal>
	);
}

export default StudentsApplicationModal;
