import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MainPage from './pages/MainPage';
import { ProvideFirebase } from './hooks/firebase';
import { ProvideDationUser } from './hooks/dation-user';
import { ProvideToast } from './hooks/use-toast';
import './i18n';
import './assets/fonts/OpenSans-Regular.ttf';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { ProvideFreshchat } from './hooks/freshchat';

function App() {
	return (
		<ProvideFirebase>
			<Router>
				<ProvideDationUser>
					<ErrorBoundary>
						<ProvideToast>
							<ProvideFreshchat>
								<MainPage />
							</ProvideFreshchat>
						</ProvideToast>
					</ErrorBoundary>
				</ProvideDationUser>
			</Router>
		</ProvideFirebase>
	);
}

export default App;
