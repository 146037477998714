import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoBar from '../components/Navigation/InfoBar';
import MenuItem from '../components/common/MenuItem';
import * as ROUTES from '../constants/routes';
import * as ROLES from '../constants/roles';
import { useDationUser } from '../hooks/dation-user';

function SettingsPage() {
	const dationUser = useDationUser();
	const { t } = useTranslation();

	const menuVariablesMapping = {
		[ROLES.COMPANY_SUPER_USER]: {
			establishment_option: { name: t('establishments.establishments'), link: ROUTES.ESTABLISHMENTS },
		},
	};
	const { roles } = dationUser.user || [];

	const menuVariables = roles ? menuVariablesMapping[roles[0]] : {};

	const userOption = { name: t('navigation.dationUser.users'), link: ROUTES.USERS };
	const logbookOption = { name: t('logbook.logbook'), link: ROUTES.LOGBOOK };

	const {
		establishment_option: establishmentOption,
	} = menuVariables || {};
	return (
		<>
			<InfoBar showBackArrow={false} />
			<div className="row">
				<div className="col-12 px-3">
					<div className="py-3">
						<h3 className="mb-3">{t('navigation.settings.settings')}</h3>
					</div>
					<div className="row super-buttons">
						{!dationUser.companyToViewName && <MenuItem icon="user-group" name={userOption.name} link={userOption.link} />}
						{dationUser.getCurrentCompanyId() && <MenuItem icon="history" name={logbookOption.name} link={logbookOption.link} />}
						{establishmentOption && <MenuItem icon="map-marker" name={establishmentOption.name} link={establishmentOption.link} />}
					</div>
				</div>
			</div>
		</>
	);
}

export default SettingsPage;
