/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';
import DateTime from 'react-datetime';
import './DateInput.scss';
import { defaultLanguage } from '../../i18n';
import moment from '../../moment';

function DateInput({
	placeholder, prependLabel, value, minDate, onChange, disabled = false, required = false,
}) {
	const dateInputRef = useRef(null);

	const openDatePicker = () => {
		dateInputRef.current.setState({ open: true });
	};
	const renderInput = props => {
		function clear() {
			props.onChange({ target: { value: '' } });
		}
		return (
			<InputGroup>
				{prependLabel ? (
					<InputGroup.Text><span onClick={() => !disabled && openDatePicker()}>{prependLabel}</span></InputGroup.Text>
				) : null}
				<input {...props} />
				{moment.isMoment(value) ? (
					<InputGroup.Text>
						<span
							className="glyphicons glyphicons-times"
							onClick={clear}
						/>
					</InputGroup.Text>
				) : null}
				<InputGroup.Text><span className="glyphicons glyphicons-calendar" onClick={() => !disabled && openDatePicker()} /></InputGroup.Text>
			</InputGroup>
		);
	};

	const isValid = current => !(minDate && !current.isAfter(minDate));

	return (
		<DateTime
			ref={dateInputRef}
			dateFormat="L"
			timeFormat={false}
			closeOnSelect
			locale={defaultLanguage}
			renderInput={renderInput}
			inputProps={{
				placeholder,
				pattern: '(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}',
				title: 'DD-MM-YYYY',
				disabled,
				required,
			}}
			className="react-datepicker-component w-100"
			value={value}
			onChange={onChange}
			isValidDate={isValid}
		/>
	);
}

DateInput.propTypes = {
	placeholder: PropTypes.string,
	prependLabel: PropTypes.string,
	value: PropTypes.instanceOf(moment),
	minDate: PropTypes.instanceOf(moment),
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
};
export default DateInput;
