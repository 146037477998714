import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormCol from './FormCol';

function Address({
	defaultAddress = {}, onChange, header, children,
}) {
	const { t } = useTranslation();

	const {
		streetName, postalCode, houseNumber, addition, city, country,
	} = defaultAddress;

	return (
		<div className="mb-4">
			{header && (
				<div className="d-flex justify-content-between align-items-start">
					<h5>{header}</h5>
					{children}
				</div>
			)}
			<Row className="mb-3">
				<FormCol label={t('address.street')} name="streetName" value={streetName} onChange={onChange} />
			</Row>
			<Row className="mb-3">
				<FormCol md={4} label={t('address.postalCode')} name="postalCode" value={postalCode} onChange={onChange} />
				<FormCol md={4} label={t('address.houseNumber')} name="houseNumber" value={houseNumber} onChange={onChange} />
				<FormCol md={4} label={t('address.addition')} name="addition" value={addition} onChange={onChange} />
			</Row>
			<Row>
				<FormCol md={4} label={t('address.place')} name="city" value={city} onChange={onChange} />
				<FormCol md={4} label={t('address.country')} name="country" value={country} onChange={onChange} />
			</Row>
		</div>
	);
}

Address.propTypes = {
	defaultAddress: PropTypes.shape({
		streetName: PropTypes.string,
		postalCode: PropTypes.string,
		houseNumber: PropTypes.string,
		country: PropTypes.string,
		city: PropTypes.string,
		addition: PropTypes.string,
	}),
	children: PropTypes.node,
	header: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};

export default Address;
