import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

function CheckboxInput({
	id,
	label,
	name,
	type = 'radio',
	value,
	checked,
	onChange,
	required = false,
	disabled = false,
}) {
	return (
		<Form.Check
			inline
			type={type}
			id={id}
			name={name}
			value={value}
			checked={checked}
			onChange={onChange}
			required={required}
			disabled={disabled}
			label={label}
		/>
	);
}

CheckboxInput.propTypes = {
	id: PropTypes.string,
	type: PropTypes.oneOf(['radio', 'checkbox']),
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.any,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default CheckboxInput;
