import moment from 'moment';
import {
	getWeekdayFormat, getDateFormat, getTimeDateFormat, getWeekdayDateTimeFormat,
} from './date';
import { RISK_AVERAGE, RISK_HIGH, RISK_LOW } from './code95';
import { DISENROLL_DAYS_THRESHOLD } from '../constants/planning';
import { compareDates } from './sort';

export const getFullSlotDate = (start, end) => `${getWeekdayFormat(start)} ${getDateFormat(start)} ${getTimeDateFormat(start)} - ${getTimeDateFormat(end)}`;

export const getSlotsWithFullDateTime = slots => slots.map(slot => ({ ...slot, date: getFullSlotDate(slot.start, slot.end) }));

export const isCode95CcvCode = ccvCode => ccvCode[0] === 'U' || ccvCode[0] === 'W';

export const transformTrainingDataForCompany = trainings => trainings.map(training => ({
	...training,
	sortDate: moment(training.start).format(),
	startDate: getWeekdayDateTimeFormat(training.start),
	slots: training.slots ? getSlotsWithFullDateTime(training.slots) : null,
}));

export const transformSlotForSubRow = slot => ({
	expander: null,
	date: slot.date,
	ccvCode: null,
	name: slot.courseName,
	city: slot.location,
	freeCapacity: null,
});

export const transformSlotForFutureSubRow = slot => {
	const transformedSlot = transformSlotForSubRow(slot);

	return {
		...transformedSlot,
		enrollButton: null,
	};
};

export const transformSlotForPastSubRow = slot => {
	const transformedSlot = transformSlotForSubRow(slot);
	delete transformedSlot.freeCapacity;

	return transformedSlot;
};

export const getRisk = expirationDate => {
	const amountOfDays = expirationDate.diff(moment(), 'days');
	if(amountOfDays <= 90) {
		return RISK_HIGH;
	}
	if(amountOfDays <= 180) {
		return RISK_AVERAGE;
	}
	return RISK_LOW;
};

export const isPlanningRestricted = training => {
	const allowDisenrollment = compareDates(moment().startOf('day'), moment(training.start).startOf('day').subtract(DISENROLL_DAYS_THRESHOLD, 'days'));
	return allowDisenrollment > 0;
};

export const getCCVCodePrefix = (ccvCode, delimiter = '-') => ccvCode.split(delimiter)[0];

export const getPlanCapacity = (training, trainingReservation) => (
	trainingReservation?.amountReserved
		? parseInt(training?.freeCapacity, 10) + trainingReservation.amountReserved - trainingReservation.amountPlanned
		: parseInt(training?.freeCapacity, 10)
);

export const getCalculatedFreeCapacity = (freeCapacity, trainingReservation) => (trainingReservation?.id
	? freeCapacity + (parseInt(trainingReservation?.amountReserved, 10) - parseInt(trainingReservation?.amountPlanned, 10))
	: freeCapacity);

export const findExistingReservation = (trainingReservations, currentCompanyId) => trainingReservations
	.find(reservation => reservation.trainingReservationCompanies.find(trc => trc.company.id === currentCompanyId)) || {};

export const getCalculatedReservationAmounts = (reservation, currentCompanyId) => {
	const trainingReservationCompany = reservation?.trainingReservationCompanies
		?.find(({ company }) => company.id === currentCompanyId) || null;
	return {
		amountPlanned: Math.min(reservation.amountReserved, trainingReservationCompany?.amountPlanned),
		amountReserved: (
			reservation.amountReserved
			- reservation.amountPlanned
			+ Math.min(reservation.amountReserved, trainingReservationCompany?.amountPlanned)
		),
	};
};
