import React from 'react';
import { Col, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SmallFormLabel from './SmallFormLabel';

function FormCol({
	xs, md, label, type, id = null, name, value, onChange, feedback, required, pattern = null, maxLength = null, minLength = null,
}) {
	return (
		<Col xs={xs} md={md}>
			<SmallFormLabel label={label} />
			<FormControl
				type={type}
				id={id}
				name={name}
				value={value || undefined}
				onChange={onChange}
				required={required}
				pattern={pattern}
				maxLength={maxLength}
				minLength={minLength}
			/>

			{feedback && (
				<FormControl.Feedback type="invalid">
					{feedback}
				</FormControl.Feedback>
			)}
		</Col>
	);
}

FormCol.propTypes = {
	xs: PropTypes.number,
	md: PropTypes.number,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	id: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.string,
	feedback: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	pattern: PropTypes.string,
	maxLength: PropTypes.string,
	minLength: PropTypes.string,
};

FormCol.defaultProps = {
	xs: 12,
	md: 6,
	type: 'text',
	value: undefined,
	required: false,
};

export default FormCol;
