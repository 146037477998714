import React, {
	createContext, useCallback, useContext, useEffect, useMemo,
} from 'react';
import { useDationUser } from '../dation-user';
import useApi from '../use-api';
import * as ROUTES from '../../constants/routes';

const freshchatContext = createContext();
const freshchatHost = 'https://wchat.eu.freshchat.com';

function useProvideFreshchat() {
	const { isDationInstanceUser, user } = useDationUser();

	const [, postRestoreId] = useApi.post(ROUTES.postFreshchatRestoreId());

	const freshchatInitialData = useMemo(() => {
		const { id, freshchatRestoreId } = user || {};
		return {
			host: freshchatHost,
			token: process.env.REACT_APP_FRESHCHAT_TOKEN,
			restoreId: freshchatRestoreId,
			externalId: `CertificatePortal.${id}`,
			siteId: process.env.REACT_APP_FRESHCHAT_SITE_ID,
			locale: 'nl',
			config: {
				headerProperty: {
					backgroundColor: '#5821A2',
					foregroundColor: '#FFFFFF',
					fontName: 'Proxima Nova',
					hideChatButton: true,
				},
			},
		};
	}, [user]);

	const freshchatDefaultUserData = useMemo(() => {
		const {
			emailAddress, firstName, lastName, insertion,
		} = user || {};
		return {
			firstName,
			lastName: `${insertion ? `${insertion} ` : ''}${lastName}`,
			email: emailAddress,
		};
	}, [user]);

	const updateFreshchatUser = () => {
		window.fcWidget.user.get(response => {
			const status = response && response.status;
			if(status === 200) {
				// User exists in freshchat - update it with any changes from our side
				window.fcWidget.user.update(freshchatDefaultUserData);
			} else {
				// User does not exist. Set the properties to be propagated when the first chat is initiated and the user is created.
				window.fcWidget.user.setProperties(freshchatDefaultUserData);
			}
		});
	};

	const initialize = useCallback(() => {
		if(!window.fcWidget.isInitialized()) {
			window.fcWidget.init(freshchatInitialData);

			updateFreshchatUser();

			window.fcWidget.on('user:created', response => {
				if(response?.data?.restoreId) {
					postRestoreId({ restoreId: response.data.restoreId }).then();
				}
			});
		}
	}, [freshchatInitialData, freshchatDefaultUserData]);

	useEffect(() => {
		if(isDationInstanceUser && user?.id) {
			initialize();
		}
	}, [isDationInstanceUser, user]);

	const toggleChat = () => {
		if(window.fcWidget.isOpen()) {
			window.fcWidget.close();
			window.fcWidget.hide();
		} else {
			window.fcWidget.show();
			window.fcWidget.open();
		}
	};

	const destroyFreshchat = () => window.fcWidget.destroy();

	return {
		toggleChat,
		destroyFreshchat,
	};
}

export function ProvideFreshchat({ children }) {
	const freshchat = useProvideFreshchat();
	return <freshchatContext.Provider value={freshchat}>{children}</freshchatContext.Provider>;
}

export const useFreshchat = () => useContext(freshchatContext);
