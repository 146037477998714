/* eslint-disable import/prefer-default-export */
export const parseErrorMessage = errors => {
	let message;
	if(Array.isArray(errors)) {
		message = errors.join(' ');
	} else {
		message = errors;
	}
	return message;
};
