import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function MaintenancePage() {
	const location = useLocation();
	const { t } = useTranslation();
	const { maintenanceEndTime } = location?.state || {};

	return (
		<div>
			<h3>{t('maintenance.maintenance')}</h3>
			<p>
				{t('maintenance.maintenance-mode-message')}
				{maintenanceEndTime && t('maintenance.maintenance-mode-time-message', { endTime: maintenanceEndTime })}
			</p>
		</div>
	);
}

export default MaintenancePage;
