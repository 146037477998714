import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import InfoBar from '../components/Navigation/InfoBar';
import EditContactPersonUser from '../components/Modal/EditContactPersonUser';
import EditDationInstanceUser from '../components/Modal/EditDationInstanceUser';
import { getDisplayName } from '../utils/person';

function EditUserPage() {
	const location = useLocation();
	const {
		state: {
			userType, original, company, dationInstance,
		},
	} = location;

	const pageContent = useMemo(() => {
		if(userType === 'contact_person') {
			return (
				<EditContactPersonUser
					companyContactPerson={original}
					companyId={company?.id}
				/>
			);
		}
		return (
			<EditDationInstanceUser
				dationInstanceUser={original}
				dationInstanceId={dationInstance?.id}
				show
			/>
		);
	}, [userType, original, dationInstance, company]);

	const typeName = userType === 'contact_person' ? 'Contactpersoon' : 'Medewerker';
	return (
		<>
			<InfoBar
				backTitle={original?.id ? getDisplayName(original) : `${typeName} toevoegen`}
			/>
			{pageContent}
		</>
	);
}

export default EditUserPage;
