import React, { createContext, useState, useContext } from 'react';

const toastContext = createContext();
function useProvideToast() {
	const [toasts, setToasts] = useState([]);

	const addToast = (message, variant = 'success') => {
		setToasts([
			...toasts,
			{ message, variant },
		]);
	};

	return {
		toasts,
		setToasts,
		addToast,
	};
}

export function ProvideToast({ children }) {
	const toast = useProvideToast();
	return <toastContext.Provider value={toast}>{children}</toastContext.Provider>;
}

export const useToast = () => useContext(toastContext);
