import React, { useEffect, useState } from 'react';
import {
	Button, Col, Form, FormControl, FormLabel, Modal, ModalBody, ModalFooter, ModalTitle, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../common/messages/ErrorMessage';
import CcvCodeSelect from '../../Form/CcvCodeSelect';
import DateInput from '../../Form/DateInput';
import { CODE_95_POINTS_PROGRESSION } from '../../../utils/code95';
import moment from '../../../moment';
import { sendGoogleAnalyticsEvent } from '../../../utils/googleAnalytics';
import { parseErrorMessage } from '../../../utils/parseErrors';
import useApi from '../../../hooks/use-api';
import * as ROUTES from '../../../constants/routes';
import { useToast } from '../../../hooks/use-toast';
import { CERTIFICATES_ENABLED, CODE_95_ENABLED, useViewPreferences } from '../../../hooks/view-preferences';

export default function ExternalTrainingModalContent({
	training,
	setTraining,
	enrollment,
	setEnrollment,
	excludeCertificate,
	getExcludeCertificateCheckbox,
	handleSubmit,
	setShow,
	cancel,
}) {
	const toast = useToast();
	const { t } = useTranslation();
	const { isPreferenceOptionEnabled } = useViewPreferences();

	const [showDeleteMessage, setShowDeleteMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isValidated, setValidated] = useState(false);
	const [hasCertificate, setHasCertificate] = useState(null);
	const [isDisabled, setDisabled] = useState(false);

	const [, postTraining] = useApi.post(ROUTES.postTraining());
	const [, updateTraining] = useApi.patch(ROUTES.updateTraining(training.id));
	const [, postEnrollment] = useApi.post(ROUTES.enrollments());
	const [, updateEnrollment] = useApi.patch(ROUTES.updateEnrollment(enrollment.id));
	const [, deleteTraining] = useApi.delete(ROUTES.updateTraining(training.id));

	const code95Enabled = isPreferenceOptionEnabled(CODE_95_ENABLED);
	const certificatesEnabled = isPreferenceOptionEnabled(CERTIFICATES_ENABLED);

	useEffect(() => {
		(hasCertificate === null && enrollment.id) && setHasCertificate(!!enrollment.certificateExpirationDate);
	}, [enrollment, hasCertificate]);

	const submit = event => {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;

		setDisabled(true);

		if(form.checkValidity() !== false) {
			const formattedTraining = {
				...training,
				ccvCode: !training.ccvCode ? null : training.ccvCode,
				start: moment.isMoment(training.start) ? training.start.format() : null,
			};

			const formattedEnrollment = {
				...enrollment,
				excludeFromRiskCalculation: excludeCertificate,
				certificateExpirationDate: moment.isMoment(enrollment.certificateExpirationDate) ? enrollment.certificateExpirationDate.format() : null,
			};

			let submitTraining;
			let submitEnrollment;
			let successMessage;
			let eventOptions = null;

			if(training.id) {
				submitTraining = updateTraining;
				submitEnrollment = updateEnrollment;
				successMessage = t('external.edit_submit');
			} else {
				eventOptions = {
					hitType: 'event',
					eventCategory: 'External Training',
					eventAction: 'Create',
				};
				submitTraining = postTraining;
				submitEnrollment = postEnrollment;
				successMessage = t('external.add_submit');
			}

			submitTraining(formattedTraining)
				.then(trainingResult => {
					if(eventOptions) {
						sendGoogleAnalyticsEvent(eventOptions);
					}
					const { id: trainingId } = trainingResult;
					submitEnrollment({
						...formattedEnrollment,
						training: {
							id: trainingId,
						},
					}).then(() => {
						handleSubmit();
						setErrorMessage(null);
						setValidated(false);
						setShowDeleteMessage(false);
						setHasCertificate(false);
						toast.addToast(successMessage);
						setDisabled(false);
						cancel();
					}).catch(e => {
						setErrorMessage(parseErrorMessage(e));
						setDisabled(false);
					});
				})
				.catch(e => {
					setErrorMessage(parseErrorMessage(e));
					setDisabled(false);
				});
		} else {
			setDisabled(false);
			setValidated(true);
		}
	};

	const handleDelete = () => {
		if(!showDeleteMessage) {
			setShowDeleteMessage(true);
		} else {
			deleteTraining()
				.then(() => {
					sendGoogleAnalyticsEvent({
						hitType: 'event',
						eventCategory: 'Code95Cycle',
						eventAction: 'Delete',
					});
					toast.addToast(t('enrollments.external_training_removed'));
					setShow(false);
					handleSubmit();
				}).catch(e => {
					setErrorMessage(parseErrorMessage(e));
				});
		}
	};

	return (
		<Form noValidate validated={isValidated} onSubmit={submit}>
			<Modal.Header closeButton={!isDisabled}>
				<ModalTitle>{training.id ? t('external.edit') : t('external.add')}</ModalTitle>
			</Modal.Header>
			<ModalBody>
				<ErrorMessage message={errorMessage} />
				<Row className="mb-3">
					{code95Enabled && (
						<Col xs={6}>
							<CcvCodeSelect
								value={training.ccvCode}
								handleChange={code => setTraining({
									ccvCode: code?.ccvCode,
									...(code?.description ? { name: code?.description } : {}),
								})}
								xs={6}
							/>
						</Col>
					)}
					<Col xs={6}>
						<FormLabel>{t('external.name')}</FormLabel>
						<FormControl type="text" value={training.name ?? ''} onChange={e => setTraining({ name: e.target.value })} required />
						<Form.Control.Feedback type="invalid">
							{t('add_employee.field_required')}
						</Form.Control.Feedback>
					</Col>
				</Row>

				<Row className="mb-3">
					<Col xs={6}>
						<FormLabel>{t('external.location')}</FormLabel>
						<FormControl type="text" value={training.city ?? ''} onChange={e => setTraining({ city: e.target.value })} />
					</Col>
					<Col xs={6}>
						<FormLabel>{t('external.date')}</FormLabel>
						<DateInput onChange={newDateMoment => setTraining({ start: newDateMoment })} value={training.start} required />
					</Col>
				</Row>

				{certificatesEnabled && (
					<Row className="mb-3">
						<Col xs={6}>
							<FormLabel>{t('external.certificate')}</FormLabel>
							<div>
								<Form.Check type="radio" inline>
									<Form.Check.Input
										type="radio"
										name="certificate"
										checked={hasCertificate}
										onChange={() => setHasCertificate(true)}
									/>
									<Form.Check.Label onClick={() => setHasCertificate(true)}>
										{t('external.yes')}
									</Form.Check.Label>
								</Form.Check>
								<Form.Check type="radio" inline>
									<Form.Check.Input
										type="radio"
										name="certificate"
										checked={!hasCertificate}
										onChange={() => {
											setHasCertificate(false);
											setEnrollment({ certificateExpirationDate: null });
										}}
									/>
									<Form.Check.Label onClick={() => {
										setHasCertificate(false);
										setEnrollment({ certificateExpirationDate: null });
									}}
									>
										{t('external.no')}
									</Form.Check.Label>
								</Form.Check>
							</div>
						</Col>
						<Col xs={6}>
							<FormLabel>{t('external.certificate_expiry_date')}</FormLabel>
							<DateInput
								onChange={newDateMoment => setEnrollment({ certificateExpirationDate: newDateMoment })}
								value={enrollment.certificateExpirationDate}
								disabled={!hasCertificate}
								required={hasCertificate}
							/>
						</Col>
					</Row>
				)}

				{code95Enabled && (
					<Row className="mb-3">
						<Col xs={6}>
							<FormLabel>{t('external.type')}</FormLabel>
							<div>
								<Form.Check type="radio" inline>
									<Form.Check.Input type="radio" name="theory" checked={training.theory} onChange={() => setTraining({ theory: true })} />
									<Form.Check.Label onClick={() => setTraining({ theory: true })}>{t('external.theory')}</Form.Check.Label>
								</Form.Check>
								<Form.Check type="radio" inline>
									<Form.Check.Input type="radio" name="theory" checked={!training.theory} onChange={() => setTraining({ theory: false })} />
									<Form.Check.Label onClick={() => setTraining({ theory: false })}>{t('external.practical')}</Form.Check.Label>
								</Form.Check>
							</div>
						</Col>
						<Col xs={6}>
							<FormLabel>{t('external.amount_of_hours')}</FormLabel>
							<Form.Select value={enrollment.code95Hours} onChange={e => setEnrollment({ code95Hours: parseInt(e.target.value, 10) })}>
								{
									CODE_95_POINTS_PROGRESSION.map(amount => <option key={amount} value={amount}>{amount}</option>)
								}
							</Form.Select>
						</Col>
					</Row>
				)}

			</ModalBody>
			<ModalFooter>
				{certificatesEnabled && getExcludeCertificateCheckbox()}
				{showDeleteMessage && <p>{t('enrollments.remove_external_training_confirm')}</p>}
				{training.id && (
					<Button
						variant={showDeleteMessage ? 'primary' : 'outline-secondary'}
						type="button"
						onClick={() => handleDelete()}
					>
						{t('modalActions.delete')}
					</Button>
				)}

				<Button variant="outline-secondary" disabled={isDisabled} onClick={() => cancel()}>{t('employees.cancel')}</Button>
				{!showDeleteMessage && <Button variant="primary" disabled={isDisabled} type="submit">{training.id ? t('modalActions.edit') : t('modalActions.add')}</Button>}
			</ModalFooter>
		</Form>

	);
}
