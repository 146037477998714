/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {
	Navbar, Nav, NavDropdown,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFirebase } from '../../hooks/firebase';
import { useDationUser } from '../../hooks/dation-user';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Logo from '../../assets/images/logo.png';
import { useFreshchat } from '../../hooks/freshchat';
import { CERTIFICATES_ENABLED, COURSE_AGENDA_ENABLED, useViewPreferences } from '../../hooks/view-preferences';

function TopNavBar() {
	const firebase = useFirebase();
	const navigate = useNavigate();
	const dationUser = useDationUser();
	const { t } = useTranslation();
	const { toggleChat, destroyFreshchat } = useFreshchat();
	const { isPreferenceOptionEnabled } = useViewPreferences();

	const settings = { id: 'Settings', link: ROUTES.SETTINGS };
	const companyAdminMenuOptions = [
		{ name: t('navigation.companyUser.dashboard'), link: ROUTES.DASHBOARD, id: 'dashboardItem' },
		{ name: t('navigation.companyUser.employees'), link: ROUTES.EMPLOYEES, id: 'employeeItem' },
		...(isPreferenceOptionEnabled(COURSE_AGENDA_ENABLED) ? [{ name: t('navigation.companyUser.offer'), link: ROUTES.INVENTORY, id: 'offerItem' }] : []),
		{ name: t('navigation.companyUser.planning'), link: ROUTES.COURSE_PLANNING, id: 'planningItem' },
		...(isPreferenceOptionEnabled(CERTIFICATES_ENABLED) ? [{ name: t('navigation.companyUser.certificates'), link: ROUTES.CERTIFICATES, id: 'certificates' }] : []),
	];

	const schoolAdminMenuVariables = {
		menu_options: [
			{ name: t('navigation.dationUser.companies'), link: ROUTES.COMPANIES },
		],
		impersonation_options: companyAdminMenuOptions,
		shouldUseFreshchat: true,
		settings_option: settings,
	};

	const menuVariablesMapping = {
		[ROLES.COMPANY_ADMIN]: {
			menu_options: companyAdminMenuOptions,
		},
		[ROLES.COMPANY_SUPER_USER]: {
			menu_options: companyAdminMenuOptions,
			settings_option: settings,
		},
		[ROLES.SCHOOL_ADMIN]: schoolAdminMenuVariables,
		[ROLES.SCHOOL_SUPER_USER]: {
			...schoolAdminMenuVariables,
		},
		[ROLES.ADMIN]: {
			menu_options: [
				{ name: t('navigation.adminUser.dation_instances'), link: ROUTES.DATION_INSTANCES },
				{ name: t('navigation.adminUser.statistics'), link: ROUTES.PORTAL_STATISTICS },
			],
		},
	};

	const user = firebase.authUser;
	const { roles, companies = [] } = dationUser.user || {};

	const menuVariables = roles && menuVariablesMapping[roles[0]];

	const {
		menu_options: menuOptions = [],
		impersonation_options: impersonationOptions = [],
		settings_option: settingsOption = null,
		shouldUseFreshchat = false,
	} = menuVariables || {};

	const onSignOut = () => {
		firebase.doSignOut().then(
			() => {
				navigate(ROUTES.LOGIN);
				dationUser.setCurrentCompanyId(null);
				dationUser.setCurrentCompanyToViewName(null);
				destroyFreshchat();
			},
		);
	};

	return (
		<Navbar collapseOnSelect expand="lg" className="top-navbar bg-body-tertiary">
			<Navbar.Brand className="custom-brand" href="#">
				<img src={Logo} className="logo-image" alt={t('portal.name')} />
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className="d-flex flex-collapse justify-content-between w-100">
					<div className="d-flex flex-collapse">
						{menuOptions.map(item => (
							<LinkContainer style={{ fontSize: '1.20rem' }} to={item.link} key={item.name} id={item.id}>
								<Nav.Link>{item.name}</Nav.Link>
							</LinkContainer>
						))}
						{dationUser.companyToViewName && <div className="mx-3 my-0 border-end border-primary border-3" />}
						{dationUser.companyToViewName && impersonationOptions.map(item => (
							<LinkContainer style={{ fontSize: '1.20rem' }} to={item.link} key={item.name} id={item.id}>
								<Nav.Link>{item.name}</Nav.Link>
							</LinkContainer>
						))}
					</div>
					<div className="d-flex flex-collapse flex-collapse-center">
						{dationUser.isDationInstanceUser() && dationUser.companyToViewName && (
							<div>{dationUser.companyToViewName}</div>
						)}
						{dationUser.isCompanyContactPersonUser() && companies.length > 1 && (
							<div className="d-flex">
								<select
									className="form-select"
									defaultValue={dationUser.companyId}
									onChange={({ target }) => dationUser.setCurrentCompanyId(target.value)}
								>
									{companies.map(currentCompany => (
										<option
											key={currentCompany.id}
											value={currentCompany.id}
										>
											{currentCompany.name}
										</option>
									))}
								</select>
							</div>
						)}
						{shouldUseFreshchat
							? (
								<span
									id="freshchatButton"
									title={t('portal.chat_with_support')}
									className="glyphicons glyphicons-user-chat glyphicons-navbar-icon nav-link"
									onClick={toggleChat}
								/>
							) : ''}
						{settingsOption && (
							<LinkContainer id="settingsLink" to={settingsOption.link}>
								<Nav.Link>
									<span className="glyphicons glyphicons-cogwheel glyphicons-navbar-icon d-grid" />
								</Nav.Link>
							</LinkContainer>
						)}
						<NavDropdown
							align="end"
							title={<span className="glyphicons glyphicons-user-rounded  glyphicons-navbar-icon" />}
							id="basic-nav-dropdown"
							className="navbar-dropdown custom-navbar-dropdown"
						>
							{user.displayName && <NavDropdown.Item>{user.displayName}</NavDropdown.Item>}
							{user.email && <NavDropdown.Item>{user.email}</NavDropdown.Item>}
							{dationUser?.user?.company && <NavDropdown.Item>{dationUser.user.company.name}</NavDropdown.Item>}
							<NavDropdown.Divider />
							<NavDropdown.Item
								id="logout"
								onClick={onSignOut}
							>
								{t('portal.logout')}
							</NavDropdown.Item>
						</NavDropdown>
					</div>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

export default TopNavBar;
