import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DEFAULT_SUPPORTED_FILE_FORMATS, getBase64File } from '../../constants/file';

function File({
	customFormats, showFileName = true, onChange, handleFileChange,
}) {
	const { t } = useTranslation();

	const [file, setFile] = useState(null);
	const AVAILABLE_FILE_FORMATS = useMemo(() => customFormats || DEFAULT_SUPPORTED_FILE_FORMATS, [customFormats]);

	// Triggered on file selection
	const changeHandler = event => onChange(new Promise((resolve, reject) => {
		const fileType = event.target.files[0].type;
		const isValidFileFormat = AVAILABLE_FILE_FORMATS.some(validFormat => !!fileType.match(validFormat.regex));

		if(isValidFileFormat) {
			setFile(event.target.files[0]);
			resolve();
		} else {
			// eslint-disable-next-line prefer-promise-reject-errors
			reject({
				error: t('fileUpload.incorrectFormat'),
			});
		}
	}));

	// Triggered after file state is set
	useEffect(() => {
		const onFileChange = async () => {
			const base64File = await getBase64File(file);
			handleFileChange({ base64File, file });
		};
		onFileChange().then();
	}, [file]);

	return (
		<div className="py-3">
			{showFileName && file && (
				<div>
					<p><b>{t('fileUpload.fileName')}:</b> {file.name}</p>
				</div>
			)}
			{!file && <p>{t('fileUpload.chooseFile')}</p>}
			<input className="col-6 mx-0 px-0" type="file" name="file" onChange={changeHandler} />
		</div>
	);
}

File.propTypes = {
	customFormats: PropTypes.arrayOf(PropTypes.string),
	showFileName: PropTypes.bool,
	onChange: PropTypes.func, // Triggered on file selection
	handleFileChange: PropTypes.func, // Triggered after file state is set
};

export default File;
