import React from 'react';
import {
	Col, Form, FormLabel, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DateInput from '../Form/DateInput';

function AddNewCycle({
	code95Student, cycleStart, updateStudent, setCycleStart, hasCycle = false,
}) {
	const { t } = useTranslation();

	return (
		<>
			<Row className="mb-3">
				<Col xs={12} className="d-flex align-items-center">
					<FormLabel className="mb-0">{t('education.followCode95')}</FormLabel>
					<div className="d-flex px-3">
						<Form.Check type="radio" inline>
							<Form.Check.Input
								type="radio"
								name="followCode95"
								checked={code95Student}
								onChange={() => updateStudent({ code95Student: true })}
							/>
							<Form.Check.Label onClick={() => updateStudent({ code95Student: true })}>
								{t('external.yes')}
							</Form.Check.Label>
						</Form.Check>
						<Form.Check type="radio" inline>
							<Form.Check.Input
								type="radio"
								name="followCode95"
								checked={!code95Student}
								onChange={() => updateStudent({ code95Student: false })}
							/>
							<Form.Check.Label onClick={() => updateStudent({ code95Student: false })}>
								{t('external.no')}
							</Form.Check.Label>
						</Form.Check>
					</div>
				</Col>
			</Row>
			{!hasCycle && code95Student && (
				<>
					<div className="d-flex align-items-center mt-3">
						<FormLabel className="mb-0">{t('education.code95CycleStart')}:</FormLabel>
						<div className="ms-2">
							<DateInput
								onChange={newDateMoment => setCycleStart(newDateMoment)}
								value={cycleStart}
							/>
						</div>
					</div>
					<p className="text-muted font-size-small mt-2">{t('education.closeModalHelpText')}</p>
				</>
			)}
		</>
	);
}

export default AddNewCycle;
