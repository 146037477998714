import React, { useEffect } from 'react';
import {
	useGlobalFilter, usePagination, useSortBy, useTable,
} from 'react-table';
import PropTypes from 'prop-types';
import TableHeader from './Default/TableHeader';
import DefaultTableBody from './Default/DefaultTableBody';
import TableGlobalFilter from './Default/TableGlobalFilter';
import TableFooter from './Default/TableFooter';

import { DATION_INSTANCE_USERS_TABLE } from '../../constants/tables';
import useUserPreferences from '../../hooks/use-user-preferences';

const UsersTable = React.memo(({
	columns,
	data,
	className = '',
	handleClick = null,
	showFilter = false,
	showFooter = false,

}) => {
	const { getPageIndex, setPageIndex, getPageSize } = useUserPreferences();
	const tableName = DATION_INSTANCE_USERS_TABLE;
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		pageOptions,
		rows,
		page,
		state: { pageIndex, pageSize, globalFilter },
		gotoPage,
		previousPage,
		nextPage,
		setPageSize,
		canPreviousPage,
		canNextPage,
		setGlobalFilter,
	} = useTable({
		columns,
		data,
		initialState: {
			pageIndex: getPageIndex(tableName),
			pageSize: getPageSize(tableName),
			sortBy: [
				{
					id: 'name',
					desc: false,
				},
			],
		},
	}, useGlobalFilter, useSortBy, usePagination);

	useEffect(() => {
		setPageIndex(tableName, pageIndex);
	}, [pageIndex]);
	return (
		<div className={className}>
			{showFilter && (
				<div className="d-flex justify-content-between align-items-end">
					<TableGlobalFilter setGlobalFilter={setGlobalFilter} globalFilter={globalFilter} />
				</div>
			)}
			<div className="table-responsive overflow-hidden">
				<table {...getTableProps()} className="table table-large table-hover align-middle">
					<TableHeader headerGroups={headerGroups} />
					<DefaultTableBody
						getTableBodyProps={getTableBodyProps}
						data={showFooter ? page : rows}
						prepareRow={prepareRow}
						handleClick={handleClick}
					/>
				</table>
				{showFooter && (
					<TableFooter
						tableName={tableName}
						previousPage={previousPage}
						canPreviousPage={canPreviousPage}
						nextPage={nextPage}
						canNextPage={canNextPage}
						pageIndex={pageIndex}
						pageOptions={pageOptions}
						gotoPage={gotoPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						pageSizeOptions={[10, 50]}
						totalItems={data.length}
					/>
				)}
			</div>
		</div>
	);
});

UsersTable.proptypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	className: PropTypes.string,
	handleClick: PropTypes.func,
	showFilter: PropTypes.bool,
	showFooter: PropTypes.bool,
};

export default UsersTable;
