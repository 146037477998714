import React from 'react';
import PropTypes from 'prop-types';
import CheckboxInput from './CheckBoxInput';

export function UserRoleCheckboxInput({
	role,
	userRole,
	...props
}) {
	return (
		<CheckboxInput
			id={`dation-user-${role.toLowerCase()}`}
			name="userRole"
			value={role}
			checked={userRole === role}
			{...props}
		/>
	);
}

UserRoleCheckboxInput.propTypes = {
	role: PropTypes.string,
	userRole: PropTypes.string,
};

export default UserRoleCheckboxInput;
