import axios from 'axios';

const api = async (method, url, variables, customConfig = {}, accept = 'application/json', contentType = 'application/json') => {
	const headers = {
		'Content-Type': contentType,
		Accept: accept,
	};

	if(sessionStorage.getItem('idToken') !== null) {
		headers.Authorization = `Bearer ${sessionStorage.getItem('idToken')}`;
	}

	const axiosInstance = axios.create({
		baseURL: process.env.REACT_APP_API_HOST,
	});

	const responseInterceptor = response => response;

	axiosInstance.interceptors.response.use(responseInterceptor);

	const requestConfig = {
		url,
		method,
		headers,
		...customConfig,
		...(method === 'get' && { params: variables }),
		...(method !== 'get' && { data: variables }),
	};

	return axiosInstance.request(requestConfig);
};

export default {
	get: (...args) => api('get', ...args),
	post: (...args) => api('post', ...args),
	put: (...args) => api('put', ...args),
	patch: (...args) => api('patch', ...args),
	delete: (...args) => api('delete', ...args),
};
