import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InfoBar from '../components/Navigation/InfoBar';
import LogbookTable from '../components/Tables/LogbookTable';
import { compareTableDates } from '../utils/sort';
import * as ROUTES from '../constants/routes';

function LogbookPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const headers = useMemo(() => [
		{
			Header: t('logbook.title'),
			accessor: 'title',
		},
		{
			Header: t('logbook.user'),
			accessor: 'username',
		},
		{
			Header: t('logbook.date'),
			accessor: 'date',
			sortType: (a, b) => compareTableDates('date', a, b),
		},
	]);
	return (
		<>
			<InfoBar
				backTitle={t('logbook.logbook')}
				goBack={() => navigate(ROUTES.SETTINGS)}
			/>
			<LogbookTable columns={headers} />
		</>
	);
}

export default LogbookPage;
