import React from 'react';
import Message from './Message';

function InfoMessage(props) {
	return <Message variant="info" {...props} bold />;
}

InfoMessage.propTypes = Message.propTypes;

export default InfoMessage;
