/* eslint-disable import/prefer-default-export */
/**
 * Verifies the password and returns translation keys for a specific error.
 */
export const verifyPassword = passwordToVerify => {
	let errors = [];
	if(passwordToVerify.length < 8) {
		errors = [
			...errors,
			'portal.password_minimum_characters',
		];
	}

	const matchesDigit = passwordToVerify.match(/\d+/g);
	if(matchesDigit === null) {
		errors = [
			...errors,
			'portal.password_minimum_digits',
		];
	}

	const matchesLowercase = passwordToVerify.match(/[a-z]/g);
	if(matchesLowercase === null) {
		errors = [
			...errors,
			'portal.password_minimum_lowercase_characters',
		];
	}

	const matchesUppercase = passwordToVerify.match(/[A-Z]/g);
	if(matchesUppercase === null) {
		errors = [
			...errors,
			'portal.password_minimum_uppercase_characters',
		];
	}

	return errors;
};
