import React from 'react';
import { useSortBy, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TableHeader from './Default/TableHeader';
import DefaultTableBody from './Default/DefaultTableBody';

function StudentEnrollmentTable({
	columns, data, handleClick, renderHeaderRight,
}) {
	const { t } = useTranslation();

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
	} = useTable({
		columns,
		data,
		initialState: {
			sortBy: [
				{
					id: 'startDate',
					desc: true,
				},
			],
		},
	}, useSortBy);

	return (
		<>
			<div className="d-flex justify-content-between">
				<h5>
					<span className="glyphicons glyphicons-calendar me-2 ms-3" />
					{t('employees.employeeDetailsTabs.enrollments')}
				</h5>
				{renderHeaderRight()}
			</div>
			<div className="table-responsive overflow-hidden">
				<table {...getTableProps()} className="table table-large table-hover">
					<TableHeader headerGroups={headerGroups} />
					<DefaultTableBody getTableBodyProps={getTableBodyProps} data={rows} prepareRow={prepareRow} handleClick={handleClick} />
				</table>
			</div>
		</>
	);
}

StudentEnrollmentTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	handleClick: PropTypes.func,
	renderHeaderRight: PropTypes.func,
};

export default StudentEnrollmentTable;
