import React from 'react';
import { useTranslation } from 'react-i18next';

export const ESTABLISHMENT_FILTER_KEY = 'establishmentId';
function EstablishmentFilter({ establishments, filters, setFilter }) {
	const { t } = useTranslation();
	return (
		<div>
			{establishments?.length > 0 && (
				<select
					className="form-select"
					onChange={({ target }) => setFilter(ESTABLISHMENT_FILTER_KEY, parseInt(target.value, 10))}
					style={{ minWidth: 250 }}
					value={filters?.find(filter => filter.id === ESTABLISHMENT_FILTER_KEY)?.value || 0}
				>
					<option value={0}>{t('table.allEstablishments')}</option>
					{establishments.map(establishment => (
						<option key={establishment.id} value={establishment.id}>{establishment.name}</option>
					))}
				</select>
			)}
		</div>
	);
}

export default EstablishmentFilter;
