import React, {
	createContext, useContext, useEffect, useState,
} from 'react';
import { useDationUser } from '../dation-user';

export const CODE_95_ENABLED = 'code95Enabled';
export const EDUCATION_INFORMATION_ENABLED = 'educationInformationEnabled';
export const CERTIFICATES_ENABLED = 'certificatesEnabled';
export const LICENSE_EXPIRY_DATE_ENABLED = 'licenseExpiryDateEnabled';
export const DRIVING_PASS_EXPIRY_DATE_ENABLED = 'drivingPassExpiryDateEnabled';
export const COURSE_AGENDA_ENABLED = 'courseAgendaEnabled';
export const VIEW_PREFERENCE_OPTIONS = [
	CODE_95_ENABLED,
	EDUCATION_INFORMATION_ENABLED,
	CERTIFICATES_ENABLED,
	LICENSE_EXPIRY_DATE_ENABLED,
	DRIVING_PASS_EXPIRY_DATE_ENABLED,
	COURSE_AGENDA_ENABLED,
];

const viewPreferencesContext = createContext();

function useProvideViewPreferences() {
	const { user } = useDationUser();
	const [preferences, setPreferences] = useState(null);

	useEffect(() => {
		const { companyContactPerson } = user || {};
		const { viewPreferences } = companyContactPerson || {};

		if(companyContactPerson) {
			setPreferences(viewPreferences);
		} else {
			setPreferences(VIEW_PREFERENCE_OPTIONS.reduce(
				(accumulator, currentValue) => ({ ...accumulator, [currentValue]: true }),
				{},
			));
		}
	}, [user]);

	function isPreferenceOptionEnabled(preferenceOption) {
		return preferences !== null ? preferences[preferenceOption] : false;
	}

	return {
		isPreferenceOptionEnabled,
		setPreferences,
	};
}

export function ProvideViewPreferences({ children }) {
	const viewPreferences = useProvideViewPreferences();
	return <viewPreferencesContext.Provider value={viewPreferences}>{children}</viewPreferencesContext.Provider>;
}

export const useViewPreferences = () => useContext(viewPreferencesContext);
