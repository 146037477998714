/* eslint-disable import/prefer-default-export */
import moment from '../moment';

const CODE_95_ZERO = 0;
const CODE_95_SEVEN = 7;
const CODE_95_FOURTEEN = 14;
const CODE_95_TWENTY_ONE = 21;
const CODE_95_TWENTY_EIGHT = 28;
const CODE_95_THIRTY_FIVE = 35;

export const CODE_95_POINTS_PROGRESSION = [
	CODE_95_ZERO,
	CODE_95_SEVEN,
	CODE_95_FOURTEEN,
	CODE_95_TWENTY_ONE,
	CODE_95_TWENTY_EIGHT,
	CODE_95_THIRTY_FIVE,
];
export const RISK_LOW = 1;
export const RISK_AVERAGE = 2;
export const RISK_HIGH = 3;

export const NO_CYCLE_CYCLE_REQUIRED = -1;

export const MAXIMUM_COMBINED_HOURS_PER_CYCLE = CODE_95_THIRTY_FIVE;

export const calculateCode95CyclePoints = cycle => Math.min(cycle.practiceHours + cycle.theoryHours, MAXIMUM_COMBINED_HOURS_PER_CYCLE);

export const getCurrentCode95Cycle = code95Cycles => {
	const today = moment();

	const currentCycle = code95Cycles.filter(cycle => {
		const startDate = moment(cycle.startDate);
		const endDate = moment(cycle.endDate);

		return today.isBetween(startDate, endDate);
	});

	return currentCycle.length ? currentCycle[0] : null;
};
