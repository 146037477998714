import React from 'react';
import {
	Button, Modal, ModalBody, ModalFooter, ModalTitle,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import WarningMessage from '../common/WarningMessage';
import ErrorMessage from '../common/messages/ErrorMessage';

function ConfirmModal({
	show, setShow, title, message, handleSubmit, errorMessage = null, warning = null,
}) {
	const { t } = useTranslation();
	return (
		<Modal show={show} onHide={() => setShow(false)} size="lg">
			<Modal.Header closeButton>
				<ModalTitle>{title}</ModalTitle>
			</Modal.Header>
			<ModalBody>
				<ErrorMessage message={errorMessage} />
				<div className="p-2">
					{warning && <WarningMessage message={warning} />}
					<p>{message}</p>
				</div>
			</ModalBody>
			<ModalFooter>
				{errorMessage ? (
					<Button variant="outline-secondary" onClick={() => setShow(false)}>{t('modalActions.cancel')}</Button>
				) : (
					<>
						<Button variant="outline-secondary" onClick={() => setShow(false)}>{t('modalActions.cancel')}</Button>
						<Button variant="primary" onClick={() => handleSubmit()}>{t('modalActions.confirm')}</Button>
					</>
				)}
			</ModalFooter>
		</Modal>
	);
}
ConfirmModal.propTypes = {
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
	warning: PropTypes.string,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	errorMessage: PropTypes.string,
};

export default ConfirmModal;
