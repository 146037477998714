import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tab } from 'react-bootstrap';
import moment from '../../moment';
import EducationsTable from '../../components/Tables/EducationsTable';
import TabsHeader from '../../components/Tabs/TabsHeader';
import { useTabs } from '../../hooks/use-tabs';
import { useApi } from '../../hooks/use-api';
import * as ROUTES from '../../constants/routes';
import CloseEducationModal from '../../components/Modal/CloseEducationModal';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import { useToast } from '../../hooks/use-toast';
import { compareTableDates } from '../../utils/sort';

function Educations({ employee, fetchEmployee, putEmployee }) {
	const { t } = useTranslation();
	const toast = useToast();

	const [{
		data,
		isLoading,
	}] = useApi.get(ROUTES.getEmployeeAppointmentsPerEducation(employee.id));
	const { key, handleKeyChange } = useTabs(data ? data.map(item => item.educationName) : []);
	const [showCloseEducationModal, setShowCloseEducationModal] = useState(false);
	const [showOpenEducationModal, setShowOpenEducationModal] = useState(false);

	const educationTableHeader = React.useMemo(() => [
		{
			Header: t('educations.date'),
			accessor: 'date',
			Cell: ({ row: { original } }) => `${moment(original.start).format('DD-MM-YYYY HH:mm')} - ${moment(original.end).format('HH:mm')}`,
			sortType: (a, b) => compareTableDates('start', a, b),
		},
		{
			Header: t('educations.location'),
			Cell: ({ row: { original } }) => (original?.location?.address
				? (original.location.address?.city ?? '')
				: ''),
		},
		{
			Header: t('educations.product'),
			accessor: 'product',
		},
		{
			Header: t('educations.result'),
			accessor: 'examResult',
		},
		{
			Header: t('educations.educator'),
			accessor: 'dationInstanceName',
		},
	], [t]);

	if(isLoading) {
		return <div className="loading-spinner" />;
	}

	const handleOpenEducationSubmit = () => {
		putEmployee({ education: true }).then(() => {
			toast.addToast(t('education.updateSuccess'));
			fetchEmployee();
		}).catch(() => {
			toast.addToast(t('education.updateFail'), 'error');
		}).finally(() => setShowOpenEducationModal(false));
	};

	return data?.length ? (
		<>
			<CloseEducationModal
				show={showCloseEducationModal}
				setShow={setShowCloseEducationModal}
				fetchEmployee={fetchEmployee}
				employee={employee}
				putEmployee={putEmployee}
			/>
			<ConfirmModal
				show={showOpenEducationModal}
				setShow={setShowOpenEducationModal}
				title={t('education.open')}
				message={t('education.openModalConfirmText')}
				handleSubmit={handleOpenEducationSubmit}
			/>
			<Tab.Container id="employee-overview-tabs" activeKey={key} onSelect={k => handleKeyChange(k)}>
				<div className="mb-3 d-flex justify-content-between align-items-center">
					<TabsHeader
						options={{ variant: 'pills' }}
						tabKey={key}
						tabs={data
							.map(item => item.educationName)
							.sort((educationName1, educationName2) => educationName1.localeCompare(educationName2))}
					/>
					{
						employee.education ? (
							<Button
								variant="outline-primary"
								type="button"
								onClick={() => setShowCloseEducationModal(true)}
							>
								{t('education.close')}
							</Button>
						) : (
							<Button
								variant="outline-primary"
								type="button"
								onClick={() => setShowOpenEducationModal(true)}
							>
								{t('education.open')}
							</Button>
						)
					}
				</div>
				<Tab.Content>
					{data.map(item => (
						<Tab.Pane eventKey={item.educationName} key={item.educationName}>
							<EducationsTable columns={educationTableHeader} data={item.appointments} />
						</Tab.Pane>
					))}
				</Tab.Content>
			</Tab.Container>
		</>
	) : (
		<p className="mt-3 ms-3">{t('educations.no-planning')}</p>
	);
}

export default Educations;
