import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectFilter, { OPTION_ALL } from './SelectFilter';

function BooleanFilter({
	column: {
		id, filterValue, setFilter,
	},
}) {
	const { t } = useTranslation();

	const options = [
		{ value: OPTION_ALL, key: OPTION_ALL, name: t('table.all') },
		{ value: 1, key: 1, name: t('employees.practical_yes') },
		{ value: 0, key: 0, name: t('employees.practical_no') },
	];

	return <SelectFilter id={id} filterValue={filterValue} setFilter={setFilter} options={options} />;
}

export const booleanFilter = (rows, columnIds, filterValue) => {
	if(filterValue === OPTION_ALL) {
		return rows;
	}

	return rows.filter(({ original }) => !!original[columnIds[0]] === !!parseInt(filterValue, 10));
};

export default BooleanFilter;
