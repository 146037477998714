/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InfoBar from '../../components/Navigation/InfoBar';
import useApi from '../../hooks/use-api';
import { useDationUser } from '../../hooks/dation-user';
import * as ROUTES from '../../constants/routes';
import { parseErrorMessage } from '../../utils/parseErrors';
import ErrorMessage from '../../components/common/messages/ErrorMessage';

function ImportCompanyPage() {
	const dationUser = useDationUser();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const dationInstanceId = dationUser.user.dationInstance.id;
	const [{ data: existingData, isLoading: isExistingLoading }] = useApi.get(ROUTES.getExistingCompaniesByDationInstance(dationInstanceId), {}, { cachePolicy: 'no-cache' });
	const [{ data, isLoading }] = useApi.get(ROUTES.getCompaniesFromDationInstance(dationInstanceId), {}, { cachePolicy: 'no-cache' });

	const [selectedCompany, setSelectedCompany] = useState('');
	const [isImporting, setImporting] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [filteredData, setFilteredData] = useState(null);

	const [state, postCompany] = useApi.post(`dation_instances/${dationInstanceId}/companies/${selectedCompany}/import`, {}, { lazy: true });

	const noAvailableCompanies = () => filteredData?.length === 0;

	const handleChange = event => {
		setSelectedCompany(event.target.value);
	};

	useEffect(() => {
		if(filteredData === null && !!data && !!existingData) {
			const existingCompanyIndex = {};
			existingData.forEach(companyData => companyData.dationIdentifiers.forEach(dationIdentifier => { existingCompanyIndex[`${dationIdentifier.dationId}_${dationIdentifier.dationInstance.id}`] = true; }));
			const newFilteredData = data
				.filter(companyToAdd => !existingCompanyIndex[`${companyToAdd.id}_${dationInstanceId}`])
				.sort((a, b) => a.name.localeCompare(b.name));

			if(!selectedCompany && newFilteredData.length) {
				setSelectedCompany(newFilteredData[0].id);
			}

			setFilteredData(newFilteredData);
		}
	}, [data, existingData, filteredData, selectedCompany]);

	const importCompany = () => {
		setImporting(true);
		postCompany().then(responseObject => {
			const { isAxiosError } = responseObject;

			if(isAxiosError) {
				const { response: { data: errors } } = responseObject;
				setImporting(false);
				setErrorMessage(errors.join(''));
			} else {
				navigate(ROUTES.COMPANIES);
			}
		})
			.catch(response => {
				setImporting(false);
				setErrorMessage(parseErrorMessage(response));
			});
	};

	return (
		<>
			<InfoBar backTitle={t('company.companies')} />
			<div className="row justify-content-center">
				{isImporting && <p>{t('portal.loading')}</p>}
				{!isImporting && (
					<div className="col-10 col-md-6 col-lg-4">
						{t('company.import_company_text')}
						<hr />
						<div>
							{!isLoading && !isImporting && (
								<ErrorMessage message={errorMessage} />
							)}
							<label htmlFor="companySelect">{t('company.select_company')}</label>
							<select
								className="form-select mt-2"
								id="companySelect"
								value={selectedCompany}
								onChange={handleChange}
								disabled={isLoading}
							>
								{(isLoading || isExistingLoading) && <option>{t('portal.loading')}</option>}
								{!!filteredData && filteredData.map(option => (
									<option key={option.id} value={option.id}>{option.name}</option>
								))}
								{noAvailableCompanies() && <option>Geen bedrijf beschikbaar</option>}
							</select>
						</div>
						<div className="text-end mt-3">
							<Button
								id="import-company"
								variant="primary"
								onClick={importCompany}
								disabled={noAvailableCompanies()}
							>
								{t('portal.add')}
							</Button>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default ImportCompanyPage;
