/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
	useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
	Button, OverlayTrigger, Tab, Tooltip,
} from 'react-bootstrap';
import { useApi } from '../../hooks/use-api';
import * as ROUTES from '../../constants/routes';
import moment from '../../moment';
import InfoBar from '../../components/Navigation/InfoBar';
import FormGroupPlainText from '../../components/Form/FormGroupPlainText';
import { compareTableCertificateExpirationDates, compareTableSortDates } from '../../utils/sort';
import StudentEnrollmentTable from '../../components/Tables/StudentEnrollmentTable';
import Code95RiskCard from '../../components/StudentRisk/Code95RiskCard';
import { calculateCode95CyclePoints, getCurrentCode95Cycle } from '../../utils/code95';
import Code95CycleModal from '../../components/Modal/Code95CycleModal';
import StudentModal from '../../components/Modal/StudentModal';
import { getDisplayName } from '../../utils/person';
import TrainingModal from '../../components/Modal/TrainingModal/TrainingModal';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import { useToast } from '../../hooks/use-toast';
import { parseErrorMessage } from '../../utils/parseErrors';
import CertificationRiskBadge from '../../components/StudentRisk/CertificationRiskBadge';
import { getWeekdayDateFormat } from '../../utils/date';
import { getCCVCodePrefix, getRisk } from '../../utils/training';
import { useDationUser } from '../../hooks/dation-user';
import Documents from './Documents';
import RightHeaderButton from '../../components/Tables/Default/RightHeaderButton';
import TabsHeader from '../../components/Tabs/TabsHeader';
import { useTabs } from '../../hooks/use-tabs';
import Educations from './Educations';
import {
	CERTIFICATES_ENABLED,
	CODE_95_ENABLED, DRIVING_PASS_EXPIRY_DATE_ENABLED,
	EDUCATION_INFORMATION_ENABLED, LICENSE_EXPIRY_DATE_ENABLED,
	useViewPreferences,
} from '../../hooks/view-preferences';
import FormGroupTextWrapper from '../../components/Form/FormGroupTextWrapper';

const EMPLOYEE_OVERVIEW_TAB = 'employee_overview_tab';

// These must match with the tab translation key
const ENROLLMENTS_TAB = 'enrollments';
const EDUCATIONS_TAB = 'educations';
const DOCUMENTS_TAB = 'documents';

function EmployeeDetailPage() {
	const { t } = useTranslation();
	const toast = useToast();
	const dationUser = useDationUser();
	const { isPreferenceOptionEnabled } = useViewPreferences();

	const tabs = [
		ENROLLMENTS_TAB,
		...(isPreferenceOptionEnabled(EDUCATION_INFORMATION_ENABLED) ? [EDUCATIONS_TAB] : []),
		DOCUMENTS_TAB,
	];
	const { key, handleKeyChange } = useTabs(tabs, EMPLOYEE_OVERVIEW_TAB);

	const { employeeId } = useParams();
	const [currentCycle, setCurrentCycle] = useState(null);
	const [latestCycle, setLatestCycle] = useState(null);
	const [showCode95Modal, setShowCode95Modal] = useState(false);
	const [showStudentModal, setShowStudentModal] = useState(false);
	const [showTrainingModal, setShowTrainingModal] = useState(false);

	const [showConfirmArchive, confirmArchive] = useState(false);
	const [hasFutureEnrollments, setFutureEnrollments] = useState(false);
	const [enrollments, setEnrollments] = useState([]);

	const [selectedEnrollment, setSelectedEnrollment] = useState(null);

	const [{
		data: employee,
		isLoading,
	}, fetchEmployee] = useApi.get(ROUTES.getEmployeeDetailsWithEnrollments(employeeId));

	const [{ data: updateEmployeeData }, putEmployee] = useApi.patch(ROUTES.editEmployee(employeeId));
	const [{ data: establishmentData }] = useApi.get(ROUTES.getEstablishmentsForCompany(dationUser.getCurrentCompanyId()));

	const [isArchived, setArchived] = useState(false);

	useEffect(() => {
		if(updateEmployeeData && updateEmployeeData.archived !== 'undefined') {
			setArchived(updateEmployeeData.archived);
		}
	}, [updateEmployeeData]);

	const startDateCell = useCallback(({ row }) => {
		const { original: { enrollment: { training } } } = row;
		const showWarning = moment(training.start).isAfter(moment('2021-08-05', 'YYYY-MM-DD'));
		const enrolledToAll = training.slots.filter(slot => slot.students.find(student => student.id === row.original.enrollment.student.id)).length === training.slots.length;
		const { external } = training;
		return (
			<span style={{ alignItems: 'center', display: 'flex' }}>
				<div className="w-100">
					<span className="glyphicons glyphicons-calendar me-2 font-size-large" />
					{getWeekdayDateFormat(row.original.startDate)}
				</div>
				{(showWarning && !enrolledToAll && !external) && (
					<OverlayTrigger
						placement="right"
						overlay={(
							<Tooltip id={`tooltip_${training.id}`}>
								{t('enrollments.not_enrolled_to_all_slots_message')}
							</Tooltip>
						)}
					>
						<span className="glyphicons glyphicons-circle-alert text-danger m-1" />
					</OverlayTrigger>
				)}
			</span>
		);
	}, []);

	const riskCell = useCallback(cellInfo => {
		const { row } = cellInfo;
		return row.original.certificationRisk
			? <CertificationRiskBadge risk={row.original.certificationRisk} /> : null;
	}, []);

	const expirationDateCell = useCallback(cellInfo => {
		const { row, cell: { value } } = cellInfo;
		const { showCertificationRisk } = row.original;
		return <div className={`${!showCertificationRisk ? 'text-secondary' : ''}`}>{value}</div>;
	}, []);

	const headers = React.useMemo(() => [
		{
			Header: t('enrollments.trainingName'),
			accessor: 'enrollment.training.name',
		},
		{
			Header: t('enrollments.date'),
			accessor: 'startDate',
			sortType: compareTableSortDates,
			Cell: startDateCell,
		},
		{
			Header: t('enrollments.city'),
			accessor: 'enrollment.training.city',
		},
		...(isPreferenceOptionEnabled(CODE_95_ENABLED) ? [
			{
				Header: t('enrollments.ccvHours'),
				accessor: 'enrollment.code95Hours',
			},
			{
				Header: t('enrollments.trainingType'),
				accessor: 'theoryAccessor',
				Cell: cellInfo => (cellInfo.cell.value ? t('enrollments.theoryTraining') : t('enrollments.practiceTraining')),
			},
		] : []),
		...(isPreferenceOptionEnabled(CERTIFICATES_ENABLED) ? [
			{
				Header: t('employees.risk'),
				accessor: 'certificationRisk',
				Cell: riskCell,
			},
			{
				Header: t('enrollments.validUntil'),
				accessor: 'enrollment.certificateExpirationDate',
				sortType: compareTableCertificateExpirationDates,
				Cell: expirationDateCell,
			}] : []),
		...(employee?.code95Student ? [{
			Header: t('enrollments.currentCycle'),
			accessor: 'inCurrentCycle',
		}] : []),
	], [t, employee]);

	const transformTrainingDataForStudentOverview = ({ enrollments: enrollmentData, code95Cycles }) => {
		const cachedCcvCodeTrainings = [];
		const currentCycleForStudent = code95Cycles.find(cycle => moment().isBefore(moment(cycle.endDate)) && moment().isAfter(moment(cycle.startDate)));
		const currentCycleStart = currentCycleForStudent ? moment(currentCycleForStudent.startDate) : null;
		const currentCycleEnd = currentCycleForStudent ? moment(currentCycleForStudent.endDate) : null;

		const parsedData = enrollmentData.map(data => {
			const { training } = data;

			if(moment(training.start).isAfter()) {
				!hasFutureEnrollments && setFutureEnrollments(true);
			}
			// react-table doesnt sort on boolean value
			const theoryAccessor = training.theory ? '1' : '';
			const plannedDate = moment(training.start);

			// Cache trainings by CCV code prefix
			// (N18,  N18-1, N18-2B) will be evaluate as trainings from the same ccvCode
			const ccvCodePrefix = training.ccvCode ? getCCVCodePrefix(training.ccvCode) : null;
			let risk = null;

			if(
				data.certificateExpirationDate
				&& !data.excludeFromRiskCalculation
			) {
				risk = getRisk(moment(data.certificateExpirationDate));
				if(ccvCodePrefix) {
					if(cachedCcvCodeTrainings[ccvCodePrefix] !== undefined) {
						// overwrite cachedCcvCodeTrainings only if is most recent date
						const cacheDate = moment(cachedCcvCodeTrainings[ccvCodePrefix]?.start);
						if(plannedDate.isAfter(cacheDate)) {
							cachedCcvCodeTrainings[ccvCodePrefix] = training;
						}
					} else {
						// save new training to cachedCcvCodeTrainings
						cachedCcvCodeTrainings[ccvCodePrefix] = training;
					}
				}
			}

			return {
				startDate: plannedDate,
				sortDate: plannedDate.format(),
				enrollment: {
					...data,
					certificateExpirationDate: data.certificateExpirationDate ? moment(data.certificateExpirationDate).format('L') : null,
				},
				certificationRisk: risk,
				inCurrentCycle: currentCycleStart && plannedDate.isBetween(currentCycleStart, currentCycleEnd, undefined, '[]')
					? t('enrollments.yes') : t('enrollments.no'),
				theoryAccessor,
			};
		});
		return parsedData.map(item => {
			const { id: trainingId, ccvCode = null } = item.enrollment.training;
			const ccvCodePrefix = ccvCode ? getCCVCodePrefix(ccvCode) : null; // Use to look for cached trainings by CcvCode Prefix
			const showRisk = cachedCcvCodeTrainings[ccvCodePrefix]?.id === trainingId || !ccvCode;
			return {
				...item,
				certificationRisk: showRisk ? item.certificationRisk : null,
			};
		});
	};

	useEffect(() => {
		if(employee) {
			setArchived(employee.archived);

			if(employee.code95Cycles) {
				const cycle = getCurrentCode95Cycle(employee.code95Cycles);
				const formatCycle = cycleItem => ({
					startDate: moment(cycleItem.startDate),
					endDate: moment(cycleItem.endDate),
					points: calculateCode95CyclePoints(cycleItem),
					practice: cycleItem.practiceHours >= 7,
				});
				setCurrentCycle(cycle ? formatCycle(cycle) : null);
				if(!cycle) {
					const latest = employee.code95Cycles.length ? employee.code95Cycles[employee.code95Cycles.length - 1] : null;
					latest && setLatestCycle(latest);
				}
			}
			if(employee.enrollments) {
				setEnrollments(transformTrainingDataForStudentOverview(employee));
			}
		}
	}, [employee]);

	const toggleArchive = () => {
		const employeeData = {
			archived: !isArchived,
		};
		confirmArchive(false);
		putEmployee(employeeData).then(() => {
			toast.addToast(isArchived ? t('employees.employee_activated') : t('employees.employee_archived'));
		}).catch(e => {
			const error = `${isArchived ? t('employees.activate_employee_error') : t('employees.archive_employee_error')} ${parseErrorMessage(e)}`;
			toast.addToast(error, 'error');
		});
	};

	const checkArchivingRequirements = () => {
		// check for future trainings
		if(!hasFutureEnrollments || isArchived) {
			confirmArchive(true);
		} else {
			toast.addToast(t('employees.archive_employee_not_allowed'), 'error');
		}
	};

	const handleEnrollment = () => {
		setSelectedEnrollment(null);
		setShowTrainingModal(true);
	};

	const handleOpenModal = editHandler => (isArchived ? toast.addToast(t('employees.edit_employee_not_allowed'), 'error') : editHandler());

	const enrollmentsTableRightHeader = () => (
		<RightHeaderButton
			title={t('enrollments.add')}
			onClick={() => handleOpenModal(handleEnrollment)}
			icon="glyphicons-file-plus"
		/>
	);

	if(isLoading) {
		return <div className="loading-spinner" />;
	}

	return (
		<>
			<InfoBar
				backTitle={getDisplayName(employee)}
				badgeTitle={t('employees.archived')}
				showBadge={isArchived}
			/>
			<Code95CycleModal
				cycles={employee?.code95Cycles || []}
				isCode95Student={employee.code95Student}
				show={showCode95Modal}
				setShow={setShowCode95Modal}
				studentId={employee.id}
				handleSubmit={fetchEmployee}
			/>
			<StudentModal
				setShow={setShowStudentModal}
				show={showStudentModal}
				employeeData={{
					...employee,
					archived: isArchived,
				}}
				handleSubmit={fetchEmployee}
				establishmentData={establishmentData}
			/>
			<TrainingModal
				setShow={setShowTrainingModal}
				show={showTrainingModal}
				student={{
					...employee,
					archived: isArchived,
				}}
				data={selectedEnrollment}
				handleSubmit={() => {
					setSelectedEnrollment(null);
					fetchEmployee();
				}}
			/>
			<div className="row">
				<div className="col-4 col-sm-2 mt-n3 pt-3 studentDetailsPanel">
					<div className="d-flex justify-content-between">
						<h6>{t('employees.general')}</h6>
						<span
							className={`glyphicons glyphicons-more code95-more mt-n2 ${isArchived ? 'text-secondary' : ''}`}
							onClick={() => handleOpenModal(() => setShowStudentModal(true))}
						/>
					</div>

					<FormGroupPlainText label={t('employees.name')} value={getDisplayName(employee)} />
					<FormGroupPlainText label={t('employees.officialFirstName')} value={employee.officialFirstName} />
					<FormGroupPlainText
						label={t('employees.dateOfBirth')}
						value={employee.dateOfBirth ? moment(employee.dateOfBirth).format('L') : ''}
					/>
					<FormGroupPlainText label={t('employees.placeOfBirth')} value={employee.placeOfBirth} />
					<FormGroupPlainText label={t('employees.email')} value={employee.emailAddress} />
					<FormGroupPlainText label={t('employees.phone')} value={employee.phoneNumber} />
					<FormGroupPlainText label={t('employees.cbrNumber')} value={employee.cbrId} />
					<FormGroupPlainText label={t('employees.employeeNumber')} value={employee.employeeNumber} />
					{establishmentData?.length > 0 && (
						<FormGroupPlainText
							label={t('employees.establishment')}
							value={employee.establishment?.name}
						/>
					)}

					<FormGroupTextWrapper
						label={t('employees.comments')}
						value={employee.comments}
					/>

					<br />

					{(isPreferenceOptionEnabled(LICENSE_EXPIRY_DATE_ENABLED) || isPreferenceOptionEnabled(DRIVING_PASS_EXPIRY_DATE_ENABLED)) && (
						<>
							<h6>{t('employees.expirationDates')}</h6>

							{isPreferenceOptionEnabled(LICENSE_EXPIRY_DATE_ENABLED) && (
								<FormGroupPlainText
									label={t('employees.drivingLicense')}
									value={employee.drivingLicenseExpiryDate ? moment(employee.drivingLicenseExpiryDate).format('L') : null}
								/>
							)}
							{isPreferenceOptionEnabled(DRIVING_PASS_EXPIRY_DATE_ENABLED) && (
								<FormGroupPlainText
									label={t('employees.driverPass')}
									value={employee.driverPassExpiryDate ? moment(employee.driverPassExpiryDate).format('L') : null}
								/>
							)}
							<br />
						</>
					)}

					{isPreferenceOptionEnabled(CODE_95_ENABLED) && (
						<>
							<div className="d-flex justify-content-between">
								<h6>{t('code95.code95')}</h6>
								<span
									className={`glyphicons glyphicons-more code95-more mt-n2 ${isArchived ? 'text-secondary' : ''}`}
									onClick={() => handleOpenModal(() => setShowCode95Modal(true))}
								/>
							</div>
							<FormGroupPlainText
								label={t('code95.followCode95')}
								value={employee.code95Student ? t('external.yes') : t('external.no')}
							/>
							<FormGroupPlainText
								label={t('employees.endDate')}
								value={currentCycle?.endDate.format('L')}
							/>
							<FormGroupPlainText label={t('employees.hoursCompleted')} value={currentCycle?.points} />

							<div className="form-group mb-2">
								<label
									className="text-secondary mb-n2 font-size-small"
									htmlFor={t('employees.practical')}
								>
									{t('employees.practical')}
								</label>
								{currentCycle?.practice
									? (
										<div className="mt-1"><span
											className="glyphicons glyphicons-square-empty-check text-success"
										/>
										</div>
									)
									: (
										<div className="mt-1"><span
											className="glyphicons glyphicons-square-empty-remove text-danger"
										/>
										</div>
									)}
							</div>
						</>
					)}

					<br />

					<Button
						className="mb-3"
						variant="outline-danger"
						onClick={checkArchivingRequirements}
					>{isArchived ? t('employees.activate') : t('employees.archive')}
					</Button>
					<ConfirmModal
						show={showConfirmArchive}
						setShow={confirmArchive}
						title={isArchived ? t('employees.activate') : t('employees.archive')}
						message={isArchived ? t('employees.activate_employee_confirm') : t('employees.archive_employee_confirm')}
						handleSubmit={toggleArchive}
					/>
				</div>
				<div className="col-8 col-sm-10">
					{isPreferenceOptionEnabled(CODE_95_ENABLED) && employee?.code95Student && (
						<div className="student-code95-card offset-2 col-4 mb-5">
							<Code95RiskCard
								currentCycle={currentCycle}
								latestCycle={latestCycle}
								handleClick={() => handleOpenModal(() => setShowCode95Modal(true))}
							/>
						</div>
					)}

					<div className="row pb-5">
						<div className="col-sm-12 col-xl-10 mx-auto">
							<Tab.Container
								id="employee-overview-tabs"
								activeKey={key}
								onSelect={k => handleKeyChange(k)}
							>
								<TabsHeader
									options={{ className: 'mb-3', i18nPrefix: 'employees.employeeDetailsTabs' }}
									tabKey={key}
									tabs={tabs}
								/>
								<Tab.Content>
									<Tab.Pane eventKey={ENROLLMENTS_TAB}>
										<StudentEnrollmentTable
											data={enrollments}
											columns={headers}
											renderHeaderRight={enrollmentsTableRightHeader}
											handleClick={({ enrollment }) => {
												setSelectedEnrollment(enrollment);
												setShowTrainingModal(true);
											}}
										/>
									</Tab.Pane>
									<Tab.Pane eventKey={DOCUMENTS_TAB}>
										<Documents employeeId={employeeId} />
									</Tab.Pane>
									<Tab.Pane eventKey={EDUCATIONS_TAB}>
										<Educations
											employee={employee}
											fetchEmployee={fetchEmployee}
											putEmployee={putEmployee}
										/>
									</Tab.Pane>
								</Tab.Content>
							</Tab.Container>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default EmployeeDetailPage;

