/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import SortComponent from './SortComponent';

function TableHeader({ headerGroups, borderBottom, customFilters = false }) {
	const borderStyle = borderBottom ? { borderBottom: '2px solid lightgrey' } : {};
	return (
		<thead className="bg-transparent">
			{headerGroups.map((headerGroup, key) => (
				<tr className="bg-transparent" {...headerGroup.getHeaderGroupProps()} style={borderStyle} key={key}>
					{headerGroup.headers.map((column, key2) => (
						<td key={key2}>
							<div className="d-flex justify-content-between">
								<div {...column.getHeaderProps(column.getSortByToggleProps({ title: '' }))}>
									{column.render('Header')}
									<SortComponent isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
								</div>
								{customFilters && column?.canFilter ? <div className="mb-0">{column.render('Filter')}</div> : null}
							</div>

						</td>
					))}
				</tr>
			))}
		</thead>
	);
}

TableHeader.propTypes = {
	headerGroups: PropTypes.array.isRequired,
	borderBottom: PropTypes.bool,
	customFilters: PropTypes.bool,
};

export default TableHeader;
