/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import {
	Navigate, Route, Routes as Switch,
} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import NotFoundPage from '../../pages/NotFoundPage';
import SignUpPage from '../../pages/SignUpPage';
import UsersListPage from '../../pages/Company/UsersListPage';
import { COMPANY_SUPER_USER } from '../../constants/roles';
import EstablishmentListPage from '../../pages/EstablishmentListPage';
import MaintenancePage from '../../pages/MaintenancePage';
import DashboardPage from '../../pages/DashboardPage';
import CourseListPage from '../../pages/CourseListPage';
import EmployeeListPage from '../../pages/Employees/EmployeeListPage';
import PlanningPage from '../../pages/PlanningPage';
import EmployeeDetailPage from '../../pages/Employees/EmployeeDetailPage';
import LogbookPage from '../../pages/LogbookPage';
import LogbookDetailPage from '../../pages/LogbookDetailPage';
import SettingsPage from '../../pages/SettingsPage';
import CertificatePage from '../../pages/CertificatePage';
import EditUserPage from '../../pages/EditUserPage';

function CompanyUserRouter({ role, handleSignOut }) {
	return (
		<Switch>
			<Route exact path={ROUTES.LOGIN} element={<Navigate to={ROUTES.DASHBOARD} />} />
			<Route exact path={ROUTES.LANDING} element={<Navigate to={ROUTES.DASHBOARD} />} />
			<Route exact path={`${ROUTES.ESTABLISHMENTS}`} element={<EstablishmentListPage />} />
			<Route
				exact
				path={ROUTES.resetPasswordRequestPage()}
				Component={() => {
					handleSignOut();
					return <SignUpPage />;
				}}
			/>

			<Route exact path="/notFound" element={<NotFoundPage />} />
			<Route path={ROUTES.MAINTENANCE} element={<MaintenancePage />} />
			{role === COMPANY_SUPER_USER && <Route exact path={ROUTES.USERS} element={<UsersListPage />} />}

			{/* Shared routes */}
			<Route exact path={`${ROUTES.DASHBOARD}`} element={<DashboardPage />} />
			<Route exact path={ROUTES.INVENTORY} element={<CourseListPage />} />
			<Route exact path={ROUTES.EMPLOYEES} element={<EmployeeListPage />} />
			<Route exact path={ROUTES.COURSE_PLANNING} element={<PlanningPage />} />
			<Route exact path={`${ROUTES.EMPLOYEES}/:employeeId`} element={<EmployeeDetailPage />} />
			<Route exact path={`${ROUTES.LOGBOOK}`} element={<LogbookPage />} />
			<Route exaxt path={`${ROUTES.LOGBOOK}/:logbookEntryId`} element={<LogbookDetailPage />} />
			<Route exaxt path={`${ROUTES.SETTINGS}`} element={<SettingsPage />} />
			<Route exact path={ROUTES.CERTIFICATES} element={<CertificatePage />} />
			<Route exact path={ROUTES.EDIT_USER} element={<EditUserPage />} />
		</Switch>
	);
}

export default CompanyUserRouter;
