import React from 'react';

function WarningMessage({ message }) {
	return (
		<div className="d-flex justify-content-around font-size-small alert alert-warning">
			<div className="me-3 align-self-center">
				<span className="glyphicons glyphicons-circle-empty-alert" />
			</div>
			<div className="text-primary-color">
				<div>
					{message}
				</div>
			</div>
		</div>
	);
}

export default WarningMessage;
