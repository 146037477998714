import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { editEmployee } from '../constants/routes';
import useApi from './use-api';
import { getCurrentCode95Cycle } from '../utils/code95';
import { getCCVCodePrefix } from '../utils/training';

const useCheckStudentEnrollments = training => {
	const selectedStudentsRef = useRef();
	const [studentWarnings, setStudentWarnings] = useState([]);
	const [selectedStudents, setSelectedStudents] = useState([]);

	const [{ data: studentData, error: studentError }, fetchStudent] = useApi.get(editEmployee(), {}, { lazy: true, cachePolicy: 'no-cache' });

	useEffect(() => {
		if(training?.ccvCode && selectedStudents.length
			&& selectedStudents.length > selectedStudentsRef?.current?.length
		) {
			// fetches last selected student
			fetchStudent(
				{ expand: ['enrollments'] },
				editEmployee(selectedStudents[selectedStudents.length - 1].value),
			);
		}
		selectedStudentsRef.current = selectedStudents;
	}, [selectedStudents]);

	useEffect(() => {
		if(studentData && !studentError) {
			const currentCycle = getCurrentCode95Cycle(studentData.code95Cycles);
			if(currentCycle) {
				const ccvCodeCurrentCycleEnrollments = studentData.enrollments.filter(
					enrollment => enrollment.training.ccvCode && getCCVCodePrefix(enrollment.training.ccvCode) === getCCVCodePrefix(training.ccvCode)
						&& moment(enrollment.training.start) > moment(currentCycle.startDate),
				);
				if(ccvCodeCurrentCycleEnrollments.length) {
					setStudentWarnings([...studentWarnings, { id: studentData.id, name: studentData.displayName }]);
				}
			}
		}
	}, [studentData]);

	const removeWarning = warningId => {
		const updatedWarnings = studentWarnings.filter(warnings => warnings.id !== warningId);
		setStudentWarnings(updatedWarnings);
	};

	return {
		studentWarnings,
		setStudentWarnings,
		selectedStudents,
		setSelectedStudents,
		removeWarning,
	};
};

export default useCheckStudentEnrollments;
