import PropTypes, { shape } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DateInput from '../../Form/DateInput';

export const isDateRangeValid = (from, until, { minDate } = {}) => moment.isMoment(from)
	&& moment.isMoment(until)
	&& from.isBefore(until)
	&& !(moment.isMoment(minDate) && minDate.isAfter(from));

export default function DateFilters({
	filters,
	minDate,
	handleChange,
}) {
	const { t } = useTranslation();
	return (
		<>
			<p>{t('code95.period')}</p>
			<div className="d-flex flex-row">
				<DateInput
					minDate={minDate}
					prependLabel={t('datepicker.from')}
					onChange={startDate => handleChange('startDate', startDate)}
					value={filters.startDate}
				/>
				<DateInput
					minDate={filters.startDate}
					prependLabel={t('datepicker.until')}
					onChange={endDate => handleChange('endDate', endDate)}
					value={filters.endDate}
				/>
			</div>
		</>
	);
}

DateFilters.propTypes = {
	filters: shape({
		startDate: PropTypes.instanceOf(moment),
		endDate: PropTypes.instanceOf(moment),
	}),
	minDate: PropTypes.instanceOf(moment),
	handleChange: PropTypes.func.isRequired,
};
