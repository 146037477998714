/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import {
	Modal,
	ModalTitle,
	ModalBody,
	ModalFooter,
	Button,
	Accordion, FormLabel, Form,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useApi from '../../hooks/use-api';
import Code95Cycle from '../Code95/Code95Cycle';
import moment from '../../moment';
import { compareDates } from '../../utils/sort';
import * as ROUTES from '../../constants/routes';
import { useToast } from '../../hooks/use-toast';
import { parseErrorMessage } from '../../utils/parseErrors';
import SuccessMessage from '../common/messages/SuccessMessage';

function Code95CycleModal({
	show, setShow, cycles: cyclesProp = [], studentId, handleSubmit, isCode95Student,
}) {
	const toast = useToast();
	const { t } = useTranslation();
	const [cycles, setCycles] = useState(cyclesProp.map(entry => ({
		...entry,
		isNewCycle: false,
	})));
	// It's used to uniquely identify each new cycle
	const [newCyclesCount, setNewCyclesCount] = useState(1);
	const [code95Student, setCode95Student] = useState(isCode95Student);
	const [cycleDataUpdated, setCycleDataUpdated] = useState(false);
	const [, updateEmployee] = useApi.patch(ROUTES.editEmployee(studentId));
	const [isDisabled, setDisabled] = useState(false);
	const [successMessage, setSuccessMessage] = useState(null);

	const getHighestEndDate = () => {
		if(!cycles.length) {
			return moment();
		}

		const latestCycle = cycles.reduce((accumulator, currentValue) => {
			if(!currentValue) {
				return accumulator;
			}

			return moment(accumulator.endDate).isAfter(moment(currentValue.endDate)) ? accumulator : currentValue;
		});

		return moment(latestCycle.endDate).add(1, 'day');
	};

	const addCycle = () => {
		const startDate = getHighestEndDate();
		setCycles([
			...cycles,
			{
				startDate: startDate.format(),
				endDate: startDate.add(5, 'years').subtract('1', 'day').format(),
				theoryHours: 0,
				practiceHours: 0,
				isNewCycle: true,
				newCycleCounter: newCyclesCount,
			},
		]);
		setNewCyclesCount(newCyclesCount + 1);
	};

	const handleClose = () => {
		const code95StudentUpdated = code95Student !== isCode95Student;

		if(code95StudentUpdated) {
			updateEmployee({ code95Student }).then(() => {
				toast.addToast(t('code95.followCode95Updated'));
				handleSubmit();
			}).catch(e => {
				toast.addToast(parseErrorMessage(e), 'error');
			});
		} else if(cycleDataUpdated) {
			handleSubmit();
		}
		setShow(false);
		setCycleDataUpdated(false);
	};

	const onCycleSubmit = newCycle => {
		if(newCycle.newCycleCounter) {
			const existingCycles = cycles.filter(
				currentCycle => currentCycle.newCycleCounter !== newCycle.newCycleCounter,
			);
			setCycles([
				...existingCycles,
				{
					...newCycle,
					isNewCycle: false,
					newCycleCounter: undefined,
				},
			]);
		}
		setCycleDataUpdated(true);
	};

	return (
		<Modal show={show} onHide={handleClose} size="lg">
			<Modal.Header closeButton>
				<ModalTitle>{t('code95.overview_title')}</ModalTitle>
			</Modal.Header>
			<ModalBody className="pt-1">
				<div className="d-flex justify-content-between">
					<div className="d-flex align-items-center">
						<FormLabel className="mb-0">{t('code95.followCode95')}:</FormLabel>
						<div className="d-flex px-3">
							<Form.Check type="radio" inline>
								<Form.Check.Input
									type="radio"
									name="followCode95"
									checked={code95Student}
									onChange={() => setCode95Student(true)}
								/>
								<Form.Check.Label onClick={() => setCode95Student(true)}>
									{t('external.yes')}
								</Form.Check.Label>
							</Form.Check>
							<Form.Check type="radio" inline>
								<Form.Check.Input
									type="radio"
									name="followCode95"
									checked={!code95Student}
									onChange={() => setCode95Student(false)}
								/>
								<Form.Check.Label onClick={() => setCode95Student(false)}>
									{t('external.no')}
								</Form.Check.Label>
							</Form.Check>
						</div>
					</div>
					<div
						className="rounded-circle glyphicons glyphicons-circle-plus text-secondary"
						style={{ cursor: 'pointer', fontSize: '3rem' }}
						onClick={() => addCycle()}
					/>
				</div>
				<SuccessMessage message={successMessage} onClose={() => setSuccessMessage(null)} />
				<Accordion defaultActiveKey={1}>
					{cycles && cycles
						// First b, then a because we sort in reversed order than the function
						.sort((a, b) => compareDates(moment(b.endDate), moment(a.endDate)))
						.map((cycle, key) => (
							<Code95Cycle
								cycle={cycle}
								key={cycle.startDate}
								accordionKey={key + 1}
								studentId={studentId}
								setDisabled={setDisabled}
								onCycleSubmit={onCycleSubmit}
								setSuccessMessage={setSuccessMessage}
							/>
						))}
				</Accordion>
			</ModalBody>
			<ModalFooter>
				<Button variant="outline-secondary" onClick={handleClose} disabled={isDisabled}>
					{t('code95.close')}
				</Button>
			</ModalFooter>
		</Modal>
	);
}

Code95CycleModal.propTypes = {
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
	cycles: PropTypes.array,
	studentId: PropTypes.number.isRequired,
	isCode95Student: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};

export default Code95CycleModal;
