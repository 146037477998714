import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleFormText from './CollapsibleFormText';
import FormGroupPlainText from './FormGroupPlainText';

function FormGroupTextWrapper({ maxCharacterLength = 200, ...props }) {
	return props.value?.length > maxCharacterLength ? <CollapsibleFormText {...props} /> : <FormGroupPlainText {...props} />;
}

FormGroupTextWrapper.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	maxCharacterLength: PropTypes.number,
};
export default FormGroupTextWrapper;
