import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const InfoBar = React.memo(({
	children,
	backTitle = null,
	goBack = null,
	showBackArrow = true,
	showBadge = false,
	badgeTitle = null,
	badgeBg = 'light',
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const backFunction = () => {
		if(typeof goBack === 'function') {
			goBack();
		} else {
			navigate(-1);
		}
	};

	const badgeText = badgeBg === 'light' ? { text: 'dark' } : {};

	return (
		<div className="info-bar row mb-3 align-items-center">
			<div className="col-3">
				{backTitle && (
					<div>
						<span className={`info-bar-back-button ${showBackArrow && 'pointer'}`} title={backTitle} onClick={() => showBackArrow && backFunction()} role="presentation">
							{showBackArrow && <span style={{ fontSize: '1.75rem' }} className="glyphicons glyphicons-arrow-thin-left me-3" />}
							{backTitle}
						</span>
						{showBadge && badgeTitle && <Badge className="ms-3" bg={badgeBg} {...badgeText}>{badgeTitle}</Badge>}
					</div>
				)}
			</div>
			<div className="col-9 d-flex flex-row-reverse">
				<div className="me-3 fw-bold slogan">{t('infobar.slogan')}</div>
				{children}
			</div>
		</div>
	);
});

InfoBar.propTypes = {
	children: PropTypes.node,
	backTitle: PropTypes.string,
	goBack: PropTypes.func,
	showBackArrow: PropTypes.bool,
	badgeTitle: PropTypes.string,
	showBadge: PropTypes.bool,
	badgeBg: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']),
};
export default InfoBar;
