import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useApi from '../../hooks/use-api';
import { getCompanyStudents, getEstablishmentsForCompany } from '../../constants/routes';
import { useDationUser } from '../../hooks/dation-user';
import ChipInput from '../ChipInput/ChipInput';
import { getDisplayName } from '../../utils/person';
import Tooltip, { infoIcon } from '../common/Tooltip';
import EstablishmentSelect from '../common/EstablishmentSelect';
import { CODE_95_ENABLED, useViewPreferences } from '../../hooks/view-preferences';

function StudentsSelection({
	training,
	optional = false,
	selected,
	setSelected,
	maxSelected,
	message,
	setMessage,
	disabled,
	show,
}) {
	const { t } = useTranslation();
	const { getCurrentCompanyId } = useDationUser();
	const { isPreferenceOptionEnabled } = useViewPreferences();

	const [options, setOptions] = useState([]);
	const [establishmentId, setEstablishmentId] = useState(0);

	const [{ data: students }, fetchStudents] = useApi.get(getCompanyStudents(getCurrentCompanyId()), { archived: false }, { lazy: true });
	const [{ data: establishmentData }] = useApi.get(getEstablishmentsForCompany(getCurrentCompanyId()));

	const code95Enabled = isPreferenceOptionEnabled(CODE_95_ENABLED);

	useEffect(() => {
		if(show) {
			fetchStudents();
		}
	}, [show]);

	useEffect(() => {
		if(students && training !== null) {
			// filter out students that are already enrolled on the training
			let filteredStudents = students
				.filter(student => !training?.enrollments.some(enrollment => enrollment.student.id === student.id));

			if(establishmentId) {
				filteredStudents = filteredStudents.filter(({ establishment }) => establishment?.id === establishmentId);
			}
			setOptions(filteredStudents.map(student => ({
				value: student.id,
				customFields: {
					ccv: student.code95Student,
					soob: student.employeeNumber !== '' && student.employeeNumber !== null,
				},
				label: getDisplayName(student),
			})));
		}
	}, [students, training, establishmentId]);

	const studentCustomFields = ({ editableOption, setEditableOption, disabled: customFieldDisabled }) => {
		const handleOptionChange = ({ target }) => setEditableOption({
			...editableOption,
			customFields: {
				...editableOption.customFields,
				[target.name]: target.checked,
			},
		});

		const { customFields: { ccv, soob } } = editableOption;
		return (
			<>
				{code95Enabled && (
					<div style={{ width: 125 }} className="text-center">
						<label className="px-2 m-0" htmlFor="ccv">
							<input
								defaultChecked={ccv}
								onChange={e => handleOptionChange(e)}
								value={ccv}
								type="checkbox"
								name="ccv"
								disabled={customFieldDisabled}
							/>
						</label>
					</div>
				)}
				<div style={{ width: 70 }} className="text-center">
					<label className="px-2 m-0" htmlFor="soob">
						<input
							defaultChecked={soob}
							onChange={e => handleOptionChange(e)}
							value={soob}
							type="checkbox"
							name="soob"
							disabled={customFieldDisabled}
						/>
					</label>
				</div>
			</>
		);
	};

	const code95Content = () => (
		<>
			{t('enrollments.cbr_website_tooltip')}
			&nbsp;
			<a
				href="https://www.cbr.nl/nl/beroepsexamens/code-95/nl.htm"
				target="_blank"
				rel="noreferrer noopener"
				className="text-primary"
			>
				{t('enrollments.cbr_website')}
			</a>
		</>
	);

	const soobContent = () => (
		<>
			<strong>{t('tooltip.attention')}</strong>
			&nbsp;
			{t('enrollments.soob_number_tooltip')}
			&nbsp;
			<a href="https://www.soobsubsidiepunt.nl" target="_blank" rel="noreferrer noopener" className="text-primary">
				{t('enrollments.soob_number_website')}
			</a>
		</>
	);

	const fieldsHeader = () => (
		<>
			{code95Enabled && (
				<div style={{ width: 125 }} className="text-center text-secondary">
					<div className="d-inline">{t('code95.ccv')}</div>
					<Tooltip renderPopoverContent={code95Content}>
						{infoIcon()}
					</Tooltip>
				</div>
			)}
			<div style={{ width: 70 }} className="text-center text-secondary">
				<dt className="d-inline">{t('employees.soob')}</dt>
				<Tooltip renderPopoverContent={soobContent}>
					{infoIcon()}
				</Tooltip>
			</div>
		</>
	);

	return (
		<>
			<div className="form-group row">
				<div className="col-12">
					{establishmentData?.length > 0 && (
						<dl className="mt-3">
							<EstablishmentSelect
								establishmentId={establishmentId}
								setEstablishmentId={setEstablishmentId}
							/>
						</dl>
					)}
				</div>
			</div>
			<dl className="mt-3">
				<dt className="mb-1 text-primary-color">{t('trainings.select_employees', { optional: optional ? t('trainings.optional') : null })}</dt>
				<ChipInput
					options={options}
					setSelected={selectedOptions => setSelected(selectedOptions)}
					selected={selected}
					title={t('trainings.selected_employees')}
					disabled={maxSelected <= selected.length || disabled}
					disabledChipIcon={disabled}
					customFields={studentCustomFields}
					renderFieldsHeader={fieldsHeader}
				/>
			</dl>
			<dl className="mt-3">
				<dt className="mb-1 text-primary-color">{t('modalFields.message')}</dt>
				<textarea
					id="message"
					rows="3"
					className="form-control"
					value={message ?? ''}
					onChange={({ target }) => setMessage(target.value)}
					disabled={disabled}
				/>
			</dl>
		</>
	);
}

StudentsSelection.propTypes = {
	training: PropTypes.object,
	optional: PropTypes.bool,
	selected: PropTypes.array,
	setSelected: PropTypes.func,
	maxSelected: PropTypes.number,
	message: PropTypes.string,
	setMessage: PropTypes.func,
	disabled: PropTypes.bool,
	show: PropTypes.bool,
};

export default StudentsSelection;
