import React, { useEffect, useState } from 'react';
import {
	Button, Card, Col, Form, FormControl, FormLabel, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useMergeState from '../../hooks/use-merge-state';
import { parseErrorMessage } from '../../utils/parseErrors';
import useApi from '../../hooks/use-api';
import { dationInstanceUserEndpoint, updateDationInstanceUser } from '../../constants/routes';
import { sendGoogleAnalyticsEvent } from '../../utils/googleAnalytics';
import { useToast } from '../../hooks/use-toast';
import { SCHOOL_SUPER_USER, SCHOOL_ADMIN } from '../../constants/roles';
import { getDisplayName } from '../../utils/person';
import UserRoleCheckboxInput from '../Form/UserRoleCheckboxInput';
import { useDationUser } from '../../hooks/dation-user';
import ErrorMessage from '../common/messages/ErrorMessage';

const EditDationInstanceUser = React.memo(({ dationInstanceId, dationInstanceUser }) => {
	const { t } = useTranslation();
	const toast = useToast();
	const dationUser = useDationUser();
	const navigate = useNavigate();

	const initialState = {
		id: null,
		userRole: null,
		emailAddress: undefined,
		firstName: undefined,
		insertion: undefined,
		lastName: undefined,
		dationInstance: { id: dationInstanceId },
	};

	const [error, setErrorMessage] = useState(null);
	const [isSubmitting, setSubmitting] = useState(false);
	const [user, setUser] = useMergeState(initialState);

	const [, postDationInstanceUser] = useApi.post(dationInstanceUserEndpoint());
	const [, updateDationInstanceUserRequest] = useApi.patch(updateDationInstanceUser(user.id));
	const [validated, setValidated] = useState(false);
	const [showRoleFeedback, setShowRoleFeedback] = useState(false);
	const userType = t('users.type.dation_instance_user');

	useEffect(() => {
		if(dationInstanceUser !== null) {
			const { roles = [] } = dationInstanceUser || {};
			setUser({
				...dationInstanceUser,
				userRole: roles.length > 0 ? roles[0] : null,
			});
		} else {
			setUser(initialState);
		}
	}, [dationInstanceUser]);

	const close = () => {
		setErrorMessage(null);
		setValidated(false);
		setUser(initialState);
	};

	const addDationInstanceUser = () => {
		const userName = getDisplayName(user || {});
		const userData = {
			...user,
			roles: [user.userRole],
		};
		delete userData.userRole;
		let submitUser;
		let successMessage;
		const eventOptions = {
			hitType: 'event',
			eventCategory: 'DationInstanceUser',
		};

		if(user.id) {
			submitUser = updateDationInstanceUserRequest;
			successMessage = t('users.edited', { userType, name: userName });
			eventOptions.eventAction = 'Edit';
		} else {
			eventOptions.eventAction = 'Create';
			submitUser = postDationInstanceUser;
			successMessage = t('users.created', { userType, name: userName });
		}
		setSubmitting(true);
		submitUser(userData)
			.then(() => {
				sendGoogleAnalyticsEvent(eventOptions);
				close();
				setSubmitting(false);
				toast.addToast(successMessage);
				navigate(-1);
			})
			.catch(e => {
				setSubmitting(false);
				setErrorMessage(parseErrorMessage(e));
			});
	};

	useEffect(() => {
		user.userRole && setShowRoleFeedback(false);
	}, [user.userRole]);

	const validateForm = event => {
		event.stopPropagation();
		event.preventDefault();
		if(event.currentTarget.checkValidity() === false) {
			user.userRole === null && setShowRoleFeedback(true);
			setValidated(true);
			setSubmitting(false);
		} else {
			addDationInstanceUser();
		}
	};

	const handleChange = ({ target }) => {
		const { name, value } = target;
		setUser({ [name]: value });
	};
	const { user: { emailAddress = null } } = dationUser || {};
	const isCurrentUser = user.emailAddress === emailAddress;
	return (
		<>
			<ErrorMessage message={error} />
			<Form noValidate validated={validated} onSubmit={validateForm}>
				<Card className="col-12 my-3">
					<Card.Title className="m-3">Algemene gegevens</Card.Title>
					<Card.Body className="col-xs-6">
						<Row className="mb-3">
							<Col xs={4}>
								<FormLabel>{t('user_info.firstName')}</FormLabel>
								<FormControl
									id="dation-user-first-name"
									name="firstName"
									type="text"
									value={user.firstName ?? ''}
									onChange={handleChange}
								/>
							</Col>
							<Col xs={4}>
								<FormLabel>{t('user_info.insertion')}</FormLabel>
								<FormControl
									id="dation-user-insertion"
									name="insertion"
									type="text"
									value={user.insertion ?? ''}
									onChange={handleChange}
								/>
							</Col>
							<Col xs={4}>
								<FormLabel>{t('user_info.lastName')}</FormLabel>
								<FormControl
									id="dation-user-last-name"
									name="lastName"
									type="text"
									value={user.lastName ?? ''}
									onChange={handleChange}
								/>
								<Form.Control.Feedback type="invalid">
									{t('add_employee.field_required')}
								</Form.Control.Feedback>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col xs={6}>
								<FormLabel>{t('portal.email_placeholder')}</FormLabel>
								<Form.Control
									id="dation-user-email"
									name="emailAddress"
									type="email"
									required
									autoFocus
									value={user.emailAddress ?? ''}
									onChange={handleChange}
									disabled={user.id}
								/>
								<Form.Control.Feedback id="invalid-feedback" type="invalid">
									{t('portal.email_not_valid')}
								</Form.Control.Feedback>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col xs={6}>
								<FormLabel>{t('users.role')}</FormLabel>
								<div>
									<UserRoleCheckboxInput
										label={t(`users.roles.${SCHOOL_SUPER_USER.toLowerCase()}`)}
										role={SCHOOL_SUPER_USER}
										userRole={user.userRole}
										onChange={handleChange}
										disabled={isCurrentUser}
										required
									/>
									<UserRoleCheckboxInput
										label={t(`users.roles.${SCHOOL_ADMIN.toLowerCase()}`)}
										role={SCHOOL_ADMIN}
										userRole={user.userRole}
										onChange={handleChange}
										disabled={isCurrentUser}
									/>
									{showRoleFeedback && (
										<div id="role-invalid-feedback" className="invalid-feedback d-block">
											{t('users.role_required', { userType })}
										</div>
									)}
								</div>
							</Col>
						</Row>
					</Card.Body>
				</Card>
				<div className="text-end pb-3">
					<Button
						id="confirm-dation-user-modal"
						variant="primary"
						type="submit"
						disabled={isSubmitting}
					>{user.id ? t('modalActions.edit') : t('modalActions.add')}
					</Button>
				</div>
			</Form>
		</>
	);
});

export default EditDationInstanceUser;
