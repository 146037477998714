import React from 'react';
import Message from './Message';

function ErrorMessage(props) {
	return <Message variant="danger" {...props} />;
}

ErrorMessage.propTypes = Message.propTypes;

export default ErrorMessage;
