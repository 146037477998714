import React, { useState } from 'react';
import { Toast, ToastHeader } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useToast } from './index';
import { NOTIFY_TOAST_HIDE_DELAY } from '../../constants/componentDefaults';

function CustomToast({ message, variant = 'success', delay = NOTIFY_TOAST_HIDE_DELAY }) {
	const [show, setShow] = useState(true);
	const toast = useToast();
	return (
		<Toast
			onClose={() => {
				setShow(false);
				toast.setToasts([]);
			}}
			show={show}
			delay={delay}
			className={`toast-${variant}`}
			autohide
		>
			<ToastHeader className="d-flex justify-content-between" closeButton>{message}</ToastHeader>
		</Toast>
	);
}

CustomToast.propTypes = {
	message: PropTypes.string,
	delay: PropTypes.number,
	variant: PropTypes.oneOf(['success', 'error']),
};

export default CustomToast;
