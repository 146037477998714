/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import StudentsOverview from '../StudentsOverview';

export const ARCHIVED_STUDENTS_TAB = 'archived';

function ArchivedStudentsOverviewTab({ students, establishmentData }) {
	const { t } = useTranslation();

	const extraHeaders = React.useMemo(() => [
		{
			Header: t('employees.establishment'),
			accessor: 'establishment.name',
			disableFilters: true,
		},
	], []);

	return (
		<StudentsOverview
			students={students}
			extraColumnHeaders={extraHeaders}
			establishmentData={establishmentData}
		/>
	);
}

export default ArchivedStudentsOverviewTab;
