/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TypeAhead from 'react-typeahead';
import GlyphIconChip from './GlyphIconChip';
import ChipInputList from './ChipInputList';

function ChipInput({
	selected = [],
	setSelected,
	options,
	title,
	disabled = false,
	disabledChipIcon = false,
	customFields = null,
	renderFieldsHeader = null,
}) {
	const { t } = useTranslation();
	const typeAheadRef = useRef(null);
	const viewableOptions = options.filter(option => !selected.some(select => select.value === option.value));

	const onOptionSelected = option => {
		// clear input on select
		typeAheadRef.current.setEntryText('');
		typeAheadRef.current.setState({ isFocused: false });
		setSelected([
			...selected,
			option,
		]);
	};

	const onOptionUpdated = updatedOption => setSelected(selected.map(option => (option.value !== updatedOption.value ? option : updatedOption)));

	const onOptionRemoved = option => setSelected(selected.filter(select => select.value !== option.value));

	const filterOption = (inputValue, option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;

	return (
		<>
			<div
				className="d-flex flex-row flex-wrap justify-content-start rounded chipInputField"
				onClick={() => typeAheadRef.current.focus()}
			>
				<TypeAhead.Typeahead
					ref={typeAheadRef}
					options={viewableOptions}
					customClasses={{
						input: 'chipTextInput form-control',
						results: 'list-group-item',
						listItem: 'listItem',
					}}
					filterOption={filterOption}
					displayOption="label"
					onOptionSelected={option => onOptionSelected(option)}
					customListComponent={ChipInputList}
					showOptionsWhenEmpty
					className="flex-fill chip-input"
					inputProps={{ autoComplete: 'nofill', disabled }}
				/>
			</div>
			{selected.length > 0 && (
				<div className="mt-3">
					<h6>{title}</h6>
					<dl className="row mx-1 mb-0">
						<dt className="col-6 text-secondary">{t('employees.name')}</dt>
						<div className="col-6 d-flex justify-content-between align-items-center">
							{renderFieldsHeader && renderFieldsHeader()}
							<dt style={{ width: 70 }} className="text-center" />
						</div>
					</dl>
					<div className="mb-3 me-3">
						{selected.map(select => <GlyphIconChip disabled={disabledChipIcon} option={select} key={select.value} renderCustomFields={customFields} updateChip={option => onOptionUpdated(option)} removeChip={(e, option) => onOptionRemoved(option)} />)}
					</div>
				</div>
			)}

		</>
	);
}

export default ChipInput;
