import React from 'react';
import moment from 'moment';
import { useDationUser } from '../../hooks/dation-user';

function Greetings() {
	const dationUser = useDationUser();

	const currentHour = parseInt(moment().format('HH'), 10);
	let greeting = 'Goedemorgen';

	if(currentHour >= 18) {
		greeting = 'Goedeavond';
	} else if(currentHour >= 12) {
		greeting = 'Goedemiddag';
	}

	const { getUserName } = dationUser || {};

	return <h3 id="greetings">{greeting} {getUserName()}</h3>;
}

export default Greetings;
