/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import InfoBar from '../../components/Navigation/InfoBar';
import useApi from '../../hooks/use-api';
import {
	dationInstanceUserList,
	getCompanyContactPersons,
	inviteDationInstanceUserToPortal,
	inviteContactPersonToPortal,
	updateDationInstanceUser,
	deleteCompanyContactPerson,
	getSendInviteUrl, EDIT_USER,
} from '../../constants/routes';
import { useDationUser } from '../../hooks/dation-user';
import UsersList from '../UsersList';
import * as ROUTES from '../../constants/routes';

function UsersListPage() {
	const { t } = useTranslation();
	const dationUser = useDationUser();
	const navigate = useNavigate();
	const {
		user: dationUserData, isCompanyContactPersonUser,
	} = dationUser || {};
	const { dationInstance } = dationUserData || {};

	const [users, setUsers] = useState({
		data: null,
		company: {},
		dationInstance: {},
	});

	const [{ data: dationInstanceUsers }, fetchDationInstanceUsers] = useApi.get(dationInstanceUserList(dationInstance?.id), {}, { lazy: true });
	const [{ data: contactPersonUsers }, fetchContactPersonUsers] = useApi.get(getCompanyContactPersons(dationUser.companyId), {}, { lazy: true });

	useEffect(() => {
		const fetchUsers = isCompanyContactPersonUser() ? fetchContactPersonUsers : fetchDationInstanceUsers;
		fetchUsers();
	}, [dationUser]);

	useEffect(() => {
		if(contactPersonUsers && contactPersonUsers.length > 0) {
			setUsers({
				data: contactPersonUsers,
				fetchData: fetchContactPersonUsers,
				inviteEndpoint: inviteContactPersonToPortal,
				newInviteEndpoint: getSendInviteUrl,
				deleteEndpoint: deleteCompanyContactPerson,
				company: {
					id: dationUser.companyId,
				},
			});
		}
	}, [contactPersonUsers]);

	useEffect(() => {
		if(dationInstanceUsers && dationInstanceUsers.length > 0) {
			setUsers({
				data: dationInstanceUsers,
				fetchData: fetchDationInstanceUsers,
				inviteEndpoint: inviteDationInstanceUserToPortal,
				deleteEndpoint: updateDationInstanceUser,
				dationInstance,
			});
		}
	}, [dationInstanceUsers]);

	return (
		<>
			<InfoBar
				backTitle={isCompanyContactPersonUser() ? t('add_employee.contact_persons') : t('users.title')}
				goBack={() => navigate(ROUTES.SETTINGS)}
			/>
			{users.data === null
				? <div className="loading-spinner" />
				: (
					<>
						<div className="d-flex flex-row-reverse">
							<Button
								variant="outline-secondary"
								className="rounded-button p-1"
								type="button"
								onClick={() => navigate(
									EDIT_USER,
									{
										state: {
											userType: isCompanyContactPersonUser() ? 'contact_person' : 'dation_instance_user',
											original: {},
											company: { id: dationUser.companyId },
											dationInstance: { id: dationInstance?.id },
										},
									},
								)}
							>
								<span className="glyphicons glyphicons-user-plus" />
							</Button>
						</div>
						<UsersList
							listType={isCompanyContactPersonUser() ? 'contact_person' : 'dation_instance_user'}
							className="col-12 col-xl-10 mx-auto pt-n5"
							users={users.data}
							company={isCompanyContactPersonUser() ? dationUser.getCurrentCompany() : null}
							handleSubmit={users.fetchData}
							inviteEndpoint={users.inviteEndpoint}
							newInviteEndpoint={users.newInviteEndpoint}
							deleteEndpoint={users.deleteEndpoint}
							showFilter
							showFooter
						/>
					</>
				)}
		</>
	);
}

export default UsersListPage;
