import React, { useEffect } from 'react';
import {
	useGlobalFilter, usePagination, useSortBy, useTable,
} from 'react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useUserPreferences from '../../hooks/use-user-preferences';
import { CERTIFICATE_TABLE } from '../../constants/tables';
import TableHeader from './Default/TableHeader';
import TableFooter from './Default/TableFooter';
import * as ROUTES from '../../constants/routes';
import XLSXDownload from '../common/XLSXDownload';
import { sortByDate } from '../../utils/date';
import { getCertificationValues } from '../StudentRisk/CertificationRiskBadge';
import TableGlobalFilter from './Default/TableGlobalFilter';

export default function CertificateTable({ columns, data, isLoading }) {
	const {
		getPageIndex, setPageIndex, getPageSize, getFilters, setFilters,
	} = useUserPreferences();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const tableName = CERTIFICATE_TABLE;

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state: { pageIndex, pageSize, globalFilter },
		page,
		previousPage,
		canPreviousPage,
		nextPage,
		canNextPage,
		gotoPage,
		pageOptions,
		setPageSize,
		setGlobalFilter,
	} = useTable({
		columns,
		data,
		initialState: {
			pageIndex: getPageIndex(tableName),
			pageSize: getPageSize(tableName),
			globalFilter: getFilters(tableName)?.value || '',
			sortBy: [
				{
					id: 'certificateExpirationDate',
					desc: false,
				},
			],
		},
	}, useGlobalFilter, useSortBy, usePagination);

	useEffect(() => {
		setPageIndex(tableName, pageIndex);
	}, [pageIndex]);

	useEffect(() => {
		setFilters(tableName, { value: globalFilter });
		gotoPage(0);
	}, [globalFilter]);

	const prepareXlSX = () => {
		const sortedCertificates = data.sort((a, b) => sortByDate(a.sortDate, b.sortDate, { order: 'asc' }));
		const mappedCertificates = sortedCertificates.map(certificateData => ({
			[t('certificates.table.name')]: certificateData.studentName,
			[t('certificates.table.certificate')]: certificateData.trainingName,
			[t('certificates.table.expiryDate')]: certificateData.certificateExpirationDate,
			[t('certificates.table.certificateRisk')]: getCertificationValues(certificateData.risk, t)?.value,
		}));
		return [{
			name: 'Certificaten overzicht',
			rows: mappedCertificates,
		}];
	};

	return (
		<div className="col-12 mx-auto pt-n5">
			<div className="table-responsive overflow-hidden">
				{isLoading && <div className="loading-spinner" />}
				<div className="text-end">
					<TableGlobalFilter setGlobalFilter={setGlobalFilter} globalFilter={globalFilter} />
				</div>
				<table {...getTableProps()} className="table table-large table-hover">
					<TableHeader headerGroups={headerGroups} />
					<tbody {...getTableBodyProps()}>
						{page.length === 0 && (
							<tr>
								<td colSpan={4} className="text-center">{t('certificates.table.emptyTableMessage')}</td>
							</tr>
						)}
						{page.map(row => {
							prepareRow(row);
							const { original: { studentId } } = row;

							return (
								<tr
									onClick={() => navigate(ROUTES.employeeDetailPage(studentId))}
									className="cursor-pointer"
									{...row.getRowProps()}
								>
									{row.cells.map(cell => (
										<td
											className="text-primary-color"
											{...cell.getCellProps()}
										>
											{cell.render('Cell')}
										</td>
									))}
								</tr>
							);
						})}
					</tbody>
				</table>
				<TableFooter
					tableName={tableName}
					previousPage={previousPage}
					canPreviousPage={canPreviousPage}
					nextPage={nextPage}
					canNextPage={canNextPage}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
					gotoPage={gotoPage}
					pageSize={pageSize}
					setPageSize={setPageSize}
					pageSizeOptions={[10, 50]}
					totalItems={data.length}
					exportButton={(
						<XLSXDownload
							className="me-3"
							getData={() => prepareXlSX()}
							fileName={`${t('certificates.table.exportedFile')}.xlsx`}
							title={t('table.export')}
							icon
						/>
					)}
				/>
			</div>
		</div>
	);
}
