/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from '../../moment';
import { compareTableDates } from '../../utils/sort';
import { getCurrentCode95Cycle } from '../../utils/code95';
import StudentRisk, { isCycleRisky } from '../StudentRisk/StudentRisk';
import DashboardTable from './DashboardTable';
import CcvImage from '../../assets/images/Code95V3.svg';
import { DEFAULT_RISKY_EXPIRATION_PERIOD_IN_DAYS, getDateFormat } from '../../utils/date';

function Code95Cycle({ students }) {
	const { t } = useTranslation();

	const risk = React.useCallback(({ value }) => (
		value ? <StudentRisk amount={value.practiceHours + value.theoryHours} endDate={moment(value.endDate)} /> : t('dashboard.no_current_cycle')
	), []);
	const headers = React.useMemo(() => [
		{
			Header: t('dashboard.name'),
			accessor: 'displayName',
		},
		{
			Header: t('dashboard.code_95_cycle'),
			accessor: 'currentCycle',
			Cell: risk,
		},
		{
			Header: t('dashboard.expiry_date'),
			accessor: 'endDate',
			Cell: ({ value }) => (value ? getDateFormat(moment(value)) : ''),
			sortType: (a, b) => compareTableDates('endDate', a, b),
		},
	], [t]);

	const filteredStudents = React.useMemo(() => {
		if(students === null) {
			return [];
		}
		return students.filter(student => {
			if(!student.code95Student) {
				return false;
			}
			const currentCycle = getCurrentCode95Cycle(student.code95Cycles);
			if(!currentCycle) {
				return true;
			}
			const { practiceHours, theoryHours, endDate } = currentCycle;
			const date = moment(endDate);
			const duration = moment.duration(moment().diff(date));
			return isCycleRisky(practiceHours + theoryHours, date) && duration.as('days') > -DEFAULT_RISKY_EXPIRATION_PERIOD_IN_DAYS;
		}).map(student => {
			const currentCycle = getCurrentCode95Cycle(student.code95Cycles);
			return {
				displayName: student.displayName,
				currentCycle,
				endDate: currentCycle?.endDate,
				id: student.id,
			};
		});
	}, [students]);

	return (
		<DashboardTable
			cardHeader={<><img style={{ height: 45 }} src={CcvImage} alt={t('code95.code95')} /><span className="m-2">{t('dashboard.cycle_expires')}</span></>}
			sortBy={[
				{
					id: 'endDate',
					desc: false,
				},
			]}
			columns={headers}
			data={filteredStudents}
		/>
	);
}

export default Code95Cycle;
