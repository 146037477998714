import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import * as ROLES from '../../constants/roles';
import DationInstanceUserRouter from './DationInstanceUserRouter';
import CompanyUserRouter from './CompanyUserRouter';
import AdminRouter from './AdminRouter';
import * as ROUTES from '../../constants/routes';
import MaintenancePage from '../../pages/MaintenancePage';

const getRouter = (role, props) => {
	switch (role) {
	case ROLES.SCHOOL_ADMIN:
	case ROLES.SCHOOL_SUPER_USER:
		return DationInstanceUserRouter({ role, ...props });
	case ROLES.COMPANY_SUPER_USER:
	case ROLES.COMPANY_ADMIN:
		return CompanyUserRouter({ role, ...props });
	case ROLES.ADMIN:
		return AdminRouter(props);
	default:
		// Return the maintenance page because we don't know the role when it is enabled
		return (
			<Switch>
				<Route exact path={ROUTES.MAINTENANCE} element={<MaintenancePage />} />
			</Switch>
		);
	}
};

export default getRouter;
