/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import StudentsOverview from '../StudentsOverview';

// These must match with the tab translation key
export const OTHERS_STUDENTS_TAB = 'otherStudents';

function NonCode95StudentsOverviewTab({ students, establishmentData }) {
	return (
		<StudentsOverview
			students={students}
			establishmentData={establishmentData}
		/>
	);
}

export default NonCode95StudentsOverviewTab;
