import React from 'react';
import { Badge } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { MAXIMUM_COMBINED_HOURS_PER_CYCLE } from '../../utils/code95';

export const isCycleRisky = (amount, endDate) => {
	const duration = moment.duration(endDate.diff(moment()));

	const yearsUntilEnd = duration.as('years');

	const riskyYearLimit = 4.5 - parseInt((amount / 7), 10);

	if(amount >= MAXIMUM_COMBINED_HOURS_PER_CYCLE) {
		return false;
	}
	return yearsUntilEnd < riskyYearLimit;
};

// Generate component to indicate the amount of points in a cycle, and the risk based on amount of points / moment of time in cycle
function StudentRisk({ amount, endDate = moment() }) {
	const badgeBg = isCycleRisky(amount, endDate) ? 'danger' : 'success';
	const getStyle = index => ({
		flexGrow: 1,
		...((amount < (index * 7)) && { opacity: 0.3 }),
	});

	return (
		<div
			className="w-100"
			style={{
				display: 'flex',
				maxWidth: 350,
			}}
		>
			<Badge bg={badgeBg} style={getStyle(1)} className="badge-rounded-left">&nbsp;</Badge>
			<Badge bg={badgeBg} style={getStyle(2)} className="ms-1 badge-not-rounded">&nbsp;</Badge>
			<Badge bg={badgeBg} style={getStyle(3)} className="ms-1 badge-not-rounded">&nbsp;</Badge>
			<Badge bg={badgeBg} style={getStyle(4)} className="ms-1 badge-not-rounded">&nbsp;</Badge>
			<Badge bg={badgeBg} style={getStyle(5)} className="ms-1 badge-rounded-right">&nbsp;</Badge>
		</div>

	);
}

StudentRisk.propTypes = {
	amount: PropTypes.number.isRequired,
	endDate: PropTypes.instanceOf(moment),
};
export default StudentRisk;
