// For more mime types check https://developer.mozilla.org/es/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
// eslint-disable-next-line import/prefer-default-export
export const SPREADSHEET_FILE_FORMAT = {
	regex: 'spreadsheetml.sheet|.sheet|csv|ms-excel',
	inputExtensionRegex: 'xlsx|csv',
	extension: 'csv',
};
export const DEFAULT_SUPPORTED_FILE_FORMATS = [
	SPREADSHEET_FILE_FORMAT,
	{
		regex: 'pdf',
		extension: 'pdf',
	},
	{
		regex: 'ms-powerpoint|presentation',
		extension: 'ptt',
	},
	{
		regex: 'pdf',
		extension: 'pdf',
	},
	{
		regex: 'msword|docx|wordprocessingml.document',
		inputExtensionRegex: 'docx|doc',
		extension: 'doc',
	},
	{
		regex: 'aac',
		extension: 'aac',
	},
	{
		regex: 'x-rar-compressed',
		extension: 'rar',
	},
	{
		regex: 'x-7z-compressed',
		extension: 'zip',
	},
	{
		regex: 'jpeg',
		extension: 'jpeg',
	},
	{
		regex: 'jpg',
		extension: 'jpg',
	},
	{
		regex: 'png',
		extension: 'png',
	},
];

const toBase64 = _file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(_file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = err => reject(err);
});

export const getBase64File = async file => {
	if(file) {
		const exp = 'base64,';
		const rawFile = await toBase64(file);
		const { index } = rawFile.match(exp);

		// formatted base64
		return rawFile.slice(index + exp.length, rawFile.length);
	}
	return null;
};

export const getFileExtension = fileType => {
	let extension = '';
	DEFAULT_SUPPORTED_FILE_FORMATS.forEach(validFormat => {
		if(fileType.match(validFormat.regex)) {
			extension = `.${validFormat.extension}`;
		}
	});
	return extension;
};

export const getFileNameExtensionMatch = fileName => {
	let extension = '';
	DEFAULT_SUPPORTED_FILE_FORMATS.forEach(validFormat => {
		const regExp = validFormat.inputExtensionRegex || validFormat.extension;
		const match = fileName.match(regExp);
		const [extensionMatch] = match || [];

		if(match?.length && extensionMatch !== '') {
			extension = `.${extensionMatch}`;
		}
	});
	return extension;
};
