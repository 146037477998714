/* eslint-disable jsx-a11y/no-static-element-interactions,no-mixed-spaces-and-tabs */
import React, {
	useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { getDisplayName } from '../utils/person';
import InviteUserModal from '../components/Modal/InviteUserModal';
import UsersTable from '../components/Tables/UsersTable';
import { transformUser } from '../utils/users';
import { useDationUser } from '../hooks/dation-user';
import DeleteUserModal from './DeleteUserModal';
import { useToast } from '../hooks/use-toast';
import { EDIT_USER } from '../constants/routes';

function UsersList({
	className = '',
	users,
	showFilter = false,
	showFooter = false,
	handleSubmit = null,
	inviteEndpoint = null,
	newInviteEndpoint = null,
	deleteEndpoint = null,
	listType,
	company = null,
}) {
	const { t } = useTranslation();
	const dationUser = useDationUser();
	const toast = useToast();
	const navigate = useNavigate();

	const { user: dationUserData = {} } = dationUser || {};
	const [selectedUser, setSelectedUser] = useState(null);
	const [body, setBody] = useState([]);
	const [showInviteModal, setShowInviteModal] = useState(false);
	const [showDeleteModal, setDeleteModal] = useState(false);

	const isCompanyContactPersonUserList = listType === 'contact_person';

	const inviteUser = currentUser => {
		setSelectedUser(currentUser);
		setShowInviteModal(true);
	};

	const nameCell = useCallback(cellInfo => {
		const { row: { original } } = cellInfo;
		return getDisplayName(original);
	}, []);

	const verifiedCell = useCallback(cellInfo => {
		const { row: { original } } = cellInfo;

		return !original.verified
			? <span className="glyphicons glyphicons-square-empty-remove text-danger" />
			: <span className="glyphicons glyphicons-square-empty-check text-success" />;
	}, []);

	const inviteCell = useCallback(cellInfo => {
		const { row: { original } } = cellInfo;
		const disabledInvite = isCompanyContactPersonUserList && company && company.archived;
		return !original.verified
			? (
				<Button
					variant="outline-secondary"
					type="button"
					onClick={e => {
						// Prevent clicking the button from also clicking the row
						e.preventDefault();
						e.stopPropagation();
						if(disabledInvite) {
							toast.addToast(t('users.add_not_allowed'), 'error');
						} else {
							inviteUser(original);
						}
					}}
				>
					{original.companyContactPersonUser ? t('users.resend_invite') : t('users.submit')}
				</Button>
			)
			: null;
	}, [isCompanyContactPersonUserList]);

	const deleteCell = useCallback(cellInfo => {
		const { row: { original } } = cellInfo;
		const { emailAddress: dationInstanceUserEmail, companyContactPerson = null } = dationUserData || {};
		const { emailAddress: companyContactPersonEmail } = companyContactPerson || {};
		const emailAddress = original.companyContactPersonUser ? companyContactPersonEmail : dationInstanceUserEmail;
		const isCurrentUser = original.emailAddress === emailAddress;
		return !isCurrentUser && (
			<div style={{ width: 70 }} className="text-center">
				<span
					className="text-center glyphicons glyphicons-bin clickable b-left d-inline-block p-0 chip-glyphicon cursor-pointer"
					id={`remove-user-${original.id}`}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						setSelectedUser(original);
						setDeleteModal(true);
					}}
				/>
			</div>
		);
	}, [dationUserData]);

	const columns = React.useMemo(() => [
		{
			Header: t('dation_instance.name'),
			accessor: 'name',
			Cell: nameCell,
		},
		{
			Header: t('portal.email_placeholder'),
			accessor: 'emailAddress',
		},
		{
			Header: t('users.role'),
			accessor: 'roleDisplay',
		},
		{
			Header: t('add_employee.verified'),
			accessor: 'verifiedAccessor',
			Cell: verifiedCell,
		},
		{
			Header: t('users.invite'),
			accessor: 'invite',
			Cell: inviteCell,
		},
		{
			accessor: 'delete',
			Cell: deleteCell,
		},
	], [t]);

	useEffect(() => {
		setBody(transformUser(users, t));
	}, [users]);

	const endpoint = inviteEndpoint ? inviteEndpoint(selectedUser?.id) : null;
	const newInviteRoute = newInviteEndpoint ? newInviteEndpoint(selectedUser?.id) : null;
	const companyId = company ? company.id : null;

	const deleteRoute = deleteEndpoint ? deleteEndpoint(selectedUser?.id, companyId) : null;
	const { companyContactPersonUser = null } = selectedUser || {};

	const userName = getDisplayName(selectedUser || {});
	const userTypeLang = t(`users.type.${listType}`);
	return (
		<>
			 <InviteUserModal
				 show={showInviteModal}
				 setShow={setShowInviteModal}
				 endpoint={companyContactPersonUser ? newInviteRoute : endpoint}
				 handleSubmit={handleSubmit}
				 title={t('users.send_invite')}
				 message={t('users.confirm_invite', { emailAddress: selectedUser?.emailAddress })}
				 successMessage={t('users.invite_send', { userType: userTypeLang, name: userName })}
			 />
			<DeleteUserModal
				deleteEndpoint={deleteRoute}
				show={showDeleteModal}
				setShow={show => {
					setSelectedUser({});
					setDeleteModal(show);
				}}
				title={t('users.delete')}
				message={t('users.confirm_delete', { userType: userTypeLang, name: userName })}
				handleSubmit={handleSubmit}
				successMessage={t('users.deleted', { userType: userTypeLang, name: userName })}
			/>
			<UsersTable
				className={className}
				columns={columns}
				data={body || []}
				handleClick={original => {
					navigate(EDIT_USER, {
						state: {
							userType: listType,
							original,
						},
					});
					setSelectedUser(original);
				}}
				showFilter={showFilter}
				showFooter={showFooter}
			/>
		</>
	);
}

UsersList.propTypes = {
	className: PropTypes.string,
	users: PropTypes.array.isRequired,
	showFilter: PropTypes.bool,
	showFooter: PropTypes.bool,
	inviteEndpoint: PropTypes.func,
	newInviteEndpoint: PropTypes.func,
	deleteEndpoint: PropTypes.func,
	handleSubmit: PropTypes.func,
	listType: PropTypes.oneOf(['contact_person', 'dation_instance_user']),
	company: PropTypes.object,
};

export default UsersList;
