import React, { useState } from 'react';
import {
	Button, Modal, ModalBody, ModalFooter, ModalTitle,
} from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useToast } from '../../hooks/use-toast';
import ErrorMessage from '../common/messages/ErrorMessage';

const XLSX_FORMAT_EXPRESSION = 'spreadsheetml.sheet';

function UploadFileModal({
	show, setShow, handleSubmit, successMessage,
}) {
	const toast = useToast();
	const { t } = useTranslation();
	const [file, setFile] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isLoading, setLoading] = useState(false);

	const changeHandler = event => {
		if(!event.target.files[0].type.match(XLSX_FORMAT_EXPRESSION)) {
			setErrorMessage([t('fileUpload.incorrectFormat')]);
		} else {
			setFile(event.target.files[0]);
		}
	};

	const toBase64 = _file => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(_file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});

	const closeModal = () => {
		setFile(null);
		setErrorMessage(null);
		setLoading(false);
		setShow(false);
	};

	const downloadErrors = e => {
		const blob = new Blob([e], { type: 'application/octet-stream' });
		const downloadUrl = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = downloadUrl;
		a.download = `${t('fileUpload.failedImport')}.csv`;
		document.body.appendChild(a);
		a.click();
	};

	const submitFile = async () => {
		setErrorMessage(null);
		setLoading(true);
		if(file) {
			try {
				const exp = 'base64,';
				const rawFile = await toBase64(file);
				const { index } = rawFile.match(exp);
				const base64File = rawFile.slice(index + exp.length, rawFile.length);
				await handleSubmit(base64File);

				toast.addToast(successMessage || t('fileUpload.success'));
				closeModal();
			} catch (e) {
				downloadErrors(e);
			} finally {
				setLoading(false);
			}
		}
	};

	return (
		<Modal show={show} onHide={closeModal} size="lg">
			<Modal.Header closeButton>
				<ModalTitle>{t('fileUpload.title')}</ModalTitle>
			</Modal.Header>
			<ModalBody>
				<ErrorMessage message={errorMessage} />
				<div>
					{file ? (
						<div>
							<p><b>{t('fileUpload.fileName')}:</b> {file.name}</p>
						</div>
					) : (
						<p>{t('fileUpload.chooseFile')}</p>
					)}
					<input className="col-6 mx-0 px-0" type="file" name="file" onChange={changeHandler} />
				</div>
			</ModalBody>
			<ModalFooter>
				<Button variant="outline-secondary" onClick={closeModal}>{t('modalActions.cancel')}</Button>
				<Button variant="primary" disabled={!file || isLoading} onClick={submitFile}>{t('modalActions.confirm')}</Button>
			</ModalFooter>
		</Modal>
	);
}
UploadFileModal.propTypes = {
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	successMessage: PropTypes.string,
};

export default UploadFileModal;
