import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function TableGlobalFilter({ setGlobalFilter, globalFilter }) {
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState(globalFilter);

	const handleChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<div className="table-search">
			<label>
				{t('table.search')}:{' '}
				<input
					className="form-control form-control-sm d-inline-block mt-1"
					value={searchValue || ''}
					onChange={e => {
						setSearchValue(e.target.value);
						handleChange(e.target.value);
					}}
				/>
			</label>
		</div>
	);
}

TableGlobalFilter.propTypes = {
	setGlobalFilter: PropTypes.func.isRequired,
	globalFilter: PropTypes.string,
};

export default TableGlobalFilter;
