/* eslint-disable import/prefer-default-export */
import { getDisplayName } from './person';

export const getExtraFields = (userRow, t) => {
	const { verified: dationInstanceUserVerified, roles: dationInstanceUserRoles = [], companyContactPersonUser } = userRow || {};
	const { verified: contactPersonUserVerified, roles: contactPersonUserRoles = [] } = companyContactPersonUser || {};
	const roles = companyContactPersonUser ? contactPersonUserRoles : dationInstanceUserRoles;
	const role = roles.length > 0 ? roles[0] : null;
	return {
		roleDisplay: role && t(`users.roles.${role?.toLowerCase()}`),
		verified: companyContactPersonUser ? contactPersonUserVerified : dationInstanceUserVerified,
	};
};

export const transformUser = (data, t) => data.map(rawUser => {
	// react-table doesnt sort on boolean value
	const extraFields = getExtraFields(rawUser, t);
	const stringGroupingValue = extraFields.verified ? '1' : '';
	return {
		...rawUser,
		name: getDisplayName(rawUser),
		verifiedAccessor: stringGroupingValue,
		invite: stringGroupingValue,
		...extraFields,
	};
});
