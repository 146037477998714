export const getApplicationOptions = code95Enabled => [
	{
		id: 1,
		name: 'Medische keuring',
	},
	{
		id: 2,
		name: 'Theoriecursus',
	},
	{
		id: 3,
		name: 'Praktijkopleiding',
	},
	...(code95Enabled ? [{
		id: 4,
		name: 'Code 95 opleiding die niet in de Cursusagenda staat',
	}] : []),
];
export const CATEGORY_OPTIONS = [
	{
		id: 1,
		name: 'A',
	},
	{
		id: 2,
		name: 'B',
	},
	{
		id: 3,
		name: 'BE',
	},
	{
		id: 4,
		name: 'C1',
	},
	{
		id: 5,
		name: 'C1E',
	},
	{
		id: 6,
		name: 'C',
	},
	{
		id: 7,
		name: 'CE',
	},
	{
		id: 8,
		name: 'D',
	},
	{
		id: 9,
		name: 'DE',
	},
	{
		id: 10,
		name: 'T',
	},
];
