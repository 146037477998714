import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

function Message({
	variant, message, onClose, bold,
}) {
	return message ? (
		<Alert
			variant={variant}
			className={`${bold ? 'font-weight-bold' : ''}`}
			dismissible={!!onClose}
			onClose={onClose}
		>
			{message}
		</Alert>
	) : null;
}

Message.propTypes = {
	variant: PropTypes.oneOf(['danger', 'success', 'info', 'warning']),
	message: PropTypes.string,
	bold: PropTypes.bool,
	onClose: PropTypes.func,
};

export default Message;
