import React from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InfoBar from '../components/Navigation/InfoBar';
import useApi from '../hooks/use-api';
import { getLogbookDetails } from '../constants/routes';
import FormGroupPlainText from '../components/Form/FormGroupPlainText';

function LogbookDetailPage() {
	const { t } = useTranslation();
	const { logbookEntryId } = useParams();

	const [{ data }] = useApi.get(getLogbookDetails(logbookEntryId));

	const {
		contents, date, title, username,
	} = data || {};
	return (
		<>
			<InfoBar backTitle={t('logbook.list')} />
			<div className="row">
				<div className="col-9">
					<Card>
						<Card.Body style={{ whiteSpace: 'pre-wrap' }}>
							<h4>{title}</h4>
							<p>
								{contents}
							</p>
						</Card.Body>
					</Card>
				</div>
				<div className="col-3">
					<Card>
						<Card.Body>
							<FormGroupPlainText label={t('logbook.user')} value={username} />
							<FormGroupPlainText label={t('logbook.date')} value={date} />
						</Card.Body>
					</Card>
				</div>
			</div>
		</>
	);
}

export default LogbookDetailPage;
