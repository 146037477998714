import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import MenuItem from './DropdownItem';

function DropdownButton({ options, minWidth, placement }) {
	return (
		<OverlayTrigger
			trigger="click"
			overlay={(
				<Popover id="studentsMenuActions" style={{ minWidth: minWidth || '15vw' }}>
					<Popover.Body>
						{options.map(item => <MenuItem key={item.id} item={item} />)}
					</Popover.Body>
				</Popover>
			)}
			placement={placement || 'bottom'}
			rootClose
		>
			<Button
				variant="outline-secondary"
				className="rounded-button p-1 d-flex flex-row justify-content-center align-items-center"
				type="button"
			>
				<span className="glyphicons glyphicons-chevron-down" />
			</Button>
		</OverlayTrigger>
	);
}

export default DropdownButton;
