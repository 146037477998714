import React, { useState } from 'react';
import PropTypes from 'prop-types';

const LINE_HEIGHT = 1.5;
const PREVIEW_LINES = 5;

const collapsibleStyle = {
	overflow: 'hidden',
	maxHeight: `${LINE_HEIGHT * PREVIEW_LINES}rem`,
	lineHeight: `${LINE_HEIGHT}rem`,
	WebkitBoxOrient: 'vertical',
	WebkitLineClamp: 5,
	display: 'block',
};

function CollapsibleFormText({ label, value }) {
	const [seeMore, setSeeMore] = useState(false);

	const toggleSeeMore = () => setSeeMore(!seeMore);
	return (
		<div className="form-group">
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<span className="d-flex justify-content-between mb-1" onClick={toggleSeeMore}>
				<label className="cursor-pointer text-secondary mb-n2 me-2 font-size-small" htmlFor={label}>{label}</label>
				{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
				<span
					className={`glyphicons cursor-pointer text-dark ${seeMore ? 'glyphicons-chevron-up' : 'glyphicons-chevron-down'}`}
				/>
			</span>
			<div className="d-flex">
				{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
				<span
					onClick={toggleSeeMore}
					className={`cursor-pointer form-control-plaintext text-primary-color p-0 item ${seeMore ? '' : 'custom-fade'} `}
					style={seeMore ? {} : collapsibleStyle}
					id={label}
				>
					{value}
				</span>
			</div>
		</div>
	);
}

CollapsibleFormText.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default CollapsibleFormText;
