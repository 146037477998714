import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectFilter, { OPTION_ALL } from './SelectFilter';
import { CODE_95_POINTS_PROGRESSION, NO_CYCLE_CYCLE_REQUIRED } from '../../../utils/code95';

const PLUS_35 = '35+';

function Code95Filter({
	column: {
		id, filterValue, setFilter,
	},
}) {
	const { t } = useTranslation();

	const options = [
		{ value: OPTION_ALL, key: OPTION_ALL, name: t('table.all') },
		{ value: NO_CYCLE_CYCLE_REQUIRED, key: NO_CYCLE_CYCLE_REQUIRED, name: t('dashboard.no_current_cycle') },
		// remove the last element from the points progression, since a custom value is required
		...CODE_95_POINTS_PROGRESSION.slice(0, 5).map(amount => ({ value: amount, key: amount, name: amount })),
		{ value: PLUS_35, key: PLUS_35, name: PLUS_35 },
	];

	return <SelectFilter id={id} filterValue={filterValue} setFilter={setFilter} options={options} />;
}

export const code95Filter = (rows, columnIds, filterValue) => {
	if(filterValue === OPTION_ALL) {
		return rows;
	}

	if(filterValue === PLUS_35) {
		return rows.filter(({ original }) => original.code95Hours >= 35);
	}

	return rows.filter(({ original }) => original.code95Hours === parseInt(filterValue, 10));
};

export default Code95Filter;
