import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from '../../moment';
import DashboardTable from './DashboardTable';
import { getDateFormat } from '../../utils/date';
import useApi from '../../hooks/use-api';
import { getCertificatesForCompany } from '../../constants/routes';
import { useDationUser } from '../../hooks/dation-user';

function CertificateRisk() {
	const dationUser = useDationUser();
	const { t } = useTranslation();

	const [{ data }, fetchCertificates] = useApi.get(
		getCertificatesForCompany(dationUser.companyId),
		{},
		{ lazy: true },
	);

	useEffect(() => {
		fetchCertificates({ certificateExpiresBeforeDate: moment().add(6, 'months').format() });
	}, []);

	const headers = React.useMemo(() => [
		{
			Header: t('dashboard.name'),
			accessor: 'student.displayName',
		},
		{
			Header: t('dashboard.certificate'),
			accessor: 'training.name',
		},
		{
			Header: t('dashboard.expiry_date'),
			accessor: 'certificateExpirationDate',
			Cell: ({ value }) => getDateFormat(value),
		},
	], [t]);

	const certificates = useMemo(() => {
		if(data === null) {
			return [];
		}
		return data.map(certificate => ({ ...certificate, id: certificate.student.id }));
	}, [data]);

	return (
		<DashboardTable
			cardHeader={<div className="d-flex"><span className="glyphicons glyphicons-certificate" style={{ fontSize: '2.5rem' }} /><span className="m-2">{t('dashboard.certificate_expires')}</span></div>}
			sortBy={[
				{
					id: 'certificateExpirationDate',
					desc: false,
				},
			]}
			columns={headers}
			data={certificates || []}
		/>
	);
}

export default CertificateRisk;
