import React from 'react';
import { FormLabel } from 'react-bootstrap';
import DefaultTableFilter from './DefaultTableFilter';
import DateInput from '../../Form/DateInput';
import moment from '../../../moment';

function IsBeforeFilter({
	column: {
		id, filterValue, setFilter,
	},
	label,
}) {
	return (
		<DefaultTableFilter id={id} active={!!filterValue}>
			<div className="form-group">
				<FormLabel>{label}</FormLabel>
				<DateInput
					onChange={newStart => {
						setFilter(newStart);
					}}
					value={filterValue}
				/>
			</div>
		</DefaultTableFilter>
	);
}

export const isBeforeFilter = (rows, columnIds, filterValue) => {
	if(filterValue && moment.isMoment(moment(filterValue))) {
		return rows.filter(({ original }) => {
			const rowValue = original[columnIds[0]];
			return rowValue && moment(rowValue, 'DD-MM-YYYY').isBefore(moment(filterValue).startOf('day'));
		});
	}
	return rows;
};
export default IsBeforeFilter;
