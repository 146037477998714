import React from 'react';
import { useDationUser } from '../../hooks/dation-user';

// Since hooks can't be used within React components and we need a component to
// be able to catch exceptions, this higher order component is necessary to pass
// the logged in user to the error boundary component to identify in Rollbar
// what users are logged in.
const DationUserComponent = Component => function WrappedComponent(props) {
	const dationUser = useDationUser().user;
	return <Component dationUser={dationUser} {...props} />;
};

export default DationUserComponent;
