/* eslint-disable no-unused-vars */
import React from 'react';
import Rollbar from 'rollbar';
import DationUserComponent from './DationUserComponent';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			rollbar: new Rollbar({
				accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
				captureUncaught: true,
				captureUnhandledRejections: true,
				payload: {
					person: this.constructPerson(),
					client: {
						javascript: {
							code_version: process.env.REACT_APP_VERSION,
							guess_uncaught_frames: true,
						},
					},
				},
			}),
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { rollbar } = this.state;
		rollbar.configure({
			payload: {
				person: this.constructPerson(),
			},
		});
	}

	componentDidCatch(error, errorInfo) {
		const { rollbar } = this.state;
		rollbar.error(error);
	}

	constructPerson = () => {
		const { dationUser } = this.props;

		if(dationUser === null) {
			return {
				id: null,
			};
		}

		let person = {
			id: dationUser.id,
		};

		const { companyContactPerson } = dationUser;

		if(undefined !== companyContactPerson) {
			person = {
				...person,
				email: companyContactPerson.emailAddress,
			};
		}

		return person;
	};

	render() {
		const { children } = this.props;
		return children;
	}
}

export default DationUserComponent(ErrorBoundary);
