import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InfoBar from '../../components/Navigation/InfoBar';
import * as ROUTES from '../../constants/routes';
import useApi from '../../hooks/use-api';
import CompanyTable from '../../components/Tables/CompanyTable';
import { useDationUser } from '../../hooks/dation-user';
import { COMPANY_TABLE } from '../../constants/tables';
import useUserPreferences from '../../hooks/use-user-preferences';

function CompaniesListPage() {
	const navigate = useNavigate();
	const { setPageIndex } = useUserPreferences();
	const { t } = useTranslation();
	const dationUser = useDationUser();
	const [body, setBody] = useState([]);
	const [{ data }, fetchCompanies] = useApi.get(ROUTES.COMPANIES, { archived: false }, { cachePolicy: 'no-cache' });

	const privateCell = useCallback(
		({ value }) => (value
			? <div className="mt-1"><span className="glyphicons glyphicons-lock" /></div>
			: <div className="mt-1"><span className="glyphicons glyphicons-lock-open" /></div>),
		[],
	);

	const seeCompanyCell = useCallback(cellInfo => {
		const { row: { original: company } } = cellInfo;
		return (
			<Button
				variant="outline-primary"
				type="button"
				size="sm"
				onClick={e => {
					e.stopPropagation();
					dationUser.setCurrentCompanyId(company.id);
					dationUser.setCurrentCompanyToViewName(company.name);
					navigate(ROUTES.DASHBOARD);
				}}
			>{t('company.see')}
			</Button>
		);
	}, [dationUser]);

	const headers = React.useMemo(() => [
		{
			Header: t('company.name'),
			accessor: 'name',
		},
		{
			Header: t('company.private'),
			accessor: 'private',
			Cell: privateCell,
			sortType: 'basic',
		},
		{
			accessor: 'seeCompany',
			Cell: seeCompanyCell,
		},
	], [t]);

	const renderCustomFilters = React.useCallback(
		() => (
			<div className="d-flex">
				<select
					className="form-select"
					onChange={({ target }) => {
						fetchCompanies({ archived: target.value });
						setPageIndex(COMPANY_TABLE, 0);
					}}
				>
					<option value={0}>{t('company.active_company_filter')}</option>
					<option value={1}>{t('company.archived_company_filter')}</option>
				</select>
			</div>
		),
		[],
	);

	useEffect(() => {
		if(data) {
			const newBody = data.map(entry => ({
				id: entry.id,
				name: entry.name,
				private: entry.private,
			}));

			setBody(newBody);
		}
	}, [data]);

	/*
	 * This creates a warning in browser console because updating the dationUser causes a re-render
	 * When re rendering the router also unmounts and mount this component again which cause the warning
	 * Can't find out how to remove warning but this is necessary so we keep it for now as it doesn't break something
	*/
	useEffect(() => {
		dationUser.setCurrentCompanyToViewName(null);
	}, []);

	return (
		<>
			<InfoBar />
			<div className="d-flex flex-row-reverse">
				<Button
					id="import-company"
					variant="outline-secondary"
					className="rounded-button p-1"
					type="button"
					onClick={() => navigate(ROUTES.IMPORT_COMPANY)}
				>
					<span className="glyphicons glyphicons-square-download" style={{ position: 'relative', top: 3 }} />
				</Button>
			</div>
			<div className="row pb-5">
				<CompanyTable columns={headers} data={body} renderCustomFilters={renderCustomFilters} />
			</div>
		</>
	);
}

export default CompaniesListPage;
