/* eslint-disable react-hooks/exhaustive-deps, react/no-unstable-nested-components */
import React, {
	useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Tab } from 'react-bootstrap';
import CompanyStudentTable from '../../Tables/CompanyStudentTable';
import { useTabs } from '../../../hooks/use-tabs';
import TabsHeader from '../../Tabs/TabsHeader';
import ChecklistOption from '../ChecklistOption';
import EstablishmentFilter from '../../common/EstablishmentFilter';
import useApi from '../../../hooks/use-api';
import { getChecklistForCompanyStudents, getEstablishmentsForCompany } from '../../../constants/routes';
import { useDationUser } from '../../../hooks/dation-user';
import InfoMessage from '../../common/messages/InfoMessage';

export const STUDENTS_CHECKLIST_TAB = 'studentsChecklist';
const STUDENTS_CHECKLIST_TABS = 'students_checklist_tabs';
const DEFAULT_TAB = 'Vrachtauto';

function StudentsChecklistOverviewTab({ visible }) {
	const { t } = useTranslation();
	const dationUser = useDationUser();

	const [studentChecklistsByType, setStudentChecklistsByType] = useState([]);
	const [tabs, setTabs] = useState([]);

	const { key, handleKeyChange } = useTabs(tabs, STUDENTS_CHECKLIST_TABS, tabs.find(tab => tab === DEFAULT_TAB));

	const [{ data: establishmentData }] = useApi.get(getEstablishmentsForCompany(dationUser.getCurrentCompanyId()));
	const [{ data: companyStudentsChecklistsByType, isLoading: loadingStudentChecklists }, fetchChecklists] = useApi.get(
		getChecklistForCompanyStudents(dationUser.companyId),
		{},
		{ lazy: true },
	);

	useEffect(() => {
		if(visible) {
			fetchChecklists();
		}
	}, [visible]);

	useEffect(() => {
		if(companyStudentsChecklistsByType?.length) {
			const checklistsWithResultsByType = companyStudentsChecklistsByType.reduce((accumulator, checklistsByType) => {
				if(checklistsByType?.checklists?.length) {
					accumulator.push(checklistsByType);
				}
				return accumulator;
			}, []);
			setStudentChecklistsByType(checklistsWithResultsByType);
		}
	}, [companyStudentsChecklistsByType]);

	useEffect(() => {
		setTabs(studentChecklistsByType.map(({ name }) => name));
	}, [studentChecklistsByType]);

	const studentHeaders = [
		{
			Header: t('employees.lastName'),
			accessor: 'studentLastName',
			disableFilters: true,
		},
		{
			Header: t('employees.firstName'),
			accessor: 'studentFirstName',
			disableFilters: true,
		},
		{
			Header: 'establishmentId',
			accessor: 'establishmentId',
		},
	];

	const getHeaders = useCallback(checklistType => {
		const { columns = [] } = checklistType || {};
		const checklistTypeHeaders = columns.map(columnName => ({
			Header: columnName,
			accessor: columnName,
			disableFilters: true,
			Cell: ({ row }) => {
				const option = row.original[columnName] ?? {};
				return <ChecklistOption data={option} />;
			},
		}));
		return [...studentHeaders, ...checklistTypeHeaders];
	}, [studentHeaders]);

	const formatCompanyStudentChecklistsOptions = useCallback(checklistType => {
		const { checklists = [] } = checklistType || {};
		const body = [];
		checklists.forEach(studentChecklists => {
			const { student, values = [] } = studentChecklists;
			let checklistValues = {};
			values.forEach(({ option, position, value }) => {
				checklistValues = {
					...checklistValues,
					// matches the header accessor name used for react table
					[option.name]: {
						position,
						option,
						value,
					},
				};
			});
			body.push({
				id: student.id,
				studentLastName: student.insertion ? `${student.lastName}, ${student.insertion}` : student.lastName,
				studentFirstName: student.firstName,
				establishmentId: student.establishment?.id,
				...checklistValues,
			});
		});
		return body;
	}, []);

	const renderCustomFilters = React.useCallback(
		({ filters, setFilter }) => (
			<EstablishmentFilter
				filters={filters}
				setFilter={setFilter}
				establishments={establishmentData}
			/>
		),
		[establishmentData],
	);

	return loadingStudentChecklists ? (
		<div className="loading-spinner" />
	) : (
		<Tab.Container id="planning-tabs" activeKey={key} onSelect={k => handleKeyChange(k)}>
			{studentChecklistsByType.length ? (
				<>
					<TabsHeader
						tabKey={key}
						tabs={tabs}
						options={{ variant: 'pills' }}
					/>
					<Tab.Content>
						{studentChecklistsByType.map(checklistType => (
							<Tab.Pane key={checklistType.name} eventKey={checklistType.name}>
								<CompanyStudentTable
									columns={getHeaders(checklistType)}
									data={formatCompanyStudentChecklistsOptions(checklistType)}
									renderCustomFilters={renderCustomFilters}
									sortByProps={{ id: 'studentLastName', desc: false }}
								/>
							</Tab.Pane>
						))}
					</Tab.Content>
				</>
			) : (
				<InfoMessage message={t('employees.no_education_students')} />
			)}
		</Tab.Container>
	);
}

export default StudentsChecklistOverviewTab;
