import React from 'react';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const getCertificationValues = (risk, t) => {
	switch (risk) {
	case 1:
		return { type: 'success', value: t('employees.low') };
	case 2:
		return { type: 'warning', value: t('employees.average') };
	case 3:
		return { type: 'danger', value: t('employees.high') };
	default:
		return { type: null, value: null };
	}
};

function CertificationRiskBadge({ risk }) {
	const { t } = useTranslation();

	if(!risk || risk === 0) {
		return null;
	}

	const { type, value } = getCertificationValues(risk, t);

	return <Badge pill bg={type} className="p-2 risk-badge">{value}</Badge>;
}

CertificationRiskBadge.propTypes = {
	risk: PropTypes.number.isRequired,
};
export default CertificationRiskBadge;
