import React, { useContext } from 'react';
import { AccordionContext, Button, useAccordionButton } from 'react-bootstrap';
import { getDateFormat, getTimeDateFormat, getWeekdayFormat } from '../../../utils/date';

function SlotHeader({ slot, eventKey }) {
	const { activeEventKey } = useContext(AccordionContext);
	const decoratedOnClick = useAccordionButton(eventKey);

	const isCurrentEventKey = activeEventKey.includes(eventKey);

	return (
		<div className="row" key={slot.id}>
			<div className="col-2 col-lg-1 pe-0">{getWeekdayFormat(slot.start)}</div>
			<div className="col-8 col-lg-11 d-flex flex-row ps-0">
				<div>{getDateFormat(slot.start)}</div>
				<div className="ms-2">
					{`${getTimeDateFormat(slot.start)}  -  ${getTimeDateFormat(slot.end)}`}
				</div>
				<Button variant="link" size="sm" className="p-0 ms-3" onClick={decoratedOnClick}>
					<span className={`glyphicons glyphicons-chevron-${isCurrentEventKey ? 'up' : 'down'}`} />
				</Button>
			</div>
		</div>
	);
}

export default SlotHeader;
