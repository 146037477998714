import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFirebase } from '../hooks/firebase';
import * as ROUTES from '../constants/routes';
import Logo from '../assets/images/logo.png';
import ErrorMessage from '../components/common/messages/ErrorMessage';
import useApi from '../hooks/use-api';

function LoginPage() {
	const { t } = useTranslation();
	const firebase = useFirebase();
	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState(null);
	const [, loginAttempt] = useApi.post(ROUTES.loginAttempt(), {}, { lazy: true });

	const onSubmit = event => {
		loginAttempt({ email, password })
			.then(responseData => {
				firebase
					.signIn(responseData.custom_token)
					.catch(firebaseError => {
						const { message } = firebaseError;
						setErrorMessage(message);
					});
			})
			.catch(err => {
				setErrorMessage(err);
			});
		event.preventDefault();
	};

	const isInvalid = password === '' || email === '';

	return (
		<form onSubmit={onSubmit}>
			<img src={Logo} className="w-75 mb-4 mx-auto d-block" alt={t('portal.name')} />
			<h3>{t('portal.loginText')}</h3>

			<ErrorMessage message={errorMessage} />
			<div className="mb-3">
				<input
					type="email"
					className="form-control"
					value={email}
					onChange={e => setEmail(e.target.value)}
					placeholder={t('portal.email_placeholder')}
					autoComplete="username"
				/>
			</div>
			<div className="mb-3">
				<input
					type="password"
					className="form-control"
					value={password}
					onChange={e => setPassword(e.target.value)}
					placeholder={t('portal.password_placeholder')}
					autoComplete="current-password"
				/>
			</div>
			<div className="d-grid gap-2">
				<button type="submit" disabled={isInvalid} className="btn btn-primary">{t('portal.login')}</button>
				<button type="button" className="btn btn-link btn-block" onClick={() => navigate(ROUTES.resetPasswordRequestPage())}>{`${t('portal.remember_password')}?`}</button>
			</div>
		</form>
	);
}

export default LoginPage;
