import React, {
	createContext, useState, useContext, useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '../use-api';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { SESSION_STORAGE } from '../../constants/storageVariables';
import { sortListByString } from '../../utils/sort';

const dationUserContext = createContext();

function useProvideDationUser() {
	const navigate = useNavigate();
	const [user, setUser] = useState(null);
	// companyToViewName is only used for impersonation feature
	const [companyToViewName, setCompanyToViewName] = useState(sessionStorage.getItem(SESSION_STORAGE.CURRENT_COMPANY_NAME) || null);
	const [companyId, setCompanyId] = useState(sessionStorage.getItem(SESSION_STORAGE.CURRENT_COMPANY_ID) || null);
	const [{ data: userData, error }, fetchProfile] = useApi.get(
		ROUTES.PROFILE,
		{},
		{ lazy: true },
	);

	const setCurrentCompanyId = currentCompanyId => {
		if(currentCompanyId === null) {
			sessionStorage.removeItem(SESSION_STORAGE.CURRENT_COMPANY_ID);
		} else {
			sessionStorage.setItem(SESSION_STORAGE.CURRENT_COMPANY_ID, currentCompanyId);
		}
		setCompanyId(currentCompanyId);
	};

	const setCurrentCompanyToViewName = companyName => {
		if(companyName === null) {
			sessionStorage.removeItem(SESSION_STORAGE.CURRENT_COMPANY_NAME);
		} else {
			sessionStorage.setItem(SESSION_STORAGE.CURRENT_COMPANY_NAME, companyName);
		}
		setCompanyToViewName(companyName);
	};

	const getCurrentCompanyId = () => parseInt(sessionStorage.getItem(SESSION_STORAGE.CURRENT_COMPANY_ID), 10) || null;

	const isDationInstanceUser = () => user?.roles[0] === ROLES.SCHOOL_ADMIN || user?.roles[0] === ROLES.SCHOOL_SUPER_USER;

	const isCompanyContactPersonUser = () => user?.roles[0] === ROLES.COMPANY_ADMIN || user?.roles[0] === ROLES.COMPANY_SUPER_USER;

	const getUserName = () => (user.companyContactPerson !== undefined ? user.companyContactPerson.firstName : user.firstName);

	const getCurrentCompany = () => (user?.companies?.length > 0 ? user.companies.find(company => company.id === getCurrentCompanyId()) : null);

	const getCompanyName = () => {
		if(isCompanyContactPersonUser()) {
			const currentCompany = getCurrentCompany();
			return currentCompany?.name;
		}
		// Only for impersonation
		return companyToViewName;
	};

	useEffect(() => {
		if(userData && userData.roles.length === 0) {
			navigate('notFound');
		}

		if(userData?.companies?.length > 1) {
			userData.companies = sortListByString(userData.companies, 'name');
		}

		if(userData?.companies?.length && !getCurrentCompanyId()) {
			setCurrentCompanyId(userData.companies[0].id);
		}

		setUser(userData);
	}, [userData, navigate]);

	useEffect(() => {
		if(error) {
			navigate('notFound');
		}
	}, [error, navigate]);

	const initializeDationUser = () => {
		fetchProfile();
	};

	return {
		initializeDationUser,
		user,
		setUser,
		setCurrentCompanyId,
		getCurrentCompanyId,
		setCurrentCompanyToViewName,
		companyToViewName,
		companyId,
		getCompanyName,
		isDationInstanceUser,
		isCompanyContactPersonUser,
		getCurrentCompany,
		getUserName,
	};
}

export function ProvideDationUser({ children }) {
	const dationUser = useProvideDationUser();
	return <dationUserContext.Provider value={dationUser}>{children}</dationUserContext.Provider>;
}

export const useDationUser = () => useContext(dationUserContext);
