/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import moment from '../moment';

// function for sorting. Expects 'moment' date objects as parameters
export const compareDates = (dateA, dateB) => {
	if(dateA > dateB) {
		return 1;
	} if(dateA < dateB) {
		return -1;
	}

	return 0;
};

export const compareTableDates = (accessor, a, b) => {
	const { original: valuesA } = a;
	const { original: valuesB } = b;
	const defaultMoment = moment('01-01-0001', 'DD-MM-YYYY');

	// explicitly check for null, otherwise it is counted as the 01-01-1970
	const dateA = valuesA[accessor] ? moment(valuesA[accessor]) : defaultMoment;
	const dateB = valuesB[accessor] ? moment(valuesB[accessor]) : defaultMoment;

	return compareDates(dateA, dateB);
};

export const compareTableBirthDates = (a, b) => compareTableDates('dateOfBirth', a, b);
export const compareTableCertificateExpirationDates = (a, b) => compareTableDates('certificateExpirationDate', a, b);
export const compareTableSortDates = (a, b) => compareTableDates('sortDate', a, b);

export const sortListByString = (list, sortingKey) => _.orderBy(list, [listItem => listItem[sortingKey].toLowerCase().trim()], ['asc']);
