import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalTitle,
	ModalBody,
	ModalFooter,
	Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useApi from '../../hooks/use-api';
import moment from '../../moment';
import { useToast } from '../../hooks/use-toast';
import { getCurrentCode95Cycle } from '../../utils/code95';
import * as ROUTES from '../../constants/routes';
import AddNewCycle from '../Code95/AddNewCycle';
import { CODE_95_ENABLED, useViewPreferences } from '../../hooks/view-preferences';

function CloseEducationModal({
	show, setShow, employee, fetchEmployee, putEmployee,
}) {
	const toast = useToast();
	const { t } = useTranslation();
	const { isPreferenceOptionEnabled } = useViewPreferences();

	const [isDisabled, setDisabled] = useState(false);
	const [hasCycle, setHasCycle] = useState(false);
	const [cycleStart, setCycleStart] = useState(null);
	const [code95Student, setCode95Student] = useState(true);

	const [, postCode95Cycle] = useApi.post(ROUTES.postCode95Cycle());

	useEffect(() => {
		if(employee) {
			setHasCycle(!!employee.code95Cycles && !!getCurrentCode95Cycle(employee.code95Cycles));
		}
	}, [employee]);

	const code95Enabled = isPreferenceOptionEnabled(CODE_95_ENABLED);

	const submit = e => {
		e.preventDefault();
		e.stopPropagation();
		setDisabled(true);
		putEmployee(code95Enabled ? { code95Student, education: false } : { education: false }).then(() => {
			if(code95Enabled && !hasCycle && moment.isMoment(cycleStart)) {
				const newCycle = {
					startDate: cycleStart.format(),
					endDate: cycleStart.clone().add(5, 'years').subtract('1', 'day').format(),
					student: {
						id: employee.id,
					},
				};
				postCode95Cycle(newCycle).then(() => {
					toast.addToast(t('education.updateSuccess'));
				}).catch(() => {
					toast.addToast(t('education.addCode95CycleFail'), 'error');
				}).finally(() => {
					fetchEmployee();
					setShow(false);
				});
			} else {
				toast.addToast(t('education.updateSuccess'));
				fetchEmployee();
				setShow(false);
			}
		}).catch(() => {
			toast.addToast(t('education.updateFail'), 'error');
			setShow(false);
			setDisabled(false);
		});
	};

	return (
		<Modal show={show} onHide={() => setShow(false)} size="lg">
			<Modal.Header closeButton>
				<ModalTitle>{t('education.close')}</ModalTitle>
			</Modal.Header>
			<ModalBody className="pt-1">
				<p>{t('education.closeModalMainText')}</p>
				{code95Enabled && (
					<AddNewCycle
						code95Student={code95Student}
						updateStudent={({ code95Student: newValue }) => setCode95Student(newValue)}
						cycleStart={cycleStart}
						setCycleStart={setCycleStart}
						hasCycle={hasCycle}
					/>
				)}
			</ModalBody>
			<ModalFooter>
				<Button
					variant="outline-secondary"
					disabled={isDisabled}
					onClick={() => setShow(false)}
				>{t('modalActions.cancel')}
				</Button>
				<Button
					variant="primary"
					disabled={isDisabled}
					onClick={submit}
				>{t('education.close')}
				</Button>
			</ModalFooter>
		</Modal>
	);
}

CloseEducationModal.propTypes = {
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
	employee: PropTypes.object.isRequired,
	fetchEmployee: PropTypes.func.isRequired,
	putEmployee: PropTypes.func.isRequired,
};

export default CloseEducationModal;
